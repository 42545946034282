import React, {useEffect, useState} from "react";
import {FormCard} from "../../../forms/FormCard";
import formData from "../../../forms/formsData.json";
import {AlphaForm} from "../../../forms/AlphaForm";
import {TextAlpha} from "../../../input/TextAlpha";
import {useNavigate} from "react-router-dom";
import {object, string} from "yup";
import SystemAdmin from "../../../../services/ApiServices/SystemAdminApi";
import SVGraphics from "../../../../assets/SVGraphics";
import Constants from "../../../../utils/constants";
import {SystemAdminApi} from "../../../../services/ApiService";
import {authenticationService} from "../../../../services/AuthinticationService";


export function AddUser() {
    const navigate = useNavigate();
    const [formType, setFormType] = useState(formData['add_user'])
    const [siteForm, setSiteForm] = useState([formData['user_sites']])
    const [userSites, setUserSites] = useState([{isActive: true,SiteID:''}]);
    const [chosenSites, setChosenSites] = useState([]);
    const [entry, setEntry] = useState({});
    const [error, setError] = useState({})
    const [siteFormErrors, setSiteFormErrors] = useState([{}])
    const [userRole, setUserRole] = useState(null);
    const [isError, setIsError] = useState(false)
    const right = <span className={"text-[#444444] text-2xl text-titleSize ml-2"}>&#8250;</span>

    const userSchema = object().shape({
        FirstName: string().required('Please insert First Name'),
        LastName: string().required('Please insert Last Name'),
        Gender: string().required('Please insert Gender'),
        PhoneNumber: string().nullable(),
        Email: string().matches(Constants.emailRegExp, "Invalid Email").required("Please insert Email"),
    });

    const userSiteSchema = object().shape({
            SiteID: string().required('Please select Site'),
            Role: string().required('Please select Role'),
        })



    useEffect(() => {
        let currentUser = authenticationService.currentUserValue
        setUserRole(currentUser.role[0])
        let form = JSON.parse(JSON.stringify(formType))
        let siteFormsTemp = JSON.parse(JSON.stringify(siteForm))
        let sites = []
        let user = {...entry}
        if(currentUser.role[0]==="SystemAdmin"){
        fetchData().then(p => {
            p.data.forEach(site => {
                if (site.IsActive) {
                    sites.push({value: site.SiteID, label: site.SiteName})
                }
            })
        })
        }else{
            currentUser.profile.Sites.forEach(site => {
                    sites.push({value: site.SiteID, label: site.SiteName})
            })

        }

            let rolesData
            if(currentUser.role[0]!=="SystemAdmin"){
                 rolesData = siteFormsTemp[0][1].data?.filter(x=>x.value!=="ProductAdmin" && x.value !== "SystemAdmin")
            }else{
                rolesData = siteFormsTemp[0][1].data?.filter(x=> x.value !== "SystemAdmin")
            }

            siteFormsTemp[0][1].data=rolesData


            siteFormsTemp[0].splice(-1)

            if(currentUser.role[0]!=="SystemAdmin"){
                siteFormsTemp[0][0]['disabled']=true
                let _userSites = [...userSites]
                _userSites[0]['SiteID'] = currentUser.profile.SiteID
            }
        siteFormsTemp[0][0].data=sites
        setSiteForm(siteFormsTemp)
            setFormType(form)
            setEntry(user)


    }, [])


    const validateForm = async (type,entry,name) => {
        let form = JSON.parse(JSON.stringify(formType))
        let formErrors =JSON.parse(JSON.stringify(error))
        let value = false
        await userSchema.validate(entry, {abortEarly: false})
            .then(function (value) {
                formErrors = {}
                setIsError(false)
                setError(formErrors)
            }).catch(function (err) {
                formErrors = {}
                err.inner.forEach(error => {

                    let findIndex = form.findIndex(x => x.name === error.path)
                    if(type === 'onChange'){
                        if(name===error.path){
                            form[findIndex]['error'] = error.errors
                            formErrors[error.path] = true
                        }
                    }else{
                        form[findIndex]['error'] = error.errors
                        formErrors[error.path] = true
                    }
                })
                value=true
                setError(formErrors)
                setIsError(value)
                value= true
            });
        setFormType(form)

        return value
    }

    const validateSiteForm= async (userSites) => {

        let value=false
        for (let i = 0; i < userSites.length; i++) {
            let item= userSites[i]
            let index=i
            let form = JSON.parse(JSON.stringify(siteForm[index]))
            let formErrors = JSON.parse(JSON.stringify(siteFormErrors[index]))

            await userSiteSchema.validate(item, {abortEarly: false})
                .then(function () {
                    formErrors={}
                    siteForm[index]=JSON.parse(JSON.stringify(form))
                    siteFormErrors[index]={...formErrors}
                    setSiteForm(JSON.parse(JSON.stringify(siteForm)))
                    setSiteFormErrors(JSON.parse(JSON.stringify(siteFormErrors)))
                })
                .catch(function (err){
                    value=true
                    formErrors = {}
                    err.inner.forEach(error => {
                        formErrors[error.path] = true
                        let findIndex = form.findIndex(x => x.name === error.path)
                        form[findIndex]['error'] = error.errors
                        setIsError(true)
                    })
                });
            siteForm[index]=JSON.parse(JSON.stringify(form))
            siteFormErrors[index]=JSON.parse(JSON.stringify(formErrors))

            setSiteForm([...siteForm])
            setSiteFormErrors([...siteFormErrors])
        }
        return value
    }


    const fetchData = async () => {
        return await SystemAdmin.getAllSitesAdvanced({filters: []})
    }

    const createUser = async () => {
        let user = {...entry}
        let valueValidateForm = await validateForm('create',user,'')
        let valueValidateSites =  await validateSiteForm(userSites)
        user['userSites'] = userSites
        if (!valueValidateForm && !valueValidateSites) {
            let submit = await SystemAdminApi.createUser(user)
            if (submit && submit.data) {
                navigate('/users')
            }
        }
    }

    const onChange = async (name, value) => {
        let user = JSON.parse(JSON.stringify(entry))
        if (name === 'Gender') {
            value = value.value
        } else if (name === 'SiteID') {
            value = value.value
        }
        if (value === "") {
            value = null
        }
        user[name] = value

        // if(error[name]){
            await validateForm('onChange',user,name)
        // }
        setEntry(user)
    }

    const onChangeSiteForm = async (index, name, value) => {

        let _userSites = JSON.parse(JSON.stringify(userSites))
        let currentFrom = JSON.parse(JSON.stringify(_userSites[index]))
        if (name === 'Role') {
            value = value.value
        } else if (name === 'SiteID') {
            value = value.value
            let _chosenSites = JSON.parse(JSON.stringify(chosenSites))
            let chosenSiteIndex = chosenSites.findIndex(x=>x.index===index)
            if(chosenSiteIndex>-1){
                _chosenSites[chosenSiteIndex].value = value
            }else{
                _chosenSites.push({index:index,value:value})
            }
            setChosenSites(_chosenSites)
        }
        if (value === "") {
            value = null
        }
        currentFrom[name] = value
        _userSites[index] = currentFrom
        setUserSites(_userSites)
        if(siteFormErrors[index][name]){
            await validateSiteForm(userSites,index)
        }

    }

    const addSites = () => {
        setUserSites([...userSites, {isActive: 1}]);
        setSiteForm([...siteForm, formData['user_sites']]);
        setSiteFormErrors([...siteFormErrors, {}]);
    }


    const deleteCurrent = (index) => {
        const userSiteArray = [...userSites];
        const siteFormsArr = [...siteForm];
        const siteFormsErrArr = [...siteFormErrors];

        if (userSiteArray.length > 1) {
            userSiteArray.splice(index, 1);
            siteFormsArr.splice(index, 1);
            siteFormsErrArr.splice(index, 1);
            let _chosenSites = JSON.parse(JSON.stringify(chosenSites))
            let chosenSiteIndex = chosenSites.findIndex(x=>x.index===index)
            if(chosenSiteIndex>-1){
                _chosenSites.splice(chosenSiteIndex,1)
            }
            setChosenSites(_chosenSites)
        }

        setSiteFormErrors(siteFormsErrArr);
        setUserSites(userSiteArray);
        setSiteForm(siteFormsArr);
    }

    return (
        <div className="w-full h-full pt-32 ml-24 overflow-auto">
            <div className={'w-11/12'}>
                <div className={'mb-7'}>
                    <TextAlpha text={"Users"}
                               className={"text-alphaTauText text-[1.688rem] text-titleSize cursor-pointer font-bold"}
                               onClick={() => navigate('/users')}/>
                    <TextAlpha text={right}/>
                    <TextAlpha text={" New user"} className={"text-alphaTauButton text-[1.5rem] text-titleSize"}/>

                </div>
                <FormCard
                    cardClass={'pb-[1.875rem]'}
                    cardTitle={"User info"}
                    data={<AlphaForm
                        data={formType}
                        formData={entry}
                        onChange={e => onChange(e.target.name, e.target.value)}
                        containerClassName={"mb-2 mr-12"}
                        labelClassName={Constants.inputLabelClass}
                        inputClassName={Constants.inputClass}
                        isShown={error}
                        isError={isError}
                    />}
                />
                {userSites.map((item, index) => {
                    return <FormCard
                        key={index + "site"}
                        cardClass={'pb-[1.875rem]'}
                        blockClass={'mt-5'}
                        cardTitle={"Sites"}
                        hasDeleteIcon={true}
                        length={userSites.length}
                        deleteCurrent={() => deleteCurrent(index)}
                        data={<AlphaForm
                            key={index + "site"}
                            chosenOptions={chosenSites}
                            data={siteForm[index]}
                            formData={userSites[index]}
                            onChange={e => onChangeSiteForm(index, e.target.name, e.target.value)}
                            containerClassName={"mb-2 mr-12"}
                            labelClassName={Constants.inputLabelClass}
                            inputClassName={Constants.inputClass}
                            isShown={siteFormErrors[index]}
                            isError={isError}
                        />
                        }/>
                })}
                {userRole === "SystemAdmin" &&
                <div
                    className={'text-alphaTauButton text-[1.25rem] h-[3.5rem] w-[8rem] font-bold font-Roboto flex items-center cursor-pointer'}
                    onClick={() => addSites()}>
                    <SVGraphics className={'w-6 h-6 mr-[0.75rem]'} svgname={'plus-circle'}/>
                    Add Site
                </div>
                }

                <div className={'float-right my-8 flex items-center pb-[3.125rem]'}>
                    <button type="button"
                            disabled={false}
                            onClick={() => createUser()}
                            className="mr-6 w-loginButton text-white bg-alphaTauButton hover:bg-alphaTauButtonHover font-Roboto font-medium rounded-rounded10 text-textSize px-5 py-2.5 text-center" >
                        Create
                    </button>
                    <div className={'ml-10 underline underline-offset-4 text-alphaTauButton text-[1.313rem] cursor-pointer'}
                         onClick={() => navigate('/users')}>Cancel
                    </div>
                </div>
            </div>
        </div>
    )

}