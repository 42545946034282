import {SelectAlpha} from "../../input/SelectAlpha";
import React from "react";
import Constants from "../../../utils/constants";
import {Error} from "../../forms/Error";

export function ScriptForm(props) {
    const days = ['Su','Mo','Tu','We','Th','Fr','Sa']
    const month = Constants.month

    return(
        <div className={"mb-6 mr-12 lg:mb-6 lg:mr-12 flex lg:flex-row items-center md:ml-16 lg:ml-2 md:mr-0 md:flex-col"}>
            <div>

            <div className={"mt-5"} ><SelectAlpha
                labelClassName={"font-Roboto text-inputSize text-alphaTauText font-bold mb-2"}
                inputClassName={"select-repeat w-[21.75rem] bg-white rounded-xl h-14 bg-transparent font-Roboto text-alphaTauText "}
                isError={props.isError}
                containerClassName={"mb-6 mr-12"}
                disabled={props.disabledRepeat}
                name={'repeat'}
                placeholder={'Repeat'}
                onChange={val => props.onChange({target: {name:'repeat', value: val}})}
                data={ [{"label": "Daily", "value": "daily"},{"label": "Weekly", "value": "weekly"},{"label": "Monthly", "value": "monthly"}]}
                label={'Repeat'}
                isIcon={true}
                svgName={'refresh'}
                value={props.data['repeat'] ? props.data['repeat'] : ''}
                // selected={data[f.name] ? data[f.name] : ''}
            />
                <Error
                    message={props.error}
                    isShown={props.isError && props.isShown['repeat']}/>
            </div>
                <div className={" mt-5 "} ><SelectAlpha
                    labelClassName={"font-Roboto text-inputSize text-alphaTauText font-bold mb-2"}
                    inputClassName={"w-[21.75rem] bg-white rounded-xl h-14 bg-transparent font-Roboto text-alphaTauText "}
                    isError={props.isError}
                    containerClassName={" mb-6 mr-12"}
                    name={'scriptStartTime'}
                    placeholder={'Start time'}
                    disabled={props.disabledScriptStartTime}
                    onChange={val => props.onChange({target: {name:'scriptStartTime', value: val}})}
                    data={ Constants.hoursAmPm}
                    label={'Start Time'}

                    isIcon={true}
                    svgName={'clock'}
                    value={props.data['scriptStartTime'] ? props.data['scriptStartTime'].toUpperCase() : ''}
                    // selected={data[f.name] ? data[f.name] : ''}
                />
                    <Error
                        message={props.error}
                        isShown={props.isError && props.isShown['scriptStartTime']}/>
                </div>
            </div>
                {props.data['repeat'] &&
                <>
                    {
                        props.data['repeat'] === 'monthly' ?
                            <div className={"mt-5 h-[15rem] grid grid-cols-7 gap-x-12 pt-[1.25rem] w-[22.25rem] lg:ml-0 md:ml-8 mr-20"}>
                                {month.map(month => {
                                    return <div key={month}
                                                onClick={() => props.onChange({
                                                    target: {
                                                        name: 'whenToRepeat',
                                                        value: month
                                                    }
                                                })}
                                                className={`rounded-full text-center cursor-pointer mr-2 w-[2.25rem] h-[2.25rem] ${props.data['whenToRepeat'] === month ? 'bg-alphaTauButton' : 'bg-transparent'}`}>
                                        <div
                                            className={`${props.data['whenToRepeat'] === month ? 'text-[#FFFFFF]' : 'text-alphaTauText'} font-Roboto text-[1.125rem] w-[2.25rem] h-[2.25rem] text-center  flex items-center justify-center`}>
                                            <span>{month}</span>
                                        </div>
                                    </div>
                                })
                                }
                            </div>
                            :
                            <div className={" h-[5.313rem] flex items-start mt-10 "}>
                                {days.map(day => {
                                    let isSelected = false
                                    if (props.data['repeat'] === 'daily') {
                                        if (props.data['whenToRepeat'] && Array.isArray(props.data['whenToRepeat'])) {
                                            isSelected = props.data['whenToRepeat'].find(x => x === day)
                                        }
                                    } else {
                                        isSelected = props.data['whenToRepeat'] === day
                                    }
                                    return <div key={day}
                                                onClick={() => props.onChange({
                                                    target: {
                                                        name: 'whenToRepeat',
                                                        value: day
                                                    }
                                                })}
                                                className={`rounded-full text-center cursor-pointer mr-4 w-[2.25rem] h-[2.25rem] ${isSelected ? 'bg-alphaTauButton' : 'bg-transparent'}`}>
                                        <div
                                            className={`${isSelected ? 'text-[#FFFFFF]' : 'text-alphaTauText'} font-Roboto text-[1.125rem] w-[2.25rem] h-[2.25rem] text-center  flex items-center justify-center`}>
                                            <span>{day}</span>
                                        </div>
                                    </div>
                                })
                                }
                            </div>
                    }
                </>
                }
        </div>
    )

}