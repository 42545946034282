import React, {useState,useEffect} from "react";
import {SelectAlpha} from "../../input/SelectAlpha";
import Select, {components} from "react-select";
import SVGraphics from "../../../assets/SVGraphics";
import Constants from "../../../utils/constants";
import {Error} from "../../forms/Error";
import ConfigurationApi from "../../../services/ApiServices/ConfigurationApi";
import {array, object, string} from "yup";
import {makeError} from "../../modals/ErrorModal";

export function AsymptoticActivityForm(props) {
    const NoOptionsMessage = (props) => {
        return (
            <components.NoOptionsMessage {...props} />
        );
    };
    const [entry, setEntry] = useState({repeat:'monthly',whenToRepeat:'1',scriptStartTime:'12 AM'});
    const [error, setError] = useState({})
    const [errorsMessages, setErrorserrorsMessages] = useState({})
    // const [isError, setIsError] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const options = [{label: "Daily", value: "daily"}, {label: "Weekly", value: "weekly"}, {
        label: "Monthly",
        value: "monthly"
    }]
    const month = Constants.month
    const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
    const boxShadow = {
        boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.06)"
    }
    const asymptoticActivitySchema = object().shape({
        deviation: string().required('Please insert Seed deviation'),
        numberOfMeasurementForCalculation: string().required('Please insert Number of measurement for calculation'),
        deviationOfExpecedActivity: string().required('Please insert Deviation of expected activity').default(""),
        repeat: string().required('Please insert repeat'),
        whenToRepeat: entry['repeat']==='daily'?array().required('Please insert when To Repeat'):string().required('Please insert when To Repeat'),
        scriptStartTime: string().required('Please insert script Start Time'),
    });
    useEffect(() =>{
        getAsymptoticConfiguration().then()
    },[])
    const getAsymptoticConfiguration = async() => {
        let data = await ConfigurationApi.getAsymptoticConfiguration()
        if(data && data.data){
            setEntry(data.data)
        }

    }
    const onChange = async (name, value) => {
        let config = {...entry}
        let formErrors = {...error}
        let messages = {...errorsMessages}
        if(name==='repeat' || name==='scriptStartTime'||name==='SiteID'){
            config[name] = value.value
            if(name==='repeat'){
                if(value=== 'daily'){
                    config['whenToRepeat']=[]
                }else {
                    config['whenToRepeat'] = ''
                }
            }
        }else if (name === 'whenToRepeat') {
            let days
            if (config['repeat'] === 'daily') {
                if (!config['whenToRepeat']) {
                    days = []
                } else {
                    days = [...config['whenToRepeat']]
                }
                let dayIndex = days.findIndex(x => x === value)
                if (dayIndex > -1) {
                    days.splice(dayIndex, 1)
                    config['whenToRepeat'] = days
                } else {
                    days.push(value)
                    config['whenToRepeat'] = days
                }
            } else {
                config['whenToRepeat'] = value
            }
        }else{
            config[name] = value
        }
        await asymptoticActivitySchema.validate(config,{abortEarly: false}).then(function (value) {
            formErrors[name] = false
            setDisabled(false)
            messages[name]=''
            // setIsError(false)
        })
            .catch(function (err) {
                err.inner.forEach(error => {
                    if (error.path === name) {
                        formErrors[error.path] = true
                        messages[name] = error.errors
                        setDisabled(true)
                        // setIsError(true)
                    }
                })
                let errorIndex = err.inner.findIndex(x => x.path === name)
                if (errorIndex === -1) {
                    formErrors[name] = false
                    messages[name]=''
                    // setIsError(false)
                }
            });
        setEntry(config)
        setErrorserrorsMessages(messages)
        setError(formErrors)
        setDisabled(disabled)
    }
    
    const saveAsymptoticActivity = async () => {
        if(entry['repeat'] && entry['repeat']==='daily'){
            entry['whenToRepeat'] =  entry['whenToRepeat'].toString()
        }
        let config = await ConfigurationApi.saveAsymptoticConfiguration(entry)
        if(config ){
            await makeError({proceedLabel: 'Ok', options:{title:"Asymptotic activity settings"}},["Asymptotic activity settings saved"]);
        }
    }


    return (
        <div className={'mt-4 mb-4 flex h-[93%] overflow-x-hidden overflow-y-auto w-[100%]'}>
            <div className={'w-[100%]'}>
                <div className={'mt-4 flex md:flex-col lg:items-start justify-between md:items-start lg:flex-row w-[70%] lg:w-[100%] xl:w-[100%] 2xl:w-[70%] md:w-[100%] py-4 px-4 rounded-[10px] pr-6 items-start'}>
                    <div className={props.classes.labelClass}>Schedule asymptotic measurement script</div>
                    <div >
                        <div>
                            <Select
                                className={`inputClass w-[21.55rem] bg-white rounded-xl h-14 bg-transparent font-Roboto text-alphaTauText`}
                                classNamePrefix="select"
                                isSearchable={false}
                                onChange={val => onChange( 'repeat', val)}
                                selected={entry['repeat']}
                                value={options.find(option=>option.value===entry['repeat'])}
                                components={{NoOptionsMessage}}
                                name="repeat"
                                options={options}
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        border: "1px solid #D7DDDF",
                                        backgroundColor: "#FFFFF",
                                        width: "100%",
                                        height: "3.5rem",
                                        color: "#444444",
                                        textTransform: "capitalize",
                                        boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.06)",
                                        fontSize: "16px",
                                        outline: "unset",
                                        paddingLeft: '2.5rem',
                                        borderRadius: "0.75rem"

                                    })
                                }}
                            />
                            <SVGraphics className={'relative w-[18px] h-[22px] '} style={{margin: '-39px 0 17px 19px'}}
                                        svgname={'refresh'}/>
                            <Error
                                message={errorsMessages['repeat']}
                                isShown={error['repeat']}
                            />
                        </div>
                        <div >
                            {entry['repeat'] &&
                            <>
                                {
                                    entry['repeat'] === 'monthly' ?
                                        <div
                                            className={"h-[15rem] grid grid-cols-7 gap-x-10 pt-[1.25rem] w-[20.50rem] "}>
                                            {/*<div className={'flex '}>*/}
                                            {month.map(month => {
                                                return <div key={month}
                                                            onClick={() => onChange('whenToRepeat',month)}
                                                            className={`rounded-full text-center cursor-pointer w-[2.25rem] h-[2.25rem] ${entry['whenToRepeat'] === month ? 'bg-alphaTauButton' : 'bg-transparent'}`}>
                                                    <div
                                                        className={`${entry['whenToRepeat'] === month ? 'text-[#FFFFFF]' : 'text-alphaTauText'} font-Roboto text-[1.125rem] w-[2.25rem] h-[2.25rem] text-center  flex items-center justify-center`}>
                                                        <span>{month}</span>
                                                    </div>
                                                </div>
                                            })
                                            }
                                            {/*</div>*/}
                                        </div>
                                        :
                                        <div className={" h-[5.313rem] flex items-center"}>
                                            {/*<div className={'flex '}>*/}
                                            {days.map(day => {
                                                let isSelected = false
                                                if (entry['repeat'] === 'daily') {
                                                    if (entry['whenToRepeat'] && Array.isArray(entry['whenToRepeat'])) {
                                                        isSelected = entry['whenToRepeat'].find(x => x === day)
                                                    }
                                                } else {
                                                    isSelected = entry['whenToRepeat'] === day
                                                }
                                                return <div key={day}
                                                            onClick={() => onChange('whenToRepeat', day)}
                                                            className={`rounded-full text-center cursor-pointer mr-2 w-[2.25rem] h-[2.25rem] ${isSelected ? 'bg-alphaTauButton' : 'bg-transparent'}`}>
                                                    <div
                                                        className={`${isSelected ? 'text-[#FFFFFF]' : 'text-alphaTauText'} font-Roboto text-[1.125rem] w-[2.25rem] h-[2.25rem] text-center  flex items-center justify-center`}>
                                                        <span>{day}</span>
                                                    </div>
                                                </div>
                                            })
                                            }
                                        </div>
                                }
                                <Error
                                    message={errorsMessages['whenToRepeat']}
                                    isShown={error['whenToRepeat']}
                                />
                            </>

                            }
                            <div className={""}>
                                <div className={'flex flex-col'}>
                                <SelectAlpha
                                    // labelClassName={"font-Roboto text-inputSize text-alphaTauText font-bold mb-2"}
                                    inputClassName={" w-[21.55rem] bg-white rounded-xl h-14 bg-transparent font-Roboto text-alphaTauText "}
                                    // isError={props.isError}
                                    containerClassName={" mb-6"}
                                    inputBg={"#FFFFF"}
                                    name={'scriptStartTime'}
                                    placeholder={'Start time'}
                                    onChange={val => onChange( 'scriptStartTime',  val)}
                                    data={Constants.hoursAmPm}
                                    // label={'Start Time'}
                                    isIcon={true}
                                    svgName={'clock'}
                                    svgClass={'z-0'}
                                    value={entry['scriptStartTime'] ? entry['scriptStartTime'] : ''}
                                    // selected={data[f.name] ? data[f.name] : ''}
                                />
                                <Error
                                    message={errorsMessages['scriptStartTime']}
                                    isShown={error['scriptStartTime']}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"h-[1px] border border-b-stone-100 w-[100%] "}/>
                <div className={props.classes.inputContainerClass}>
                    <div className={props.classes.labelClass}>Deviation of expected activity</div>
                    <div className={'flex flex-col'}>
                    <input
                        className={props.classes.inputClass}
                        type={"text"}
                        onChange={e => onChange(e.target.name, e.target.value)}
                        style={boxShadow}
                        value={entry['deviationOfExpecedActivity']?entry['deviationOfExpecedActivity']:''}
                        name="deviationOfExpecedActivity"
                    />
                    <Error
                        message={errorsMessages['deviationOfExpecedActivity']}
                        isShown={error['deviationOfExpecedActivity']}
                    />
                    </div>
                </div>

                <div className={props.classes.inputContainerClass}>
                    <div className={props.classes.labelClass}>Seed deviation</div>
                    <div className={'flex flex-col'}>
                    <input
                        className={props.classes.inputClass}
                        type={"text"}
                        onChange={e => onChange(e.target.name, e.target.value)}
                        style={boxShadow}
                        value={entry['deviation']?entry['deviation']:''}
                        name="deviation"
                    />
                    <Error
                        message={errorsMessages['deviation']}
                        isShown={error['deviation']}
                    />
                    </div>
                </div>

                <div className={props.classes.inputContainerClass}>
                    <div className={props.classes.labelClass}>Number of measurement for calculation</div>
                    <div className={'flex flex-col'}>
                    <input
                        className={props.classes.inputClass}
                        type={"text"}
                        onChange={e => onChange(e.target.name, e.target.value)}
                        style={boxShadow}
                        value={entry['numberOfMeasurementForCalculation']?entry['numberOfMeasurementForCalculation']:''}
                        name="numberOfMeasurementForCalculation"
                    />
                    <Error
                        message={errorsMessages['numberOfMeasurementForCalculation']}
                        isShown={error['numberOfMeasurementForCalculation']}
                    />
                    </div>
                </div>

                <div className={'w-[100%] flex justify-end my-8 mx-4'}>
                    <button type="button"
                            onClick={() => saveAsymptoticActivity()}
                            className="mr-[11rem] mb-4 w-[10rem] text-white bg-alphaTauButton hover:bg-alphaTauButtonHover font-Roboto font-medium rounded-rounded10 text-textSize px-5 py-1 text-center">
                        Save
                    </button>
                </div>
            </div>


        </div>

    )
}
