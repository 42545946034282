import { authenticationService } from '../AuthinticationService'
import Config from '../../config/Config'
import restApi from './RestApi'

const globalUrl = Config.globalUrl

const ConfigurationApi = {
  getAsymptoticConfiguration: async () => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.get(`${globalUrl}/api/v1/webapp/${user.profile.UserStorageId}/configurations/asymptotic`, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getStations: async (siteID) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.get(
        `${globalUrl}/api/v1/webapp/${user.profile.UserStorageId}/configurations/stations?SiteID=${siteID}`,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  saveAsymptoticConfiguration: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/${user.profile.UserStorageId}/configurations/asymptotic`, body, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getCalculatorConfiguration: async (id) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.get(`${globalUrl}/api/v1/webapp/${user.profile.UserStorageId}/configurations/calculator/${id}`, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getDataByStation: async (station, siteID) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.get(
        `${globalUrl}/api/v1/webapp/${user.profile.UserStorageId}/configurations/getDataByStation/?station=${station}&SiteID=${siteID}`,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  saveCalculatorConfiguration: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/${user.profile.UserStorageId}/configurations/calculator`, body, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  saveMeasurementConfiguration: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/${user.profile.UserStorageId}/configurations/measurement`, body, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  }
}
export default ConfigurationApi
