import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  pageIndex: 0,
};

export const OrdersReducer = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setPage: (state, action) => {
      // console.log("action.payload",action.payload)
      let _pageIndex =action.payload
      // let newState =[]

      // chambers.map(item=>{
      //   console.log(item)
      //   newState.push({...item, ...item.PII, ...item.User})
      // })
      state.pageIndex = _pageIndex;
    },
  },
});

export const { setPage } = OrdersReducer.actions;
export default OrdersReducer.reducer;
