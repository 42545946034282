import React, {forwardRef} from "react";
import SVGraphics from "../../assets/SVGraphics";
import Select, { components } from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export function SelectAlphaIcon(props) {
    let value = props.data?.find(option => option.value === props.value)
    const style = {
        border: "unset",
        backgroundColor: props.backgroundColor?props.backgroundColor:"#ffffff",
        width: "100%",
        height: "3.5rem",
        color: "#BABABA",
        textTransform: "capitalize",
        boxShadow: 'unset',
        fontSize: "18px",
        outline: "unset",
        borderRadius: "0.75rem"
    }
    const inputWidth = props.inputWidth?props.inputWidth:'21rem'
    const bgColor = props.backgroundColor?props.backgroundColor:"#ffffff"
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div style={{width:inputWidth, backgroundColor:bgColor}} className={`dateSelect flex h-[3.5rem] w-[20rem]  lg:w-[17rem] xl:w-[20rem] rounded-[0.75rem] `}>
            <input onChange={props.onChangeRaw} className={`dateSelect flex h-[3.5rem] rounded-[0.75rem] pl-12 w-full lg:w-[17rem] font-Roboto text-[#444444] text-[1.125rem] placeholder:font-normal placeholder:text-[#ACAEB1] placeholder:text-[#BABABA] opacity-100 placeholder:opacity-100` } style={style} defaultValue={value} placeholder={props.label} onClick={onClick} ref={ref}/>
        </div>

    ));
    const NoOptionsMessage = (props) => {
        return (
                <components.NoOptionsMessage {...props} />
        );
    };

    return (
        <div className={`SelectAlphaIcon ${props.class} ${props.mb?props.mb:"mb-6"} `} >

            {props.type === 'Select' ?
                <Select
                    className={"pl-10 w-[20rem] lg:w-[17rem] xl:w-[20rem] bg-white rounded-xl bg-transparent text-[1.125rem] font-Roboto text-alphaTauText font-bold placeholder:font-normal placeholder:text-[#ACAEB1] shadow-[0_4px_6px_0px_rgba(0,0,0,0.06)]"}
                    name={props.name}
                    disabled={props.disabled}
                    value={value?value:null}
                    // value={props.value}
                    selected={props.selected}
                    components={{ NoOptionsMessage }}
                    onChange={val => props.onChange({target:{name: props.name, value: val}})}
                    isClearable
                    placeholder={<div className={'text-[#ACAEB1] font-normal font-Roboto text-[1.125rem]'}>{props.label}</div>}
                    classNamePrefix="select"
                    options={props.data}
                    // menuIsOpen={true}
                    styles={{
                        control: (base) => ({
                            ...base,
                            border: "unset",
                            backgroundColor: "#ffffff",
                            width: "100%",
                            height: "3.5rem",
                            // color: "#BABABA",
                            textTransform: "capitalize",
                            boxShadow: 'unset',
                            // fontSize: "18px",
                            outline: "unset",
                            borderRadius: "0.75rem"
                        })
                    }}
                />:
                props.type==='date'?

                    <DatePicker
                        calendarClassName={'search-input-date '}
                        selected={props['isRange']?props.startDate:props.selected}
                        onChange={props.onChangeDate}
                        startDate={props.startDate}
                        endDate={props.endDate}
                        placeholderText={props.label}
                        selectsRange={!!props['isRange']}
                        disabled={props.disabled}
                        dateFormat="dd/MM/yyyy"
                        isClearable={props.isClearable!=="false"}
                        minDate={props.minDate}
                        // inline
                        customInput={<ExampleCustomInput
                        placeholder={props.name}/>}
                    />
                    :null
            }
            <SVGraphics className={`select-input-icon ${props.svgClass}`} svgname={props.svgName}/>
        </div>
    )
}
