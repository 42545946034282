import React from "react";
import {TextAlpha} from "./TextAlpha";

export function TextAreaAlpha(props) {

    return (
        <div className={props.containerClassName}>
            <TextAlpha text={props.label} className={props.labelClassName} style={props.textAreaStyle}/>

            <textarea disabled={props.disabled} name={props.name} maxLength={props.maxLength} className={props.textAreaInputClassName} placeholder={props.placeholder} onChange={props.onChange} value={props.value}/>
        </div>
    )
}
