import { authenticationService } from '../AuthinticationService'
import Config from '../../config/Config'
import restApi from './RestApi'

const globalUrl = Config.globalUrl

const SystemAdminApi = {
  createUser: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let SystemAdmin = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: SystemAdmin?.token
        }
      }
      return await restApi.post(
        `${globalUrl}/api/v1/webapp/userManagement/${SystemAdmin.profile.UserStorageId}/createUser`,
        body,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  editUser: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let SystemAdmin = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: SystemAdmin?.token
        }
      }
      return await restApi.post(
        `${globalUrl}/api/v1/webapp/userManagement/${SystemAdmin.profile.UserStorageId}/editUser/${body.UserStorageId}`,
        body,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  unlockUser: async (userid) => {
    try {
      if (!authenticationService.currentUserValue) return
      let SystemAdmin = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: SystemAdmin?.token
        }
      }
      return await restApi.get(
        `${globalUrl}/api/v1/webapp/userManagement/${SystemAdmin.profile.UserStorageId}/unlockUser/${userid}`,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getAllUsers: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let SystemAdmin = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: SystemAdmin?.token
        }
      }
      return await restApi.post(
        `${globalUrl}/api/v1/webapp/userManagement/${SystemAdmin.profile.UserStorageId}/getAllUsers`,
        body,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },

  getAllSitesAdvanced: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let SystemAdmin = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: SystemAdmin?.token
        }
      }
      return await restApi.post(
        `${globalUrl}/api/v1/webapp/sites/${SystemAdmin.profile.UserStorageId}/getAllSitesAdvanced`,
        body,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getSitesCountry: async () => {
    try {
      if (!authenticationService.currentUserValue) return
      let SystemAdmin = authenticationService.currentUserValue

      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: SystemAdmin?.token
        }
      }
      return await restApi.get(`${globalUrl}/api/v1/webapp/sites/${SystemAdmin.profile.UserStorageId}/getSitesCountry`, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getSite: async (id) => {
    try {
      if (!authenticationService.currentUserValue) return
      let SystemAdmin = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: SystemAdmin?.token
        }
      }
      return await restApi.get(`${globalUrl}/api/v1/webapp/sites/${SystemAdmin.profile.UserStorageId}/getSite/${id}`, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  editSite: async (body, id) => {
    try {
      if (!authenticationService.currentUserValue) return
      let SystemAdmin = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: SystemAdmin?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/sites/${SystemAdmin.profile.UserStorageId}/editSite/${id}`, body, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  createSite: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let SystemAdmin = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: SystemAdmin?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/sites/${SystemAdmin.profile.UserStorageId}/createSite`, body, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getAdminByEmail: async (email) => {
    try {
      if (!authenticationService.currentUserValue) return
      let SystemAdmin = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: SystemAdmin?.token
        }
      }
      return await restApi.get(
        `${globalUrl}/api/v1/webapp/userManagement/${SystemAdmin.profile.UserStorageId}/getAdminByEmail/${email}`,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  disableSite: async (sitId) => {
    try {
      if (!authenticationService.currentUserValue) return
      let SystemAdmin = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: SystemAdmin?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/sites/${SystemAdmin.profile.UserStorageId}/disableSite`, sitId, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  }
}
export default SystemAdminApi
