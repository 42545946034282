import React, {useEffect, useState} from "react";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import OrdersApi from "../../../services/ApiServices/OrdersApi";
import {TextAlpha} from "../../input/TextAlpha";
import {FooterSaveButtons} from "../../input/FooterSaveButtons";
import Constants from "../../../utils/constants";
import {Table} from "../../table/table";
import {AlertConfirm} from "../../modals/Confirm";
import * as XLSX from 'xlsx';
import {EditOrderPlan} from "./EditOrderPlan";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {authenticationService} from "../../../services/AuthinticationService";
import utils from "../../../utils/utils";


export function ViewPlan() {
    const navigate = useNavigate();

    const [planData, setPlanData] = useState([]);
    const [orderName, setOrderName] = useState("");
    const [orderStatus, setOrderStatus] = useState("");
    const [openEditPlan, setOpenEditPlan] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [siteId, setSiteId] = useState('');
    const [SIBD_ALPH_FIXATION, setSIBD_ALPH_FIXATION] = useState("");
    const [SIBD_ALPH_SEEDL, setSIBD_ALPH_SEEDL] = useState("");
    const [userRole, setUserRole] = useState(null);

    let params = useParams();
    const right = <span className={"text-[#444444] text-2xl text-titleSize ml-2"}>&#8250;</span>

    const location = useLocation();

    useEffect(() => {
        let user = authenticationService.currentUserValue
        setUserRole(user.role[0])
        getPlans()
    }, [params.id])

    const planSaved = () => {
        getPlans()
        setOpenEditPlan(false)
    }

    const getPlans = () => {
        let id = params.id
        if (id) {
            if(location.state){
                let ORDNAME = location.state.ORDNAME

                if(location.state.isCalculated){
                    arrangePlan(location.state.plan)
                }else{
                    getData().then(result => {
                        arrangePlan(result.data)
                    })
                }
                setOrderName(ORDNAME)
            }
        }
    }

    const arrangePlan = (data) => {
        let dataArr = utils.arrangePlan(data)
        let status = data.planStatus
        let seedl = data && data['SIBD_SEEDLEN'] ? data['SIBD_SEEDLEN'] : ""
        let fixation = data && data.SIBD_ALPH_FIXATION ? data.SIBD_ALPH_FIXATION : ""
        setPlanData([...dataArr])
        setSIBD_ALPH_FIXATION(fixation)
        setSIBD_ALPH_SEEDL(seedl)
        setOrderStatus(status)
    }

    const getData = async () => {
        let siteId = location.state ? location.state.SiteID : ""
        setSiteId(siteId)
        let planId = location.state ? location.state.planId : ""
        return await OrdersApi.getSubPlans({planID: planId, siteid: siteId})
    }

    const approveOrder = async () => {
        let orderID = location.state ? location.state.orderID : ""
        let isViewPlan = location.state?.viewPlan
        let savedPlan
        let plan
        if(!isViewPlan) {
            let planToSave = location.state ? location.state.plan : ""
            planToSave['SiteID'] = location.state.SiteID
            savedPlan = await OrdersApi.savePlan({plans: planToSave})
            if (savedPlan && savedPlan.data) {
                plan = savedPlan.data
            }
        } else {
                plan = location.state ? location.state.plan : ""
            }


        let affectedOrders = []
        let confirmApprove = true
        if (plan && plan.affectedOrders) {
            plan.affectedOrders.map(item => affectedOrders.push(item.OrderID))

            confirmApprove = await AlertConfirm({
                proceedLabel: 'Yes',
                cancelLabel: 'No',
                options: {hasSVG: true, svgName: "alert-triangle"},

            }, "Are you sure you want to continue?", `Plan ${plan.Name} is about to be overwritten`)

        }
        if(location.state.orderEdited){
            await OrdersApi.editOrder(location.state.editedData,params.id,location.state.SiteID,orderName)
        }
        if (confirmApprove) {
            let result = await OrdersApi.approvePlan(plan['PlanID'], {orderid: orderID, affectedOrders: affectedOrders})
            if (result && result.data) {
                let confirmApprove = await AlertConfirm({
                    proceedLabel: 'Ok',
                    cancelLabel: 'none',
                    options: {hasSVG: true, svgName: "check-circle2"},

                }, "", `Plan ${params?.id} is approved`)

                if(confirmApprove)
                    navigate(`/orders`)
            }
        }
    }


    const columns = [
        {
            Header: ' Chamber ID',
            accessor: 'chamberID', // accessor is the "key" in the data
            // sortType: 'string'
        },
        {
            Header: 'Start Charging Date And Time',
            accessor: 'startTimeDate',
            // /* Cell: (cell) => {
            //      return (<div>
            //              {cell.row.original.EDATE ? moment(cell.row.original.EDATE).format('MMM DD YY') : ''}
            //          </div>
            //      )
            //  }*/
        },
        {
            Header: 'End Charging Date And Time',
            accessor: 'endTimeDate',
            /*  Cell: (cell) => {
                  return (<div>
                          {cell.row.original.EDATE ? moment(cell.row.original.EDATE).format('MMM DD YY') : ''}
                      </div>
                  )
              }*/
        },

        {
            Header: 'Charging Time (H)',
            accessor: 'chargeTime',
        },
        {
            Header: 'Exp. Activity At Pick Up (μCi)',
            accessor: 'activityAtDelivery',
        },
        {
            Header: 'Exp. Shedding Loss',
            accessor: 'expectedSheddingLoss',
        },
        {
            Header: 'Exp. Activity At The Procedure (KBq) ',
            accessor: 'expectedActivityAtProcedure',
        },
        {
            Header: 'Exp. Activity At The Procedure (μCi)',
            accessor: 'expectedActivityAtProcedure_M',
        },
        {
            Header: 'Status',
            accessor: 'subPlanStatus',
        },

    ]

    // const cancelEdit = async () => {
    //     navigate('/systemAdmin/orders')
    // }

    const exportToCsv = async () => {
        let dataToExport = []
        dataToExport.push(["Order ID", "Type of seeds", "Length of seeds"])
        dataToExport.push([orderName, SIBD_ALPH_FIXATION, SIBD_ALPH_SEEDL])

        if (planData.length > 0) {
            let keys = Object.keys(planData[0])
            dataToExport.push(keys)
            for (let i = 0; i < planData.length; i++) {
                let row = []
                for (let j = 0; j < keys.length; j++) {
                    row.push(planData[i][keys[j]])
                }
                dataToExport.push(row)
            }
        }
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(dataToExport);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});

        const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        let fileName = params ? "Plan-" + params.id : "Plan-"
        link.href = url;
        link.setAttribute('download', `${fileName}.xlsx`);
        document.body.appendChild(link);
        link.click();
        await DownloadComponentAsPDF()
    }

    const DownloadComponentAsPDF = async() => {

            // Reference to the component you want to capture
            const componentRef = document.getElementById('component-to-capture');

            // Use html2canvas to capture the content as an image
            const canvas = await html2canvas(componentRef);

            // Create a new jsPDF instance
            const pdf = new jsPDF();

            // Calculate the ratio to ensure the proper scale in the PDF
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 200; // Width of the A4 paper
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            // Add the captured image to the PDF
            pdf.addImage(imgData, 'PNG', 5, 0, imgWidth, imgHeight);
            // Download the PDF
            pdf.save('downloaded-file.pdf');

    }

    /**
     * navigate previous page
     * @returns {Promise<void>}
     * @constructor
     */
    const GoBackToPlans = async () => {
        let plansView = location.state && location.state.viewFrom && location.state.viewFrom === "plans"
        let siteId = location.state ? location.state.SiteID : ""
        let ORDNAME = location.state ? location.state.ORDNAME : ""
        let orderID = location.state ? location.state.orderID : ""
        if (plansView) {
            navigate(`/orders/orderPlans/${orderID}`, {
                state: {
                    SiteID: siteId, ORDNAME: ORDNAME, orderID: orderID,
                    isCalculate: location.state.isCalculated,calculateOrder:location.state.calculateOrder

                }
            })
        } else {
            navigate(`/orders/calculateOrder/${orderID}`)
        }

    }
    const editPlan = (row) => {
        if(new Date() < new Date(row.original.endTimeDate)){
            if(orderStatus==="pendingApproval" || orderStatus==="approvedOrders" && (userRole ==='SystemAdmin' || userRole ==='ProductAdmin')){
                setSelectedPlan(row.original)
                setOpenEditPlan(true)
            }
        }
    }
    const closeEditPlan = async () => {
        getPlans()
        await setOpenEditPlan(false)
    }

    return (
        <div className="w-full h-[100%] pt-32 ml-24 lg:ml-24 md:ml-8 font-Roboto overflow-y-auto ">
            <div className={'w-[93%] h-[60%]'}>
                <div id="component-to-capture">
                <div className={'mb-7 flex items-center justify-between'}>
                    <div>
                        <TextAlpha text={"Orders"}
                                   className={"text-alphaTauText text-[1.688rem] text-titleSize cursor-pointer font-bold"}
                                   onClick={() => navigate('/orders')}
                        />
                        {location.state && location.state.viewFrom && location.state.viewFrom === "plans" &&
                            <>
                                <TextAlpha text={right}/>
                                <TextAlpha text={" Plans"} className={"text-[#444444] text-[1.5rem] cursor-pointer"}
                                           onClick={() => {
                                               GoBackToPlans().then()
                                           }}
                                />
                            </>
                        }
                        <TextAlpha text={right}/>
                        <TextAlpha text={` Plan ${params?.id}`} className={"text-alphaTauButton text-[1.5rem] "}/>
                    </div>
                    {/*<div className={'flex font-Roboto text-[2.25rem] font-bold'}>*/}
                    {/*    <div className={' text-[#444444]'}>Order №:</div>*/}
                    {/*    <div className={'text-[#17597C] ml-2'}>{plan?.ORDNAME}</div>*/}
                    {/*</div>*/}
                </div>

                <div className={"w-tableWidth h-[3.938rem] bg-[#F2F5FA] rounded-md flex justify-evenly pt-[1.188rem] pb-[1.188rem]"}>
                    <div className={''}>
                        <span className={"font-Roboto  font-bold text-21 leading-25 text-black"}>Order ID:</span>
                        <span
                            className={"ml-[1rem] font-Roboto font-normal text-21 leading-25 text-black"}>{orderName}</span>
                    </div>
                    <div className={''}>
                        <span className={"font-Roboto  font-bold text-21 leading-25 text-black"}>Type of seeds:</span>
                        <span
                            className={"ml-[1rem] font-Roboto font-normal text-21 leading-25 text-black"}>{SIBD_ALPH_FIXATION}</span>
                    </div>
                    <div className={''}>
                        <span className={"font-Roboto font-bold text-21 leading-25 text-black"}>Length of seeds:
                        </span>
                        <span
                            className={"ml-[1rem] font-Roboto font-normal text-21 leading-25 text-black"}>{SIBD_ALPH_SEEDL} mm
                        </span>
                    </div>
                </div>

                <div className={"w-tableWidth overflow-auto h-max"}>
                    <Table data={planData ? planData : []} columns={columns} noPlansData={true}
                           onClick={editPlan}
                        // isLoading={isLoading}
                    />
                </div>

                </div>
                <div className={'absolute top-[80%] w-11/12 flex pr-14'}>
                    <FooterSaveButtons
                        saveButtonClass={userRole==='TeamLeader' && orderStatus==="approvedOrders" ? 'hidden':''}
                        disabledSave={location.state && location.state.viewFrom && location.state.viewFrom === "order"}
                        onClickSave={() => approveOrder()}
                        containerClass={'lg:flex-row justify-between items-end w-10/12'}
                        saveLabel={'Approve'}
                        // buttonsClass={' mr-2'}
                        onClickCancel={() => GoBackToPlans()}
                        cencelLabel={'Back'}
                        children={<button type="button"
                            // disabled={props.disabledSave}
                                          onClick={() => exportToCsv()}
                                          className={`${Constants.buttonClass} mr-12 w-loginButton text-white bg-alphaTauButtonSecondary hover:bg-alphaTauButtonHover font-Roboto font-medium rounded-rounded10 text-textSize px-5 py-2.5 text-center h-[3.5rem]`}>
                            Download
                        </button>}
                    />
                </div>
            </div>
            {openEditPlan &&
                <EditOrderPlan
                    order={selectedPlan}
                    orderId={location.state.orderId}
                    closeEditPlan={()=>closeEditPlan()}
                    sheddingLoss={location.state.sheddingLoss}
                    siteId={siteId}
                    cancelEdit={() => {
                        setOpenEditPlan(false)
                        setSelectedPlan(null)
                    }}
                    planSaved={()=>planSaved()}
                />
            }

        </div>
    )


}