import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import OrdersApi from "../../../services/ApiServices/OrdersApi";
import {TextAlpha} from "../../input/TextAlpha";
import SVGraphics from "../../../assets/SVGraphics";
import {FooterSaveButtons} from "../../input/FooterSaveButtons";
import Constants from "../../../utils/constants";
import {AlertConfirm} from "../../modals/Confirm";


export function OrderPlan() {
    const navigate = useNavigate();
    const location = useLocation();
    const [ordName, setOrdName] = useState("")
    const [plans, setPlans] = useState([])
    const [partialPlans, setPartialPlans] = useState([])
    const [alternativePlans, setAlternativePlans] = useState([])
    const [selectedPlan, setSelectedPlan] = useState(null)
    const [openWarningPlan, setOpenWarningPlan] = useState(false)
    const [activeKey, setActiveKey] = useState("");
    const [isCalculated, setIsCalculated] = useState(false)
    const [calculateOrder, setCalculateOrder] = useState({})
    const [orderEdited, setOrderEdited] = useState(false)
    const [editedData, setEditedData] = useState({})
    const plansHeader = ['***', 'Plan', 'Working Hours', 'Weekends', 'Nights', 'Chambers', '@@@']


    let params = useParams();
    const right = <span className={"text-[#444444] text-2xl text-titleSize ml-2"}>&#8250;</span>

    useEffect(() => {
        if(location.state.isCalculate){
            setIsCalculated(location.state.isCalculate)
            setCalculateOrder(location.state.calculateOrder)
        }
        setOrderEdited(location.state?.orderEdited)
        setEditedData(location.state.editedData)
        let id = params.id
        let alternativePlansArr=[]
        if (id) {
            if( location.state.isCalculate){
                arrangeOrders(location.state.calculateOrder)
            }
            else{
                getData(id).then(result => {
                    arrangeOrders(result.data)
                    let ORDNAME=location.state ? location.state.ORDNAME:""
                    setOrdName(ORDNAME)
                })
            }

            getAlternativePlans(id).then(result => {
                let alternativeResult=result && result.data?result && result.data:[]
                alternativeResult.forEach(item => {
                    let PlanChambers = ""
                    let subPlans = item.SubPlans ? item.SubPlans : []
                    subPlans.forEach((subPlan, index) => {
                        PlanChambers = index !== subPlans.length && index !== 0 ?
                            PlanChambers + ", " + subPlan.chamberID : PlanChambers + subPlan.chamberID
                    })
                    item['isSelected'] = false
                    item['hasMore'] = true
                    item['PlanChambers'] = PlanChambers
                    alternativePlansArr.push(item)
                })


                let ORDNAME = location.state ? location.state.ORDNAME : ""
                setOrdName(ORDNAME)
                setAlternativePlans([...alternativePlansArr])

            })
        }
    }, [params.id])

    const arrangeOrders = (data) => {
        let plansArr=[]
        let partialPlansArr=[]
        let plansData= data  ?data.regular:[]
        let partialPlans =data ?data.partial:[]
        plansData.forEach(item=> {
            let PlanChambers=""
            let subPlans = item.SubPlans ?item.SubPlans:[]
            subPlans.forEach((subPlan,index)=>{
                PlanChambers =  index!==subPlans.length && index!==0 ?
                    PlanChambers  +", " + subPlan.chamberID  : PlanChambers + subPlan.chamberID
            })
            item['isSelected']=false
            item['PlanChambers']=PlanChambers
            plansArr.push(item)
        })

        partialPlans.forEach(item=> {
            let PlanChambers=""
            let subPlans = item.SubPlans ?item.SubPlans:[]
            subPlans.forEach((subPlan,index)=>{
                PlanChambers =  index!==subPlans.length && index!==0 ?
                    PlanChambers  +", " + subPlan.chamberID  : PlanChambers + subPlan.chamberID
            })
            item['isSelected']=false
            item['PlanChambers']=PlanChambers
            partialPlansArr.push(item)
        })

        setPlans([...plansArr])
        setPartialPlans([...partialPlansArr])
    }

    const getData = async (id) => {
        let siteId=location.state ? location.state.SiteID:""
        return await OrdersApi.getOrderPlans({siteid:siteId,orderid: id})
    }
    const getAlternativePlans = async (id) => {
        let siteId=location.state ? location.state.SiteID:""
        return await OrdersApi.getAlternativePlans({siteid:siteId,orderid: id})
    }

    const cancelEdit = async () => {
        // let siteId=location.state ? location.state.SiteID:""
        let orderId=params.id
        navigate(`/orders/calculateOrder/${orderId}`)
    }

    const openCollapse = (coll) => {
        if (activeKey === coll) {
            setActiveKey("")
        } else {
            setActiveKey(coll)
        }
    }
    const approveOrder = async () => {
        let isPlanOverride = selectedPlan.includes("warn");
        let isPlanPartial = selectedPlan.includes("part");
        let selectedPlanIndex = selectedPlan.substring(4)
        let confirmApprove =false
        let isViewPlan = location.state?.viewPlan
        let savedPlan
        let plan
        if(!isViewPlan){
            let planToSave = isPlanOverride?alternativePlans[selectedPlanIndex]:isPlanPartial?partialPlans[selectedPlanIndex]:plans[selectedPlanIndex]
            planToSave['SiteID'] = location.state.SiteID
            savedPlan = await OrdersApi.savePlan({plans:planToSave})
            if(savedPlan && savedPlan.data){
                plan = savedPlan.data
        }
        }else{
            plan = isPlanOverride?alternativePlans[selectedPlanIndex]:isPlanPartial?partialPlans[selectedPlanIndex]:plans[selectedPlanIndex]

        }
        let planName =plan.Name
        let affectedOrders=[]
        if (selectedPlanIndex) {
            if(isPlanOverride){
                confirmApprove = await AlertConfirm({
                    proceedLabel: 'Yes',
                    cancelLabel: 'No',
                    options:{hasSVG :true,svgName:"alert-triangle" },

                }, "Are you sure you want to continue?", `Plan ${plan.Name} is about to be overwritten`)
            }

            if(confirmApprove || !isPlanOverride || isPlanPartial){
                if(isPlanOverride){
                    plan.affectedOrders.map(item=>affectedOrders.push(item.OrderID))
                }
                if(orderEdited){
                    await OrdersApi.editOrder(editedData,params.id,location.state.SiteID,ordName)
                }
                let result = await OrdersApi.approvePlan(plan['PlanID'],{orderid: params.id,affectedOrders:affectedOrders})
                if (result && result.data) {
                 await AlertConfirm({
                        proceedLabel: 'Yes',
                        cancelLabel: 'none',
                        options: {hasSVG: true, svgName: "check-circle2"},

                    }, "", `Plan ${planName} is approved`)
                    navigate(`/orders`)
                }
            }
        }
        //approvePlan

    }


    const displayPlans = (_plans, type) => {

        const classname = 'text-[1.125rem] font-bold w-[17%]'
        let data = []
        let isPlans= type === 'plans'
        let selectedOption=type === 'plans'?"plan":type === 'warn'?"warn":"part"
        _plans.forEach((plan,index) => {
            data.push(
                <div key={plan.Name} className={'w-[88.813rem] mb-[1.25rem]'}>
                    <div style={{backgroundColor: isPlans ? '#F2F5FA' : '#FFF2F2'}}
                         className={` flex w-[88.813rem] h-[3.5rem] rounded-[0.5rem] items-center justify-between`}>
                        <div className={` text-[1.125rem] font-bold w-[7.5%] text-center flex justify-between`}>
                            <div className={'flex ml-4'}
                                 onClick={()=> {
                                     setSelectedPlan(selectedOption+index)
                                 }}>
                                <input name={'plan'} type={'radio'} value={selectedOption + index}
                                       checked={(selectedOption + index) === selectedPlan}
                                       onChange={()=> {}}
                                       className={'radio-custom-style'}/>

                            <div className={'shadow-md w-[1.875rem] h-[1.875rem] border-2 border-red relative rounded-full right-[1.7rem] top-[-0.20rem]'}/>
                            </div>
                            {!isPlans &&
                            <SVGraphics svgname={'red-warning-circle'} className={'w-[1.5rem] '}/>
                            }
                        </div>
                        <div className={`${classname} text-center cursor-pointer`} onClick={()=> {
                            let siteId = location.state ? location.state.SiteID : ""
                            let ORDNAME = location.state ? location.state.ORDNAME : ""
                            let orderID = location.state ? location.state.orderID : ""
                            navigate(`/orders/plan/${plan.Name}`, {
                                state: {
                                    SiteID: siteId, ORDNAME: ORDNAME, orderID: orderID,plan:plan,planId:plan['PlanID'],viewFrom:"plans",
                                    isCalculated:isCalculated,calculateOrder:calculateOrder,orderEdited:orderEdited,
                                    editedData:editedData,viewPlan:location.state.viewPlan,type:selectedOption
                                }
                            })
                        }
                        }>
                            {plan.Name}
                        </div>
                        <div className={`${classname} justify-center`}>
                            {/*<SVGraphics className="h-6" svgname={plan.WorkingHours ? "check-circle" : "x-circle"}/>*/}
                            <div className={`${classname} w-auto text-center`}>
                                {plan['WorkingHours']}
                            </div>

                        </div>
                        <div className={`${classname} justify-center`}>
                            <SVGraphics className="h-6" svgname={plan['IsWeekends'] ? "check-circle" : "x-circle"}/>

                        </div>
                        <div className={`${classname} justify-center`}>
                            <SVGraphics className=" h-6" svgname={plan['IsNights'] ? "check-circle" : "x-circle"}/>

                        </div>
                        <div className={`${classname} text-center`}>
                            {plan.PlanChambers}
                        </div>

                        <div className={`flex items-center w-[7.5%] justify-center`}>
                            {plan.hasMore &&
                            <div className={`text-[1.125rem] font-bold text-right text-center  cursor-pointer`}
                                 onClick={() => openCollapse(plan.Name)}
                                 style={{transform: activeKey === plan.Name ? 'rotate(270deg)' : 'rotate(90deg)'}}>&#8250;</div>
                                // <SVGraphics className="h-10" svgname={"arrowBlackDown"} onClick={()=>openCollapse(plan.Name)}/>
                            }
                        </div>
                        {type==='part' &&
                        <div className={`text-[1.125rem] font-bold w-[10%] text-center`}>
                            {plan['seedQuantity']}
                        </div>}
                    </div>
                    {activeKey === plan.Name &&
                    plan.affectedOrders?.map(affectedOrder => {
                        return <div key={affectedOrder.ORDNAME}
                                    className={`flex w-[88.813rem] h-[3.5rem] rounded-[0.5rem] items-center justify-start bg-[#F6F6F6] border border-[#EDEDED]`}>
                            <div className={'ml-[13%]'}>{affectedOrder.ORDNAME}</div>
                            <div className={'ml-[13%]'}>{affectedOrder.TYPECODE}</div>
                        </div>
                    })
                    }

                </div>)
        })
        return data
    }

    const displayHeaders = (type) => {
//seedQuantity
        let headers = JSON.parse(JSON.stringify(plansHeader))
        if(type === 'partial'){
            headers[6]='Number of seeds'
        }
        return <div
            className={'flex justify-between w-[88.813rem] h-[3.5rem] bg-[#F2F5FA] rounded-[0.5rem] items-center mb-[1.25rem]'}>
            {
                headers.map(header => {
                    return <div key={header} style={{
                        color: header !== "***" && header !== "@@@" ? "#252B42" : "transparent",
                        width: header !== "***" && header !== "@@@" ? '17%' : '7.5%'
                    }} className={`text-[1.125rem] font-bold text-center`}>
                        {header}
                    </div>
                })
            }
        </div>
    }

    return (
        <div className="w-full h-[100%] pt-32 ml-24 font-Roboto">
            <div className={'w-[93%] h-[60%]'}>
                <div className={'mb-7 flex items-center justify-between'}>
                    <div>
                        <TextAlpha text={"Orders"}
                                   className={"text-alphaTauText text-[1.688rem] text-titleSize cursor-pointer font-bold"}
                                   onClick={() => navigate('/orders')}
                        />
                        <TextAlpha text={right}/>
                        <TextAlpha text={" Plans"} className={"text-alphaTauButton text-[1.5rem] "}
                                   onClick={() => navigate('/orders')}
                        />
                    </div>
                    <div className={'flex font-Roboto text-[2.25rem] font-bold'}>
                        <div className={' text-[#444444]'}>Order №:</div>
                        <div className={'text-alphaTauButton ml-2'}>{ordName}</div>
                    </div>
                </div>
                <div className={'plans-container h-[64vh] overflow-y-auto overflow-x-hidden'}>
                    {plans && plans.length > 0 ?
                        <div>{
                            displayHeaders('regular')}
                            <div>
                                {
                                    displayPlans(plans, 'plans')
                                }
                            </div>
                        </div> :
                        <div className={'flex flex-col w-full items-center h-[18rem] justify-center'}>
                            <SVGraphics className=" h-[2.25rem]" svgname={'folder'}/>
                            <div className={'font-Roboto text-[#0D3B53] text-[1.688rem]'}>NO PLANS FOUND</div>
                        </div>
                    }
                    <div className={'mt-12'}>
                        { !openWarningPlan &&
                            <button onClick={() => setOpenWarningPlan(true)}
                                                           className={'mb-5 text-alphaTauButton font-bold underline'} style={{display:alternativePlans&&alternativePlans.length>0?'block':'none'}}>Show Overriding Plans</button>
                        }
                        { openWarningPlan &&
                            <button onClick={() => setOpenWarningPlan(false)}
                                    className={'mb-5 text-alphaTauButton font-bold underline'} style={{display:alternativePlans&&alternativePlans.length>0?'block':'none'}}>Hide Overriding Plans</button>
                        }
                        {openWarningPlan &&
                        <>
                            {displayHeaders('warning')}
                            {displayPlans(alternativePlans, 'warn')}
                        </>
                        }
                    </div>

                    {partialPlans && partialPlans.length>0 &&
                        <div className={'mt-5'}>
                            <div className={'text-[#444444] text-[1.125rem] mb-[0.969rem]'}>You may select one of the
                                partial plans:
                            </div>
                            {partialPlans && partialPlans.length > 0 &&
                                <div>{
                                    displayHeaders('partial')}
                                    <div>
                                        {
                                            displayPlans(partialPlans, 'part')
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>

            <div className={'absolute top-[83%] w-11/12 flex pr-14'}>
            <FooterSaveButtons
                disabledSave={!selectedPlan}
                onClickSave={() => approveOrder()}
                containerClass={'lg:flex-row justify-between items-end w-10/12'}
                saveLabel={'Approve'}
                // buttonsClass={' mr-2'}
                onClickCancel={() => cancelEdit()}
                cencelLabel={'Back'}
                children={   <button type="button"
                                     onClick={()=>cancelEdit()}
                                     className={`${Constants.buttonClass} mr-12 w-loginButton text-white bg-alphaTauButtonSecondary hover:bg-alphaTauButtonHover font-Roboto font-medium rounded-rounded10 text-textSize px-5 py-2.5 text-center h-[3.5rem]`}>
                    Recalculate
                </button>}
            />
            </div>

        </div>
    )


}
