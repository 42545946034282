import React, {useState} from "react";
import { Tooltip } from "react-bootstrap";
import moment from "moment";
import {v4 as uuidv4} from "uuid";


export function OrdersGanttView(props) {

    const [openPopups, setOpenPopups] = useState([]);
    const currentUser = props.currentUser
    const toolTipDataTitles = [{name: 'TYPECODE', label: 'Order Type'}, {
        name: 'SIBD_ALPH_FIXATION',
        label: 'Seed Type'
    }, {name: 'SIBD_ALPH_SEEDL', label: 'Seed length'}, {name: 'startDate', label: 'Start'}, {
        name: 'endDate',
        label: 'End'
    }]
    /**
     * return the type of order data (1- start date, 2 - end date,3-inBetween dates, 4-no order in this date , 5-end and start date
     * @param order
     * @returns {*[]}
     */
    const getOrdersData = (order,chamberName) => {
        let data = []
        const returnDate = (date,time)=>{
            return moment(date).format('DD MMM YYYY')+' '+ time
        }
        props.currentRange.forEach(range => {
            order.forEach(or=>{
                or.orderDatesRange.forEach(dateRange => {
                    let startDate = new Date(dateRange.startDate)
                    let endDate = new Date(dateRange.endDate)
                    let originStartTime = dateRange.startTime
                    let originEndTime = dateRange.endTime
                    let newRang = range.date.setHours(0, 0, 0, 0).valueOf()
                    let newStart = startDate.setHours(0, 0, 0, 0).valueOf()
                    let newEnd = endDate.setHours(0, 0, 0, 0).valueOf()
                    if (newRang === newStart) {
                        let startEnd = data.findIndex(x => x.date === newRang)
                        if (startEnd > -1) {
                            data[startEnd].startDate = returnDate(dateRange.startDate,dateRange.startTime)
                            data[startEnd].endDate =returnDate(dateRange.endDate,dateRange.endTime)
                            if (data[startEnd].type === "2") {
                                data[startEnd].type = "5"
                                data[startEnd].timeEnd = data[startEnd].time
                                data[startEnd].time = originStartTime
                                data[startEnd].color = or.color
                                data[startEnd].order = or
                            } else {
                                data[startEnd].type = "1"
                                data[startEnd].time = originStartTime
                                data[startEnd].color = or.color
                                data[startEnd].order = or
                            }
                        } else {
                            data.push({
                                day: range.day,
                                date: newStart,
                                type: "1",
                                color:or.color,
                                order:or,
                                time: originStartTime,
                                id: uuidv4(),
                                startDate: returnDate(dateRange.startDate,dateRange.startTime),
                                endDate: returnDate(dateRange.endDate,dateRange.endTime)
                            })
                        }
                    } else if (newRang === newEnd) {
                        let startEnd = data.findIndex(x => x.date === newRang)
                        if (startEnd > -1) {
                            data[startEnd].startDate = returnDate(dateRange.startDate,dateRange.startTime)
                            data[startEnd].endDate =returnDate(dateRange.endDate,dateRange.endTime)
                            if (data[startEnd].type === "1") {
                                data[startEnd].type = "5"
                                data[startEnd].color = or.color
                                data[startEnd].order = or
                            } else {
                                data[startEnd].type = "2"
                                data[startEnd].time = originEndTime
                                data[startEnd].color = or.color
                                data[startEnd].order = or
                            }
                        } else {
                            data.push({
                                day: range.day,
                                date: newRang,
                                type: "2",
                                color:or.color,
                                order:or,
                                time: originEndTime,
                                id: uuidv4(),
                                startDate: returnDate(dateRange.startDate,dateRange.startTime),
                                endDate: returnDate(dateRange.endDate,dateRange.endTime)
                            })
                        }
                    } else if (newStart < newRang && newEnd > newRang) {
                        let startEnd = data.findIndex(x => x.date === newRang)
                        if (startEnd > -1) {
                            data[startEnd].type = "3"
                            data[startEnd].startDate = returnDate(dateRange.startDate,dateRange.startTime)
                            data[startEnd].endDate = returnDate(dateRange.endDate,dateRange.endTime)
                            data[startEnd].color = or.color
                            data[startEnd].order = or
                        } else {
                            data.push({
                                day: range.day,
                                date: newRang,
                                type: "3",
                                color:or.color,
                                order:or,
                                id: uuidv4(),
                                startDate: returnDate(dateRange.startDate,dateRange.startTime),
                                endDate:returnDate(dateRange.endDate,dateRange.endTime)
                            })
                        }
                    } else {
                        let date = data.findIndex(x => x.date === newRang)
                        if (date === -1) {
                            if (data.length < 8) {
                                data.push({
                                    day: range.day,
                                    date: newRang,
                                    type: "4",
                                    color:"",
                                    order:or,
                                    id: uuidv4(),
                                    startDate:returnDate(dateRange.startDate,dateRange.startTime),
                                    endDate:returnDate(dateRange.endDate,dateRange.endTime)
                                })
                            }
                        }
                    }
                })
            })
        })
        let newData = []
        for(let d of data ){
            newData.push( <div key={d.id}
                               className={` ${d.day === 'Saturday' ? 'bg-[#D9D9D9]/30 ' : 'bg-[#F2F5FA]'} max-h-[32px] h-[32px] ${d.type === "4" ? 'border-x ' : d.type === "1" ? 'border-l' : d.type === "2" ? 'border-r' : ''} px-0 border-b border-[#D7DDDF] w-full flex items-center overflow-hidden relative right-[17px]`}>
                {getDesign(d, d.color, d.order,chamberName)}
            </div>)
        }
        // data.forEach(d=>{
        //     newData.push( <div key={d.id}
        //                 className={` ${d.day === 'Saturday' ? 'bg-[#D9D9D9]/30 ' : 'bg-[#F2F5FA]'} max-h-[32px] h-[32px] ${d.type === "4" ? 'border-x ' : d.type === "1" ? 'border-l' : d.type === "2" ? 'border-r' : ''} px-0 border-b border-[#D7DDDF] w-full flex items-center overflow-hidden relative right-[17px]`}>
        //         {getDesign(d, d.color, d.order,chamberName)}
        //     </div>)
        // })
        return newData
    }
    const Ellipsis = (props) => <svg className={'cursor-pointer w-[35px]'} width="23" height="14" viewBox="0 0 23 14"
                                     x={props.x} y={props.y} fill="none" xmlns="http://www.w3.org/2000/svg"
                                     onClick={props.onClick}>
        <path
            d="M12 7C12.5523 7 13 7.27614 13 7C13 6.72386 12.5523 6 12 6C11.4477 6 11 6.72386 11 7C11 7.27614 11.4477 7 12 7Z"
            stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M12 3C12.5523 3 13 3.27614 13 3C13 2.72386 12.5523 2 12 2C11.4477 2 11 2.72386 11 3C11 3.27614 11.4477 3 12 3Z"
            stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M12 11C12.5523 11 13 11.2761 13 11C13 10.7239 12.5523 10 12 10C11.4477 10 11 10.7239 11 11C11 11.2761 11.4477 11 12 11Z"
            stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    const approvePlane = async (order) => {
        props.approvePlane(order)
        let opensArray = JSON.parse(JSON.stringify(openPopups))
        let orderIndex = opensArray.findIndex(x=>x.OrderID===order.OrderID)
        if(orderIndex>-1){
            opensArray.splice(orderIndex,1)
        }
        await setOpenPopups(opensArray)
    }
    const orderToolTip = (order, d) => {
        return <div className={'py-[1rem] bg-[#FFFFFF] w-auto px-[2rem] border border-[#D3D3D3FF] rounded-[10px] fixed text-[#444444] text-[1.125rem] font-Roboto  w-[14.313rem]'}>
            {toolTipDataTitles.map(data => {
                // let startDate = d.startDate + ' ' + d.startTime
                return <div className={'flex justify-between py-[0.375rem]'} key={data.name}>
                    <div className={' font-semibold '}>{data.label}:</div>
                    {data.name !== 'startDate' && data.name !== 'endDate' ?
                        <div>{order[data.name]}</div> :
                        <div>{d[data.name]}</div>
                    }
                </div>
            })}
            {!order?.approved && (currentUser?.role[0] === "SystemAdmin" || currentUser?.role[0] === "ProductAdmin") ?
            <button
                className={'w-[100%] h-[3.5rem] rounded-[10px] border border-alphaTauButton text-alphaTauButton font-Roboto mt-[1.375rem] hover:bg-alphaTauButton hover:text-white'} onClick={()=>approvePlane(order)}>Approve</button>
           :null
            }
        </div>
    }

    const openPopUp = (order,chamberName) => {
        let opensArray = JSON.parse(JSON.stringify(openPopups))
        let orderIndex = opensArray.findIndex(x=>x.OrderID===order.OrderID && 'ch'+x.orderDatesRange[0].ChamberID===chamberName)
        if(orderIndex>-1){
            opensArray.splice(orderIndex,1)
        }else{
            opensArray.push(order)
        }
        setOpenPopups(opensArray)
    }

    /**
     * return the svg design of the gantt according to getOrdersData method data
     * @param d
     * @param color
     * @returns {JSX.Element}
     */
    const getDesign = (d, color, order,chamberName) => {
        let role = currentUser.role[0]
        let approvedRole = role === 'SystemAdmin' || role === 'ProductAdmin'|| role === 'TeamLeader'

        let isEnded = order.orderDatesRange.every(date=>{
            return date.endDate<new Date()
        })
        let current = openPopups.find(x=>x.OrderID===order.OrderID && chamberName === 'ch' + x.orderDatesRange[0].ChamberID)
        let onOver = <Tooltip className={"gantToolTip left-[5%] top-[1%] "}>
            {orderToolTip(order, d)}</Tooltip>
        let _stripedBackground = <pattern id={`diagonalHatch${order.OrderID}`} viewBox="0,0,8,8" width="16" height="16"
                                          patternUnits="userSpaceOnUse">
            <rect width="16" height="16" fill={order.color} style={{fill: order.color, opacity: 0.3}}/>
            <line x1="0" y1="0" x2="16" y2="16" style={{stroke: order.color, strokeWidth: 0.5}}/>
        </pattern>
        let colorStyle = {fill: order.approved ? order.color : `url(#diagonalHatch${order.OrderID})`}
        let design =<></>
        switch (d.type) {
            case "1" :
                 design =  <>
                    <svg id={`popover-trigger-${order.OrderID}-${current?.orderDatesRange[0].ChamberID}`} xmlns="http://www.w3.org/2000/svg" width="200" height="26" style={{fill: order.color}} onClick={()=>openPopUp(order,chamberName)}>
                        {!order.approved &&
                        _stripedBackground
                        }
                        <rect x="80" y="0" width="180" height="26" rx="10" ry="10" style={colorStyle} fill={order.color}/>
                        <rect x="190" y="0" width="10" height="26" style={colorStyle} fill={order.color}/>
                        <text x="85" y="17" fill="white" style={{fontSize: '13px'}}>{d.time}</text>
                    </svg>
                     {current ? onOver : null}
                 </>
                break;
            case "2" :
                design =  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="26">
                    {!order.approved &&
                    <pattern id="diagonalHatchOpp" viewBox="0,0,8,8" width="16" height="16"
                             patternUnits="userSpaceOnUse">
                        <rect width="16" height="16" fill={order.color} style={{fill: order.color, opacity: 0.3}}/>
                        <line x1="16" y1="16" x2="0" y2="0" style={{stroke: order.color, strokeWidth: 0.5}}/>
                    </pattern>
                    }
                    <rect x="-10" y="0" width="90" height="26" rx="10" ry="10" style={colorStyle}/>
                    <rect x="190" y="0" width="10" height="26" style={colorStyle}/>
                    <rect x="190" y="0" width="10" height="26" style={colorStyle}/>
                    <text x="25" y="17" fill="white" style={{fontSize: '13px'}}>{d.time}</text>
                    {!isEnded && approvedRole &&
                    <Ellipsis x="55" y="5" onClick={() => props.editOrder(order, chamberName)}/>
                    }

                </svg>
                break;
            case "3" :
                design =  <svg xmlns="http://www.w3.org/2000/svg" width="200" height="26" onClick={()=>openPopUp(order,chamberName)}>
                    {!order.approved &&
                    _stripedBackground
                    }
                    {/*<rect x="280" y="0" width="10" height="26" style={!order.approved?stripes:colorStyle} />*/}
                    <rect x="0" y="0" width="180" height="26" style={colorStyle}/>
                </svg>
                break;
            case "4" :
                break;
            case "5":
            design =  <svg xmlns="http://www.w3.org/2000/svg" width="200" height="26" onClick={()=>openPopUp(order,chamberName)}>
                {!order.approved &&
                _stripedBackground
                }
                <rect x="85" y="0" width="90" height="26" rx="10" ry="10" style={colorStyle}/>
                <rect x="190" y="0" width="5" height="26" style={colorStyle}/>
                <text x="90" y="17" fill="white" style={{fontSize: '13px'}}>{d.time}</text>
                <g>
                    <rect x="-10" y="0" width="90" height="26" rx="10" ry="10" style={colorStyle}/>
                    <rect x="190" y="0" width="10" height="26" style={colorStyle}/>
                    <rect x="190" y="0" width="10" height="26" style={colorStyle}/>
                    <text x="25" y="17" fill="white" style={{fontSize: '13px'}}>{d.timeEnd}</text>
                    <Ellipsis x="55" y="5" onClick={() => props.editOrder(order,chamberName)}/>
                </g>
            </svg>
                break;
            default: {
                break;
            }
        }
        return design
    }

    return (
        <>
            {props.orders.map(order => {
                return <div key={'' + order.name} className={'grid grid-cols-9'}>
                    <div style={{backgroundColor:order.chamberColor}} className={" flex justify-center items-center w-[143px]"}>{order.name}</div>
                    {
                        getOrdersData(order.orders,order.name)}
                </div>
            })
            }
        </>
    )

}