import React, {useEffect, useState} from "react";
import Select, {components} from "react-select";
import {Error} from "../../forms/Error";
import {boolean, number, object} from "yup";
import ConfigurationApi from "../../../services/ApiServices/ConfigurationApi";
import {makeError} from "../../modals/ErrorModal";
import {RadioButtonAlpha} from "../../input/RadioButtonAlpha";
import {SlideAlpha} from "../../input/SlideAlpha";

export function MeasurementForm(props) {

    const [entry, setEntry] = useState({});
    const [error, setError] = useState({})
    const [errorsMessages, setErrorserrorsMessages] = useState({})
    const [disabled, setDisabled] = useState(true)
    const [stations, setStations] = useState([])
    const [getData, setGetData] = useState(0)
    const [seedType, setSeedType] = useState('Oxidized')
    const NoOptionsMessage = (props) => {
        return (
            <components.NoOptionsMessage {...props} />
        );
    };
    const measurementSchema = object().shape({
        vacuumLowerLimit: number().typeError('Please insert Vacuum min limit').required().default(15),
        vacuumUpperLimit: number().typeError('Please insert Vacuum max limit').required().default(100),
        activityValidationLowerCoated: number().typeError('Please insert min limit').required().default(0),
        activityValidationUpperCoated: number().typeError('Please insert max limit').required().min(0).default(0),
        activityValidationLowerOxidized: number().typeError('Please insert min limit').required().default(0),
        activityValidationUpperOxidized: number().typeError('Please insert max limit').required().min(0).default(0),
        allowSecondMeasurementOnlyCoated: boolean().typeError('').required().default(false),
        allowSecondMeasurementOnlyOxidized: boolean().typeError('').required().default(false),
        channelWidth: number().typeError('Please insert Channel Width').required(),
        desorptionSmallerCoated: number().typeError('Please insert desorption').required().default(0),
        desorptionSmallerOxidized: number().typeError('Please insert desorption').required().default(0),
        measurementTime: number().typeError('Please insert measurement Time').required().default(0),
        rnDesorptionGreaterCoated: number().typeError('Please insert RN desorption').required().default(0),
        rnDesorptionGreaterOxidized: number().typeError('Please insert RN desorption').required().default(0),
        detectorRadius: number().typeError('Please insert Detector radius').required().default(100),
        // seedType: string().typeError('Please insert seedType').required().default(""),

    });
    const form = [
        {name: "vacuum", label: "Vacuum Limit (H)", lower: "vacuumLowerLimit", upper: "vacuumUpperLimit"},
        {name: "measurementTime", label: "Measurement Time (SEC)"},
        {name: "measurementTime", label: "Measurement Time (SEC)"},
        {name: "detectorRadius", label: "Detector Radius (mm)"},
        {name: "channelWidth", label: "Channel Width"},
    ]
    let seedTypeForm = [
        {
            name: "activityValidation",
            label: "Activity Validation (%)",
            lower: "activityValidationLower",
            upper: "activityValidationUpper"
        },
        {

            name: "desorption",
            label: "Desorption",
            lower: "minRnDesorption",
            upper: "maxDesorption"

        },

     /*   {
            name: "rnDesorptionGreater",
            label: "Rn Des Greater than (%)  ",
        },*/
        {name: "allowSecondMeasurementOnly", label: "Allow Second Measurement Only"},

    ]
    // const firstMeasurement = [{
    //     name: "firstMeasurementDesorption",
    //     label: "Desorption Smaller than (%)"
    // },
    //     {
    //         name: "allowSecondMeasurementOnly", label: "Allow Second Measurement Only"
    //     }]
    // const secondMeasurement = [
    //     {
    //         name: "secondMeasurementDesorption",
    //         label: "Des - Smaller than (%)",
    //     },
    //     {
    //         name: "secondMeasurementRNDesorption",
    //         label: "Rn des greater than (%)  ",
    //     },
    //     {name: "allowSecondMeasurementOnly", label: "Allow Second Measurement Only"},
    // ]
    const seedTypeOptions = [{label: "Oxidized", value: "Oxidized"}, {label: "Coated", value: 'Coated'}]

    const boxShadow = {
        boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.06)"
    }
    useEffect(() => {
        if (props.role !== "SystemAdmin") {
            let siteId = props.currentUser?.profile.SiteID
            getStation(siteId).then()
        }


    }, [])

    const getStation = async (siteId) => {
        let _stations = await ConfigurationApi.getStations(siteId)
        if (_stations && _stations.data) {
            setStations(_stations.data.map(station => {
                return {value: station, label: station}
            }))
        }
    }

    const onChange = async (name, value) => {
        let formErrors = {...error}
        let messages = {...errorsMessages}
        let config = JSON.parse(JSON.stringify(entry))
        if (name === 'SiteID') {
            config[name] = value.value
            config = {SiteID: value.value,
                // allowSecondMeasurementOnly: false
            }
            await getStation(value.value)
        } else if (name === 'station') {
            let siteId = props.role === "SystemAdmin" ? entry['SiteID'] : props.currentUser?.profile.SiteID
            config[name] = value.value
            await getMeasurementConfiguration(value.value, siteId)
        }
      else if( name==='minRnDesorption' || name==='maxDesorption' || name==='activityValidationLower'|| name==='activityValidationUpper') {
            config[name+seedType] = value
        }
        else if(name==='allowSecondMeasurementOnly'){
            config[name+seedType] = value!==0
        }
        else {
            config[name] = value
        }

        if (name !== 'station')
            setEntry(config)

        await measurementSchema.validate(config, {abortEarly: false}).then(function (value) {
            formErrors[name] = false
            setDisabled(false)
            messages[name] = ''
            // setIsError(false)
        })
            .catch(function (err) {
                err.inner.forEach(error => {
                    if (error.path === name) {
                        formErrors[error.path] = true
                        messages[name] = error.errors
                        setDisabled(true)
                        // setIsError(true)
                    }
                })
                let errorIndex = err.inner.findIndex(x => x.path === name)
                if (errorIndex === -1) {
                    formErrors[name] = false
                    messages[name] = ''
                    // setIsError(false)
                }
            });
        setErrorserrorsMessages(messages)
        setError(formErrors)
    }


    const getMeasurementConfiguration = async (station, siteId) => {
        let data = await ConfigurationApi.getDataByStation(station, siteId)
        if (data && data.data) {
            data = data.data
            await measurementSchema.validate(data, {abortEarly: false}).then(function (value) {
                setDisabled(false)
            })
                .catch(function () {
                    setDisabled(true)
                })
            setEntry(data)
            setGetData(getData + 1)
        }
    }

    const saveMeasurement = async () => {
        let config = await ConfigurationApi.saveMeasurementConfiguration(entry)
        if (config && config.data) {
            await makeError({
                proceedLabel: 'Ok',
                options: {title: "Measurement settings"}
            }, ["Measurement settings saved"]);
        }
    }

    let isStation = props.role !== "SystemAdmin" || (props.role === "SystemAdmin" && entry['SiteID'])
    return (
        <div className={'mt-4 mb-8 h-[93%] overflow-x-hidden overflow-y-auto w-[100%]'}>
            <div className={'w-[100%]'}>
                {props.role === "SystemAdmin" &&
                <div className={props.classes.inputContainerClass}>
                    <div className={props.classes.labelClass}>Site</div>
                    <div className={'flex flex-col'}>
                        <Select
                            className={`inputClass w-[21.55rem] bg-white rounded-xl h-14 bg-transparent font-Roboto text-alphaTauText`}
                            classNamePrefix="select"
                            onChange={val => onChange('SiteID', val)}
                            components={{NoOptionsMessage}}
                            name="SiteID"
                            options={props.sites}
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    border: "1px solid #D7DDDF",
                                    backgroundColor: "#FFFFF",
                                    width: "100%",
                                    height: "3.5rem",
                                    color: "#444444",
                                    textTransform: "capitalize",
                                    boxShadow: boxShadow,
                                    fontSize: "16px",
                                    outline: "unset",
                                    paddingLeft: '0',
                                    borderRadius: "0.75rem"

                                })
                            }}
                        />
                    </div>

                </div>
                }
                {isStation &&
                <div className={props.classes.inputContainerClass}>
                    <div className={props.classes.labelClass}>Station</div>
                    <div className={'flex flex-col'}>
                        <Select
                            className={`inputClass w-[21.55rem] bg-white rounded-xl h-14 bg-transparent font-Roboto text-alphaTauText`}
                            classNamePrefix="select"
                            selected={entry['station'] ? {label: entry['station']} : ''}
                            value={entry['station'] ? {label: entry['station']} : ''}
                            onChange={val => onChange('station', val)}
                            components={{NoOptionsMessage}}
                            name="station"
                            options={stations}
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    border: "1px solid #D7DDDF",
                                    backgroundColor: "#FFFFF",
                                    width: "100%",
                                    height: "3.5rem",
                                    color: "#444444",
                                    textTransform: "capitalize",
                                    boxShadow: boxShadow,
                                    fontSize: "16px",
                                    outline: "unset",
                                    paddingLeft: '0',
                                    borderRadius: "0.75rem"

                                })
                            }}
                        />
                    </div>

                </div>
                }
                {entry['station'] &&
                <div>
                    {form.map(field => {
                        return <div key={field.name} className={props.classes.inputContainerClass}>
                            <div className={props.classes.labelClass}>{field.label}</div>
                            {
                                field.name === "vacuum" ?
                                            <div
                                                className={'flex justify-between h-[3.5rem] items-center w-[21.75rem] mt-2 '}>
                                                <div className={"w-[10.4rem] h-[5.5rem]"}>
                                                    <input
                                                        className={" w-[100%] bg-[#FFFFF] rounded-xl h-14 pl-4 font-Roboto text-alphaTauText border border-[#D7DDDF] "}
                                                        type={"text"}
                                                        onChange={e => onChange(e.target.name, e.target.value)}
                                                        style={boxShadow}
                                                        value={entry['vacuumLowerLimit'] ? entry['vacuumLowerLimit'] : ''}
                                                        name={'vacuumLowerLimit'}
                                                    />
                                                    <Error
                                                        message={errorsMessages['vacuumLowerLimit']}
                                                        isShown={error['vacuumLowerLimit']}/>
                                                </div>
                                                <div className={"w-[10.4rem] h-[5.5rem]"}>
                                                    <input
                                                        className={"w-[100%] bg-[#FFFFF] rounded-xl h-14 pl-4 font-Roboto text-alphaTauText border border-[#D7DDDF] "}
                                                        type={"text"}
                                                        // isSearchable={false}
                                                        onChange={e => onChange(e.target.name, e.target.value)}
                                                        style={boxShadow}
                                                        // isDisabled ={props.disabled}
                                                        // selected={props.selected}
                                                        // menuIsOpen={true}
                                                        value={entry['vacuumUpperLimit'] ? entry['vacuumUpperLimit'] : ''}
                                                        name={'vacuumUpperLimit'}
                                                    />
                                                    <Error
                                                        message={errorsMessages['vacuumUpperLimit']}
                                                        isShown={error['vacuumUpperLimit']}/>
                                                </div>
                                            </div>
                                              :
                                    <div className={' ml-20 mt-10 lg:ml-20 lg:mt-0 md:ml-2 md:mt-4'}>
                                        <input
                                            className={props.classes.inputClass}
                                            type={"text"}
                                            onChange={e => onChange(e.target.name, e.target.value)}
                                            style={boxShadow}
                                            value={entry[field.name] ? entry[field.name] : ''}
                                            name={field.name}
                                        />
                                        <Error
                                            message={errorsMessages[field.name]}
                                            isShown={error[field.name]}/>
                                    </div>
                            }
                        </div>
                    })
                    }
                    <div className={'w-[100%] '}>
                        <div  className={'w-[80%] border-t-2 border-[#D7DDDF]'}/>
                        <div className={props.classes.inputContainerClass}>
                            <div className={props.classes.labelClass}>Seed Type</div>
                            <div className={'flex justify-between h-[3.5rem] items-center w-[21.75rem] mt-2 '}>
                                {
                                    seedTypeOptions.map(option => {
                                        return <div key={option.value} className={"w-[10.4rem]"}>
                                            <RadioButtonAlpha
                                                inputClassName={'w-[1.25rem] h-[1.25rem] mr-[1.25rem] ml-[1.813rem]'}
                                                onChange={() => setSeedType(option.value)}
                                                checked={seedType === option.value}
                                                // checked={include.name==='basic'}
                                                value={seedType === option.value}
                                                name={'seedType'}
                                                label={option.label}
                                            />
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        {
                            seedTypeForm.map(seedField => {
                                let selectedLower
                                let selectedUpper
                                if(seedField.name === "activityValidation"){
                                    selectedLower = entry['activityValidationLower'+seedType] ? entry['activityValidationLower'+seedType] : ''
                                    selectedUpper = entry['activityValidationUpper'+seedType] ? entry['activityValidationUpper'+seedType] : ''
                                }else{
                                    selectedLower = entry['minRnDesorption'+seedType] ? entry['minRnDesorption'+seedType] : ''
                                    selectedUpper = entry['maxDesorption'+seedType] ? entry['maxDesorption'+seedType] : ''
                                }

                                return <div key={seedField.name} className={props.classes.inputContainerClass}>
                                    <div className={props.classes.labelClass}>{seedField.label}</div>
                                    {
                                        seedField.name === "activityValidation" || seedField.name ==="desorption" ?
                                            <div
                                                className={'flex justify-between h-[3.5rem] items-center w-[21.75rem] mt-2 '}>
                                                <div className={"w-[10.4rem] h-[5.5rem]"}>
                                                    <input
                                                        className={" w-[100%] bg-[#FFFFF] rounded-xl h-14 pl-4 font-Roboto text-alphaTauText border border-[#D7DDDF] "}
                                                        type={"text"}
                                                        onChange={e => onChange(e.target.name, e.target.value)}
                                                        style={boxShadow}
                                                        value={selectedLower}
                                                        name={seedField.lower}
                                                    />
                                                    <Error
                                                        message={errorsMessages[seedField.lower]}
                                                        isShown={error[seedField.lower]}/>
                                                </div>
                                                <div className={"w-[10.4rem] h-[5.5rem]"}>
                                                    <input
                                                        className={"w-[100%] bg-[#FFFFF] rounded-xl h-14 pl-4 font-Roboto text-alphaTauText border border-[#D7DDDF] "}
                                                        type={"text"}
                                                        onChange={e => onChange(e.target.name, e.target.value)}
                                                        style={boxShadow}
                                                        value={selectedUpper}
                                                        name={seedField.upper}
                                                    />
                                                    <Error
                                                        message={errorsMessages[seedField.upper]}
                                                        isShown={error[seedField.upper]}/>
                                                </div>
                                            </div> :
                                            seedField.name === "allowSecondMeasurementOnly" ?
                                                <div className={'w-[21.75rem] ml-20  flex justify-center'}>
                                                    <SlideAlpha
                                                        leftLabel={"No"}
                                                        width={'75%'}
                                                        name={seedField.name}
                                                        onChange={e => onChange('allowSecondMeasurementOnly', e.target.value)}
                                                        rightLabel={"Yes"}
                                                        value={entry[seedField.name+seedType] ? 1 : 0}
                                                        step={1}
                                                        min={0}
                                                        max={1}
                                                    />
                                                </div> :
                                                <div className={' ml-20 mt-10 lg:ml-20 lg:mt-2 md:ml-2 md:mt-4'}>
                                                    <input
                                                        className={props.classes.inputClass}
                                                        type={"text"}
                                                        onChange={e => onChange(e.target.name, e.target.value)}
                                                        style={boxShadow}
                                                        value={entry[seedField.name+seedType] ? entry[seedField.name+seedType] : ''}
                                                        name={seedField.name}
                                                    />
                                                    <Error
                                                        message={errorsMessages[seedField.name]}
                                                        isShown={error[seedField.name]}/>
                                                </div>

                                    }
                                </div>
                                    })
                                    }
                                    {/*            <div className={'flex flex-col'}>*/}
                                    {/*                <div className={props.classes.inputContainerClass}>*/}
                                    {/*                    <div className={props.classes.labelClass}>{'Activity Validation'}</div>*/}
                                    {/*                    <div className={'flex justify-between h-[3.5rem] items-center w-[21.75rem] mt-10 '}>*/}
                                    {/*                        <div className={"w-[10.4rem] h-[5.5rem]"}>*/}
                                    {/*                            <input*/}
                                    {/*                                className={" w-[100%] bg-[#FFFFF] rounded-xl h-14 pl-4 font-Roboto text-alphaTauText border border-[#D7DDDF] "}*/}
                                    {/*                                type={"text"}*/}
                                    {/*                                // isSearchable={false}*/}
                                    {/*                                onChange={e => onChange(e.target.name, e.target.value)}*/}
                                    {/*                                style={boxShadow}*/}
                                    {/*                                // isDisabled ={props.disabled}*/}
                                    {/*                                selected={entry['activityValidationLower'] ? entry['activityValidationLower'] : ''}*/}
                                    {/*                                // menuIsOpen={true}*/}
                                    {/*                                value={entry['activityValidationLower'] ? entry['activityValidationLower'] : ''}*/}
                                    {/*                                name={'activityValidationLower'}*/}
                                    {/*                            />*/}
                                    {/*                            <Error*/}
                                    {/*                                message={errorsMessages['activityValidationLower']}*/}
                                    {/*                                isShown={error['activityValidationLower']}/>*/}
                                    {/*                        </div>*/}
                                    {/*                        <div className={"w-[10.4rem] h-[5.5rem]"}>*/}
                                    {/*                            <input*/}
                                    {/*                                className={"w-[100%] bg-[#FFFFF] rounded-xl h-14 pl-4 font-Roboto text-alphaTauText border border-[#D7DDDF] "}*/}
                                    {/*                                type={"text"}*/}
                                    {/*                                // isSearchable={false}*/}
                                    {/*                                onChange={e => onChange(e.target.name, e.target.value)}*/}
                                    {/*                                style={boxShadow}*/}
                                    {/*                                // isDisabled ={props.disabled}*/}
                                    {/*                                // selected={props.selected}*/}
                                    {/*                                // menuIsOpen={true}*/}
                                    {/*                                value={entry['activityValidationUpper'] ? entry['activityValidationUpper'] : ''}*/}
                                    {/*                                name={'activityValidationUpper'}*/}
                                    {/*                            />*/}
                                    {/*                            <Error*/}
                                    {/*                                message={errorsMessages['activityValidationUpper']}*/}
                                    {/*                                isShown={error['activityValidationUpper']}/>*/}
                                    {/*                        </div>*/}
                                    {/*                    </div>*/}
                                    {/*                </div>*/}
                                    {/*                    <div  className={'flex flex-col'}>*/}
                                    {/*                        {seedType === 'Coated' &&*/}
                                    {/*                            <div className={''}>*/}
                                    {/*                            <div className={`${props.classes.labelClass} ml-4 font-bold`}>First Measurement:</div>*/}
                                    {/*                            {firstMeasurement.map(first => {*/}
                                    {/*                                console.log("first",first)*/}
                                    {/*                                return <div key={first.name} className={props.classes.inputContainerClass}>*/}
                                    {/*                                    <div className={props.classes.labelClass}>{first.label}</div>*/}
                                    {/*                                    <input*/}
                                    {/*                                        className={props.classes.inputClass}*/}
                                    {/*                                        type={"text"}*/}
                                    {/*                                        // isSearchable={false}*/}
                                    {/*                                        onChange={e => onChange(e.target.name, e.target.value)}*/}
                                    {/*                                        style={boxShadow}*/}
                                    {/*                                        // isDisabled ={props.disabled}*/}
                                    {/*                                        // selected={props.selected}*/}
                                    {/*                                        // menuIsOpen={true}*/}
                                    {/*                                        value={entry[first.name] ? entry[first.name] : ''}*/}
                                    {/*                                        components={{NoOptionsMessage}}*/}
                                    {/*                                        name={first.name}*/}
                                    {/*                                    />*/}
                                    {/*                                    <Error*/}
                                    {/*                                        message={errorsMessages[first.name]}*/}
                                    {/*                                        isShown={error[first.name]}/>*/}
                                    {/*                                </div>*/}
                                    {/*                                // </div>*/}
                                    {/*            */}
                                    {/*                            })*/}
                                    {/*                            }*/}
                                    {/*                            </div>*/}
                                    {/*                        }*/}
                                    {/*                        <div className={`${props.classes.labelClass} ml-4 font-bold`}>Second Measurement:</div>*/}
                                    {/*                        {secondMeasurement.map(second => {*/}
                                    {/*                            return <div key={second.name} className={props.classes.inputContainerClass}>*/}
                                    {/*                                <div className={props.classes.labelClass}>{second.label}</div>*/}
                                    {/*                                <input*/}
                                    {/*                                    className={props.classes.inputClass}*/}
                                    {/*                                    type={"text"}*/}
                                    {/*                                    // isSearchable={false}*/}
                                    {/*                                    onChange={e => onChange(e.target.name, e.target.value)}*/}
                                    {/*                                    style={boxShadow}*/}
                                    {/*                                    // isDisabled ={props.disabled}*/}
                                    {/*                                    // selected={props.selected}*/}
                                    {/*                                    value={entry[second.name] ? entry[second.name] : ''}*/}
                                    {/*                                    components={{NoOptionsMessage}}*/}
                                    {/*                                    name={second.name}*/}
                                    {/*                                />*/}
                                    {/*                                <Error*/}
                                    {/*                                    message={errorsMessages[second.name]}*/}
                                    {/*                                    isShown={error[second.name]}/>*/}
                                    {/*                            </div>*/}
                                    {/*                        })*/}
                                    {/*                        }*/}
                                    {/*                    </div>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}

                                    {/*    </div>*/}
                                    {/*}*/}
                                </div>
                                </div>
                            }


                {/*</div>*/}
                </div>
                                <div className={'w-[100%] flex justify-end my-8 mx-4'}>
                                    <button type="button"
                                            onClick={() => saveMeasurement()}
                                            disabled={disabled}
                                            className="mr-[11rem] mb-4 w-[10rem] text-white bg-alphaTauButton hover:bg-alphaTauButtonHover font-Roboto font-medium rounded-rounded10 text-textSize px-5 py-1 text-center">
                                        Save
                                    </button>
                                </div>
                            </div>


                            )
                            }

