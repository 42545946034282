import set from 'set-value'
import moment from "moment";

class utils {
  get(obj, path) {
    // Get the path as an array
    path = this.stringToPath(path)

    if (!path) return null

    // Cache the current object
    let current = obj
    // For each item in the path, dig into the object
    for (let i = 0; i < path.length; i++) {
      // If the item isn't found, return the default (or null)
      if (current === null || this.isUndefined(current) || this.isUndefined(current[path[i]])) return ''
      // Otherwise, update the current  value
      current = current[path[i]]
    }
    return current
  }

  set(object, path, value) {
    set(object, path, value)
  }

  removeEmptyOrNull(source) {
    const obj = Object.assign({}, source)
    Object.keys(obj).forEach(
        (k) =>
            (obj[k] && typeof obj[k] === 'object' && this.removeEmptyOrNull(obj[k])) ||
            ((obj[k] === undefined ||
                    obj[k] === null ||
                    obj[k].length === 0 ||
                    (obj[k] instanceof String && obj[k].isEmpty()) ||
                    obj[k] === '' ||
                    obj[k] === '') &&
                delete obj[k])
    )

    if (Array.isArray(obj) && obj.length < 1) {
      return null
    }

    return obj
  }

  /**
   * Checks if value is empty. Deep-checks arrays and objects
   * Note: isEmpty([]) == true, isEmpty({}) == true, isEmpty([{0:false},"",0]) == true, isEmpty({0:1}) == false
   * @param value
   * @returns {boolean}
   */
  isEmpty(value) {
    let is = this.isEmpty

    let isEmptyObject = function (a) {
      if (typeof a.length === 'undefined') {
        // it's an Object, not an Array
        let hasNonempty = Object.keys(a).some(function nonEmpty(element) {
          return !is(a[element])
        })
        return hasNonempty ? false : isEmptyObject(Object.keys(a))
      }

      return !a.some(function nonEmpty(element) {
        // check if array is really not empty as JS thinks
        return !is(element) // at least one element should be non-empty
      })
    }

    let isEmptyString = function (s) {
      if (typeof s === 'string') return s.length === 0
    }

    return (
        // value === false
        // ||
        typeof value === 'undefined' ||
        value == null ||
        (typeof value === 'object' && isEmptyObject(value)) ||
        (typeof value === 'string' && isEmptyString(value))
    )
  }

  isObject(obj) {
    return obj && typeof obj === 'object'
  }

  isUndefined(obj) {
    return typeof obj === 'undefined'
  }

  /**
   * If the path is a string, convert it to an array
   * @param  {String|Array} path The path
   * @return {Array}             The path array
   */
  stringToPath(path) {
    // If the path isn't a string, return it
    if (typeof path !== 'string') return path

    // Create new array
    let output = []

    // Split to an array with dot notation
    path.split('.').forEach(function (item) {
      // Split to an array with bracket notation
      item.split(/\[([^}]+)\]/g).forEach(function (key) {
        // Push to the new array
        if (key.length > 0) {
          output.push(key)
        }
      })
    })

    return output
  }

  toNumeric(val) {
    return val.replace(/\D/g, '').replace('e', '')
  }

  ObjectEmptiesToNulls(source) {
    const obj = Object.assign({}, source)
    Object.keys(obj).forEach((k) => {
      if (obj[k] === null) return obj[k]

      if (
          (obj[k] && typeof obj[k] === 'object' && this.ObjectEmptiesToNulls(obj[k])) ||
          obj[k] === undefined ||
          obj[k].length === 0 ||
          (obj[k] instanceof String && obj[k].isEmpty()) ||
          obj[k] === '' ||
          obj[k] === ''
      )
        obj[k] = null
    })
    return obj
  }

  // getDateDashFormatWithYear = function (date) {
  //     if (!date) return "NoDate"
  //     let today = date;
  //     let dd = String(today.getDate()).padStart(2, '0');
  //     let mm = today.getMonth()+1
  //     if (mm.toString().length === 1) mm = '0'+mm.toString()
  //     let yyyy = today.getUTCFullYear();
  //     return yyyy + '-' + mm + '-' + dd;
  // };

  time_convert(num) {
    if (num) {
      let sign = num / 60 < 0 ? '-' : ''
      let min = Math.floor(Math.abs(num / 60))
      let sec = Math.floor((Math.abs(num / 60) * 60) % 60)
      return sign + (min < 10 ? '0' : '') + min + ':' + (sec < 10 ? '0' : '') + sec
    } else {
      return ''
    }
  }

  yearsDiff(d1, d2) {
    let date1 = new Date(d1)
    let date2 = new Date(d2)
    return date2.getFullYear() - date1.getFullYear()
  }


  getOrderTypeLabel(key) {
    let type
    switch (key) {
      case '000':
        type = 'Pre-Clinical'
        break
      case '001':
        type = 'Inert'
        break
      case '002':
        type = 'Tentative'
        break
      case '003':
        type = 'Hot Shipment'
        break
      case '004':
        type = 'Study'
        break
      case '005':
        type = 'Commercial'
        break
      case '006':
        type = 'Validation'
        break
      case '007':
        type = 'Comp'
        break
      case '008':
        type = 'Maintenance'
        break
      default:
        type = ''
    }
    return type
  }

  parseJwt(token) {
    var base64Url = token?.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )

    return JSON.parse(jsonPayload)
  }

  getCurrentPage(key) {
    let page
    switch (key) {
      case 'page_1':
        page = 'organization'
        break
      case 'page_2':
        page = 'admin'
        break
      case 'page_3':
        page = 'contactPerson'
        break
      case 'page_4':
        page = 'OrganizationLogo'
        break
      default:
        page = ''
    }
    return page
  }

  mailValidation(email) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
  }

  passwordValidation(password) {
    let lengthPattern = /^[a-zA-Z\d]{8,20}$/
    let specialCharacterPattern =
        /^(?=.*[!@#$%^&*()_\-+={}[\]\\|:;"'<>,.?\/])[^!@#$%^&*()_\-+={}[\]\\|:;"'<>,.?\/]{0,}$[a-zA-Z0-9]*[!@#$%^&*()_\-+={}[\]\\|:;"'<>,.?\/][a-zA-Z0-9]*$/
    let oneNumberPattern = /^(?=.*\d)[a-zA-Z\d]*$/
    let upperCasePattern = /^(?=.*[A-Z])[a-zA-Z\d]*$/
    let lowerCasePattern = /^(?=.*[a-z])[a-zA-Z\d]*$/

    if (!lengthPattern.test(password)) {
      return '8-20 characters'
    }
    if (!specialCharacterPattern.test(password)) {
      return 'one special character'
    }
    if (!oneNumberPattern.test(password)) {
      return 'one number'
    }
    if (!upperCasePattern.test(password)) {
      return 'one uppercase character'
    }
    if (!lowerCasePattern.test(password)) {
      return 'one lowercase character'
    }

    return true
  }

  /**
   * return zero before number if the number is smaller then 10
   * @param num
   * @returns {string|*}
   */
  addZeroToNumber(num) {
    if (num < 10) {
      return '0' + num
    } else {
      return num
    }
  }

  /**
   * return an arranged order data
   * @param data
   */
  arrangePlan(data) {
    let dataArr = []
    let responseData = data ? data.SubPlans : []
    responseData?.forEach(item => {
      let combinedStartTimeString
      let combinedEndTimeString
      if (item.startTime) {
        combinedStartTimeString = item.startDate + "T" + item.startTime;
      } else {
        combinedStartTimeString = item.startDate
      }
      if (item.endTime) {
        combinedEndTimeString = item.endDate + "T" + item.endTime;
      } else {
        combinedEndTimeString = item.endDate
      }
      let combinedStartDate = new Date(combinedStartTimeString);
      let combinedEndDate = new Date(combinedEndTimeString);
      // console.log("item.startTime",item.startDate)
      // console.log("combinedEndDate",combinedEndDate)
      item['startTimeDate'] = moment(combinedStartDate.toLocaleString()).format('DD MMM YYYY, h:mm A')
      item['endTimeDate'] = moment(combinedEndDate.toLocaleString()).format('DD MMM YYYY, h:mm A')
      dataArr.push(item)
    })
    return dataArr
  }
}

export default new utils()
