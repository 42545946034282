import React, {useEffect, useState} from "react";
import Constants from "../../utils/constants";


export function TimeInputAlpha(props) {
    const [minutes, setMinutes] = useState([]);
    useEffect( () => {
        let _minutes = []
        for (let i = 0; i < 60; i++) {
            let minute = i < 10 ? '0' + i : i + ''
            _minutes.push(minute)
        }

        setMinutes(_minutes)
    },[])

    return (
        <div className={props.containerClassName}>
            {/*<InputLabel className={props.labelClassName}>{props.label}</InputLabel>*/}

                <div className={'bg-[#F2F5FA] border border-[#D7DDDF] w-[4.125rem] h-[3.5rem] rounded-[0.25rem] flex justify-center items-center'}>
                    <select className={'w-[3rem] lg:w-[4rem] font-Roboto text-[#444444] text-[1.125rem] h-full bg-transparent focus:outline-none'} disabled={props.disabled} name={props.hourName} onChange={props.onChangeHours}>
                        {Constants.hours.map(hour => {
                            // let selectedHour =type==='Start'?block['startHour']:block['endHour']
                            return <option key={hour} value={hour} selected={props.selectedHour===hour}>{hour}</option>
                        })
                        }
                    </select>
                </div>
                <div className={'text-[1.125rem] text-[#444444] font-Roboto mx-[0.563rem] flex items-center '} >:</div>
                <div className={'bg-[#F2F5FA] border border-[#D7DDDF] w-[4.125rem] h-[3.5rem] rounded-[0.25rem] flex justify-center items-center'}>
                    <select className={'w-[3rem] lg:w-[4rem] font-Roboto text-[#444444] text-[1.125rem] h-full bg-transparent focus:outline-none'} disabled={props.disabled} name={props.minuteName} onChange={props.onChangeMinutes}>
                        {minutes.map(minute => {
                            return <option key={minute} value={minute} selected={props.selsctedMinute===minute} name={'minute'}>{minute}</option>
                        })
                        }
                    </select>
                </div>

                <div className={'text-[1.125rem] text-[#444444] font-Roboto mx-[0.563rem] flex items-center '}>:</div>
                <div className={'bg-[#F2F5FA] border border-[#D7DDDF] w-[4.125rem] h-[3.5rem] rounded-[0.25rem] flex justify-center items-center'}>
                    <select className={'w-[3rem] lg:w-[4rem] font-Roboto text-[#444444] text-[1.125rem] h-full bg-transparent focus:outline-none'} disabled={props.disabled} name={'hourType'} onChange={props.onChangeHourType} >
                        <option value={'AM'} selected={props.selectedHourType==='AM'}>AM</option>
                        <option value={'PM'} selected={props.selectedHourType==='PM'}>PM</option>
                    </select>
                </div>
        </div>


    )
}
