import React, {useEffect, useState} from "react";
import Select, {components} from "react-select";
import {InputCheckboxAlpha} from "../../input/InputCheckboxAlpha";
import ConfigurationApi from "../../../services/ApiServices/ConfigurationApi";
import {makeError} from "../../modals/ErrorModal";
import {object, number, string} from "yup";
import {RadioButtonAlpha} from "../../input/RadioButtonAlpha";
import {TimeInputAlpha} from "../../input/TimeInputAlpha";
import {Error} from "../../forms/Error";
import {OverlayTrigger, Tooltip} from "react-bootstrap";


export function CalculationForm(props) {
    const [entry, setEntry] = useState({
        SaturdayIncluded: false,
        SundayIncluded: false,
        JewishHolidaysIncluded: false,
        ChristianHolidaysIncluded: false,
        // basicWorkingHoursStartTime:'08:00 AM',
        // basicWorkingHoursEndTime:'05:00 PM',
        // extendedWorkingHoursStartTime:'06:00 AM',
        // extendedWorkingHoursEndTime:'20:00 PM'
    });
    const [startTime, setStartTime] = useState({hour: '01', minute: '00', hourType: 'AM'});
    const [endTime, setEndTime] = useState({hour: '01', minute: '00', hourType: 'AM'});
    const [error, setError] = useState({})
    const [errorsMessages, setErrorserrorsMessages] = useState({})
    const [disabled, setDisabled] = useState(false)
    const [getData, setGetData] = useState(0)
    const NoOptionsMessage = (props) => {
        return (
            <components.NoOptionsMessage {...props} />
        );
    };
    const calculatorSchema = object().shape({
        readyTime: number().typeError('Please insert Ready time').required().default(15),
        maxTimeForCharge: number().typeError('Please insert Max time for each charge').required().default(100),
        chargeDiff: number().typeError('Please insert Difference').required().default(0),
        allowedDeviation: number().typeError('Please insert deviation').required().default(0),
        extraSeeds: number().typeError('Please insert Quantity of extra seeds').required(),
        bakeLoss: number().typeError('Please insert Shedding loss - bake').required(),
        coatedLoss: number().typeError('Please insert Shedding loss - coated').required(),
        workingHoursType: string().typeError('Please select working hours').required()
    });
    const form = [
        {name: "readyTime", label: "Ready time (H)"},
        {name: "maxTimeForCharge", label: "Max time for each charge (Min)"},
        {name: "chargeDiff", label: "Difference between two charges in the same chamber (H)"},
        {name: "extraSeeds", label: "Quantity of extra seeds (%)"},
        {name: "workingHours", label: "Working hours (H)"},
        {name: "workingHoursStartTime", label: "Start time"},
        {name: "workingHoursEndTime", label: "End time"},
        {name: "allowedDeviation", label: "Allowed deviation from activity level (%)"},
        {name: "holidays", label: "Holidays"},
        {name: "weekends", label: "Weekends"},
        {name: "bakeLoss", label: "Shedding loss - bake (%)"},
        {name: "coatedLoss", label: "Shedding loss - coated (%)"},
        // {name: "holidaySelection", label: "Holiday selection"},
        // {name:"Default",label:"Default"},
    ]
    const includeDays = [{
        name: "JewishHolidaysIncluded",
        label: "Jewish holidays"
    }, {name: "ChristianHolidaysIncluded", label: "Christian holidays"}]
    const includeWeekends = [{name: "SaturdayIncluded", label: "Saturdays"}, {
        name: "SundayIncluded", label: "Sundays"
    }]
    const workingHours = [{name: "basic", label: "Basic"}, {name: "extended", label: "Extended"}, {
        name: "24h",
        label: "24 Hours"
    }]

    const boxShadow = {
        boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.06)"
    }
    useEffect(() => {
        // basicWorkingHoursStartTime:'08:00 AM',
        // basicWorkingHoursEndTime:'05:00 PM',
        // extendedWorkingHoursStartTime:'06:00 AM',
        // extendedWorkingHoursEndTime:'20:00 PM'
        entry['workingHoursType']='basic'
        let start = entry['basicWorkingHoursStartTime']?.split(/:| /)
        let end = entry['basicWorkingHoursEndTime']?.split(/:| /)
        if (start && start.length > 0) {
            setStartTime({hour: start[0], minute: start[1], hourType: start[2]})
        }
        if (end && end.length > 0) {
            setEndTime({hour: end[0], minute: end[1], hourType: end[2]})
        }
    }, [getData])

    const onChange = async (name, value) => {
        let formErrors = {...error}
        let messages = {...errorsMessages}
        let config = JSON.parse(JSON.stringify(entry))
        if (name === 'SiteID') {
            config[name] = value.value
            getCalculatorConfiguration(value.value).then()
        } else if (name === 'workingHoursType'){
            config[name] = value
            if(value==='basic'){
                let start = entry['basicWorkingHoursStartTime']?.split(/:| /)
                let end = entry['basicWorkingHoursEndTime']?.split(/:| /)
                if (start && start.length > 0) {
                    setStartTime({hour: start[0], minute: start[1], hourType: start[2]})
                }
                if (end && end.length > 0) {
                    setEndTime({hour: end[0], minute: end[1], hourType: end[2]})
                }

            }else if(value==='extended'){
                let start = entry['extendedWorkingHoursStartTime']?.split(/:| /)
                let end = entry['extendedWorkingHoursEndTime']?.split(/:| /)
                if (start && start.length > 0) {
                    setStartTime({hour: start[0], minute: start[1], hourType: start[2]})
                }
                if (end && end.length > 0) {
                    setEndTime({hour: end[0], minute: end[1], hourType: end[2]})
                }
            }
        }
        else {
            config[name] = value
        }
        setEntry(config)
        await calculatorSchema.validate(config, {abortEarly: false}).then(function (value) {
            formErrors[name] = false
            setDisabled(false)
            messages[name] = ''
            // setIsError(false)
        })
            .catch(function (err) {
                err.inner.forEach(error => {
                    if (error.path === name) {
                        formErrors[error.path] = true
                        messages[name] = error.errors
                        setDisabled(true)
                        // setIsError(true)
                    }
                })
                let errorIndex = err.inner.findIndex(x => x.path === name)
                if (errorIndex === -1) {
                    formErrors[name] = false
                    messages[name] = ''
                    // setIsError(false)
                }
            });
        setErrorserrorsMessages(messages)
        setError(formErrors)
    }
    // useEffect(() =>{
    //     getCalculatorConfiguration().then()
    // },[])
    const getCalculatorConfiguration = async (siteId) => {
        let data = await ConfigurationApi.getCalculatorConfiguration(siteId)
        if (data && data.data) {
            setEntry(data.data)
            setGetData(getData + 1)
        }
    }

    //props.role !== "SystemAdmin" || (props.role === "SystemAdmin" && entry['SiteID'])
    // const formStyle = {
    //     marginTop: props.role !== "SystemAdmin" || (props.role === "SystemAdmin" && entry['SiteID']) ? '9rem' : '1rem'
    // }
    const saveCalculation = async () => {

        entry['workingHoursStartTime'] = startTime['hour'] + ":" + startTime['minute'] + " " + startTime['hourType']
        entry['workingHoursEndTime'] = endTime['hour'] + ":" + endTime['minute'] + " " + endTime['hourType']

        let config = await ConfigurationApi.saveCalculatorConfiguration(entry)
        if (config && config.data) {
            await makeError({
                proceedLabel: 'Ok',
                options: {title: "Calculator settings"}
            }, ["Calculator settings saved"]);
        }
    }
    const onChangeWorkingHours = (e) => {
        if (e.type === "workingHoursStartTime") {
            let _startTime = JSON.parse(JSON.stringify(startTime))
            _startTime[e.name] = e.value
            setStartTime(_startTime)
            let formattedTime = _startTime.hour+":"+_startTime.minute+" "+_startTime.hourType
            if(entry["workingHoursType"]==="basic"){
                entry["basicWorkingHoursStartTime"] = formattedTime
            }else if(entry["workingHoursType"]==="extended"){
                entry["extendedWorkingHoursStartTime"] = formattedTime

            }
        } else {

            let _endTime = JSON.parse(JSON.stringify(endTime))
            _endTime[e.name] = e.value
            setEndTime(_endTime)
            let formattedTime = _endTime.hour+":"+_endTime.minute+" "+_endTime.hourType

            if(entry["workingHoursType"]==="basic"){
                entry["basicWorkingHoursEndTime"] = formattedTime
            }else if(entry["workingHoursType"]==="extended"){
                entry["extendedWorkingHoursEndTime"] = formattedTime
            }
        }
    }

    return (
        <div className={'mt-4 mb-8 h-[93%] overflow-x-hidden overflow-y-auto w-[100%]'}>
            {/*<div className={'w-[100%]'}>*/}
            {props.role === "SystemAdmin" &&
            <div className={props.classes.inputContainerClass}>
                <div className={props.classes.labelClass}>Site</div>
                <div className={'flex flex-col'}>
                    <Select
                        className={`inputClass w-[21.55rem] bg-white rounded-xl h-14 bg-transparent font-Roboto text-alphaTauText`}
                        classNamePrefix="select"
                        isSearchable={false}
                        onChange={val => onChange('SiteID', val)}
                        components={{NoOptionsMessage}}
                        name="SiteID"
                        options={props.sites}
                        styles={{
                            control: (base) => ({
                                ...base,
                                border: "1px solid #D7DDDF",
                                backgroundColor: "#FFFFF",
                                width: "100%",
                                height: "3.5rem",
                                color: "#444444",
                                textTransform: "capitalize",
                                boxShadow: boxShadow,
                                fontSize: "16px",
                                outline: "unset",
                                paddingLeft: '0',
                                borderRadius: "0.75rem"

                            })
                        }}
                    />
                </div>

            </div>
            }
            {entry['SiteID'] &&
            form.map(field => {
                let includes = field.name === "holidays" ? includeDays : includeWeekends
                return entry['workingHoursType'] === '24h' && (field.name === 'workingHoursStartTime' || field.name === 'workingHoursEndTime') ? null :

                    <div key={field.name} className={props.classes.inputContainerClass}>
                        {field.name === "readyTime"?
                            <OverlayTrigger
                                placement={"bottom-end"}
                                delay={{show: 250, hide: 250}}
                                trigger={["hover", "focus"]}
                                rootClose
                                // show={true}
                                overlay={<Tooltip id="" className={"tableToolTip right-[-1rem]"}>
                                        <div className={""}>X hours before delivery time</div>
                                </Tooltip>}>
                                <div className={props.classes.labelClass}>{field.label}</div>
                            </OverlayTrigger>
                        :
                            <div className={props.classes.labelClass}>{field.label}</div>}
                    {field.name === 'workingHours' ?
                        <div className={'flex justify-between  h-[3.5rem] items-center'}>
                        <div className={'flex  '}>
                    {
                        workingHours.map(include => {
                        return <RadioButtonAlpha
                            key={include.name}
                        inputClassName={'w-[1.25rem] h-[1.25rem] mr-[1.25rem]  ml-[1.813rem]'}
                        onChange={() => onChange('workingHoursType', include.name)}
                        checked={entry['workingHoursType'] === include.name}
                        // checked={include.name==='basic'}
                        value={include.name}
                        name={'workingHoursType'}
                        label={include.label}
                        />
                    })
                    }
                        </div>

                        </div>
                        :
                        (field.name === "workingHoursStartTime" || field.name === "workingHoursEndTime")  ?
                        entry['workingHoursType'] ==='24h'?
                        null:
                        <TimeInputAlpha
                        containerClassName={'flex'}
                        selectedHour={field.name === 'workingHoursStartTime' ? startTime['hour'] : endTime['hour']}
                        selsctedMinute={field.name === 'workingHoursStartTime' ? startTime['minute'] : endTime['minute']}
                        hourName={'hour'}
                        minuteName={'minute'}
                        disabled={entry['workingHoursType'] === '24h'}
                        onChangeHours={e => onChangeWorkingHours({
                        type: field.name,
                        name: 'hour',
                        value: e.target.value
                    })}
                        onChangeMinutes={e => onChangeWorkingHours({
                        type: field.name,
                        name: 'minute',
                        value: e.target.value
                    })}
                        selectedHourType={field.name === 'workingHoursStartTime' ? startTime['hourType'] : endTime['hourType']}
                        onChangeHourType={(e) => onChangeWorkingHours({
                        type: field.name,
                        name: 'hourType',
                        value: e.target.value
                    })}
                        /> :
                        field.name === "holidays" || field.name === "weekends" ?


                        <div className={'flex ml-20 mt-10  lg:ml-20 lg:mt-10 md:ml-0 md:mt-4'}>
                    {
                        includes.map(include => {
                        return <div key={include.name} className={'w-[15rem] flex  '}><InputCheckboxAlpha
                        inputClassName={'w-[1.25rem] h-[1.25rem] mr-[1rem] md:ml-4 ml-[4.5rem] lg:ml-[4.5rem]'}
                        onChange={e => onChange(e.target.name, e.target.checked)}
                        name={include.name}
                        checked={entry[include.name]}
                        />
                        <div>{include.label}</div>
                        </div>
                    })
                    }
                        </div> :
                        <div className={' ml-20 mt-10 lg:ml-20 lg:mt-0 md:ml-2 md:mt-4'}>
                        <input
                        className={props.classes.inputClass}
                        type={"text"}
                        onChange={e => onChange(e.target.name, e.target.value)}
                        style={boxShadow}
                        value={entry[field.name] ? entry[field.name] : ''}
                        name={field.name}
                        />
                        <Error
                        message={errorsMessages[field.name]}
                        isShown={error[field.name]}/>
                        </div>
                    }
                        </div>
                    })
                    }

                    <div className={'w-[100%] flex justify-end my-8 mx-4'}>
                        <button type="button"
                                onClick={() => saveCalculation()}
                                disabled={disabled}
                                className="mr-[11rem] mb-4 w-[10rem] text-white bg-alphaTauButton hover:bg-alphaTauButtonHover font-Roboto font-medium rounded-rounded10 text-textSize px-5 py-1 text-center">
                            Save
                        </button>
                    </div>
                    {/*</div>*/}
                </div>

            )
            }
