import React, { useEffect, useState} from "react";
import Constants from "../../utils/constants";
import "react-datepicker/dist/react-datepicker.css";
import {SelectAlphaIcon} from "../input/SelectAlphaIcon";
import {TimeInputAlpha} from "../input/TimeInputAlpha";
import ChambersApi from "../../services/ApiServices/ChambersApi";
import moment from "moment";


const AsymptoticActivityCalculation = ({onCancel,saveActivityLevel, startDate,chamber, endDate}) => {

    const [asymptoticActivity, setAsymptoticActivity] = useState({});
    const [runAgain, setRunAgain] = useState({});
    const [selected, setSelected] = useState(null)
    const [calculatedDate, setCalculatedDate] = useState(null)
    const activity = ['Calculated','Expected']

    useEffect( () => {
        if(chamber?.lastScriptResult){
            let lastScript = chamber.lastScriptResult
            setAsymptoticActivity({
                Calculated:lastScript.Calculated,
                Expected:lastScript.Expected,
                deviation:lastScript.deviation
            })
        }
    },[])

    const onChangeRunAgain = (e) => {
        setSelected('run')
        let _run = {...runAgain}
        _run[e.name] = e.value
        setRunAgain(_run)
    }
    const selectActivity = (act) => {
        setRunAgain({})
        setSelected(act)
    }
    const saveCalculation = async() => {
        if(selected==='run'){
            let _runAgain = {...runAgain}
            _runAgain['hour'] = _runAgain['hour']?_runAgain['hour']:'01'
            _runAgain['minute'] = _runAgain['minute']?_runAgain['minute']:'00'
            _runAgain['hourType'] = _runAgain['hourType']?_runAgain['hourType']:'AM'
            let runDate = _runAgain['date']
            let hours = _runAgain['hourType']==='PM'?Number(_runAgain['hour'])+12:Number(_runAgain['hour'])
            let minutes = Number(_runAgain['minute'])
            runDate.setHours(hours)
            runDate.setMinutes(minutes)
            let saveRunScript =await ChambersApi.saveTemporaryScriptRun({
                scriptStartDate:runDate.toISOString(),
                siteID:chamber.SiteID,
                chamberInternalID:chamber.chamberInternalID
            })
            if(saveRunScript){
                onCancel()
            }
        }else{
            let _chamber = {...chamber}
            _chamber['ActivityLevel']=asymptoticActivity[selected]
            // _chamber['ActivityDate']=moment(_chamber['ActivityDate']).toDate()
            _chamber['ActivityDate']=moment(_chamber['ActivityDate'], "DD/MM/YYYY").toDate();
            _chamber['needToUpdateActivity']=false
            delete _chamber['lastCalcTime']
            let submit = await ChambersApi.editChamber(_chamber,_chamber.chamberInternalID)
            if(submit){
                saveActivityLevel()
            }
        }
    }

    return (
        <div id="modalEl" tabIndex="-1" aria-hidden="true"
             className="fixed top-0 left-0 right-0 font-Roboto  w-auto z-50  py-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full bg-stone-900/60 flex items-center justify-center ">
            <div className="h-auto md:h-auto self-center w-[46.5rem]">
                <div className="relative bg-white pl-[1.875rem] w-auto rounded-lg shadow border border-indigo-600 dark:bg-gray-700">
                    <div
                        className={"rounded-full bg-[#F6F7F8] w-8 h-8 p-1 text-center float-right cursor-pointer top-[1rem] right-[2%] relative text-black font-semibold"}
                        onClick={()=>onCancel()}>
                        &#10005;
                    </div>
                    <div className="flex-col items-center  w-full px-auto justify-center justify-items-center flex">
                        <div
                            className="flex-col items-center rounded-t w-full px-auto justify-center justify-items-center mt-5">
                            <div
                                className="text-[1.688rem] w-full font-semibold font-Roboto text-[#0D3B53] mt-[0.938rem] text-center mb-[0.5rem]">
                                Asymptotic activity calculation
                            </div>

                        </div>
                        <div className="flex-col items-center rounded-t w-full px-auto justify-center justify-items-center text-[1.125rem] mb-[2rem]">
                            On the
                            <span className={'font-semibold mx-2'}>{chamber?.lastCalcTime}</span>
                            the asymptotic activity calculated.
                        </div>
                        <div className="flex-col items-center rounded-t w-full px-auto justify-center justify-items-center text-[1.313rem] font-bold text-[#444444] pb-[0.775rem]">
                            Select the required activity:
                        </div>
                        <div className={'flex w-full'}>
                            {activity.map(act=>{
                                return <div key={act} className={`w-[15.125rem] h-[6.929rem] bg-[#F2F5FA] rounded-[0.625rem] mr-[3.125rem] flex flex-col items-center cursor-pointer ${selected===act&&'border border-alphaTauButton'}`}
                                onClick={()=>selectActivity(act)}
                                >
                                        <div className={'text-[1.125rem] text-[#444444] mt-[1.25rem] ml-[1.25rem] mb-[0.25rem] w-full'}>{act}:</div>
                                        <div className={'text-alphaTauButton font-Roboto font-bold text-[1.688rem]'}>{asymptoticActivity[act]} kBq</div>
                                </div>
                            })
                            }
                            <div className={' h-[6.929rem]  flex flex-col items-center  ml-[0.25rem]'}>
                                <div className={'text-[1.125rem] text-[#444444] mt-[1.25rem] mb-[0.25rem] w-full'}>Deviation:</div>
                                <div className={'text-[#FF3434] font-bold text-[1.688rem]'}>{asymptoticActivity['deviation']}</div>
                            </div>
                        </div>
                    </div>
                    <div className={'w-[33.375rem] flex items-center h-[1.313rem] justify-between my-[1.296rem]'}>
                        <hr className={'w-[15.063rem] text-[#D7DDDF] h-px'}/>
                            <div className={'w-[2.688rem] text-[1.125rem] text-[#747879] bg-[#FFFFFF] flex justify-center'}>or</div>
                        <hr className={'w-[15.063rem] text-[#D7DDDF] h-px'}/>
                    </div>
                    <div className={' h-[6.929rem] mr-[2rem]'}>
                        <div className="flex-col items-center rounded-t w-full px-auto justify-center justify-items-center text-[1.313rem] font-bold text-[#444444] pb-[0.775rem]">
                            Run again:
                        </div>
                        <div className={'flex items-center h-[3.5rem] justify-between'}>
                                <SelectAlphaIcon
                                    class={' w-[21.75rem]'}
                                    mb={'mb-0'}
                                    inputWidth={'21.75rem'}
                                    backgroundColor={'#F2F5FA'}
                                    svgName={'calendar'}
                                    data={null}
                                    isRange={false}
                                    type={'date'}
                                    // value={runAgain['date']}
                                    minDate={new Date()}
                                    selected={runAgain['date']}
                                    name={'day'}
                                    label={'Select a day'}
                                    onChangeDate={e=>onChangeRunAgain({name:'date',value:e})}
                                    startDate={startDate}
                                    endDate={endDate}
                                    hasLine={true}
                                    inputClassName={'Select-Icon'}
                                />
                        <TimeInputAlpha
                            containerClassName={'flex'}
                            selectedHour={runAgain['hour']}
                            selsctedMinute={runAgain['minute']}
                            hourName={'hour'}
                            minuteName={'minute'}
                            onChangeHours={e=>onChangeRunAgain({name:'hour',value:e.target.value})}
                            onChangeMinutes={e=>onChangeRunAgain({name:'minute',value:e.target.value})}
                            selectedHourType={runAgain['hourType']}
                            onChangeHourType={(e)=>onChangeRunAgain({name: 'hourType', value: e.target.value})}
                        />

                    </div>
                    </div>
                    <div className=" py-6 rounded-b justify-end mx-auto mt-5 flex mr-[1.5rem] mb-[0.938rem]">
                        <button type="button"
                            onClick={() => saveCalculation()}
                            // disabled={disabled}
                                className={`${Constants.buttonClass} font-Roboto w-[21.75rem] hover:bg-alphaTauButtonHover text-white bg-alphaTauButton focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-[1.25rem] px-5 py-3 mb-4 text-center disabled:opacity-25`}>
                            Save
                        </button>

                    </div>
                </div>

            </div>

        </div>
    )
        ;

}

export default AsymptoticActivityCalculation;