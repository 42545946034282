import React from "react";
import {InputLabel} from "@mui/material";


export function LabelInputAlpha(props) {

    return (
        <div className={props.containerClassName}>
            <InputLabel className={props.labelClassName}>{props.label}</InputLabel>
            <div className={props.labelInputClassName}>{props.value}</div>
        </div>
    )
}
