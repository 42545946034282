import React from "react"
import SVGraphics from "../../assets/SVGraphics";



export function NoPlansData(){

    return(

        <div className={"w-full h-80 flex justify-center mt-4 rounded-xl"}>
            <div className={"flex flex-col justify-center items-center"}>
                <SVGraphics className=" w-11 h-11 flex justify-self-center mb-4" svgname={'folder'}/>
                <div className={"font-Roboto font-bold text-2xl leading-8 text-navSelected"}>NO PLANS FOUND</div>
                {/*<div className={"font-Rubik font-medium text-base"}></div>*/}
            </div>

        </div>
    )

}