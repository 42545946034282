import React from "react";
import { Slider } from '@mui/material';
import { styled } from '@mui/material/styles';

export function SlideAlpha(props) {

    const StyledSlider = styled(Slider)`
      color: ${props => props.value === 1 || props.value === true ? '#3B8B81' : '#807f7f'};
      max-width: 58px;
      width: 100%;
      height: 8px;
      margin-top: 10px;
      padding: 0;
      border-radius: 8px;

      & .MuiSlider-track {
        border-radius: 4px;
      }`;


    return (
        <div className={"mt-12"} style={{width:props.width}}>
            {props.leftLabel&&<span className={"mr-5"}>{props.leftLabel}</span>}
            <StyledSlider
                value={props.value}
                name={props.name}
                onChange={props.onChange}
                step={props.step}
                min={props.min}
                max={props.max}
                disabled={props.disabled}
            />
            {props.rightLabel&&<span className={"ml-5"}>{props.rightLabel}</span>}


        </div>
    );
}
