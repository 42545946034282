import axios from 'axios'
import { authenticationService } from '../AuthinticationService'

const globalUrl = process.env.REACT_APP_API_ENDPOINT

const LoginAPICalls = {
  signIn: async (email, password) => {
    let body = {
      Email: email,
      Password: password
      // "reCaptchaToken": reCaptchaToken
    }
    try {
      return await axios.post(`${globalUrl}/api/v1/webapp/users/signin`, body)
    } catch (err) {
      return err
    }
  },

  changeNewPassword: async (password) => {
    if (!authenticationService.currentUserValue) return
    let requestconfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authenticationService.currentUserValue.token
      }
    }
    try {
      return await axios.post(
        `${globalUrl}/api/v1/webapp/users/changePass`,
        { newPassword: password.newPassword.trim(), confirmNewPassword: password.confirmNewPassword.trim() },
        requestconfig
      )
    } catch (err) {
      console.log(err)

      return err
    }
  },

  submitForgotPasswordCode: async (code) => {
    let requestconfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authenticationService.currentfpUserValue.token
      }
    }
    try {
      return await axios.post(
        `${globalUrl}/api/v1/webapp/users/forgetPass/verify`,
        // `${globalUrl}/api/v1/webapp/users/forgetPass/verify/admin`,
        { code: code },
        requestconfig
      )
    } catch (err) {
      return err
    }
  },

  submit2fa: async (code) => {
    let requestconfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authenticationService.currentMfaUserValue.token
      }
    }
    try {
      return await axios.post(`${globalUrl}/api/v1/webapp/users/signin/2fa/admin`, { TwoFaCode: code }, requestconfig)
    } catch (err) {
      return err
    }
  },
  getVersion: async () => {
    let requestconfig = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    try {
      return await axios.get(`${globalUrl}/api/v1/webapp/users/getVersion`, requestconfig)
    } catch (err) {
      return err
    }
  },
  forgetPass: async (Email) => {
    let requestconfig = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    try {
      return await axios.post(`${globalUrl}/api/v1/webapp/users/forgetPass`, { Email: Email.Email }, requestconfig)
    } catch (err) {
      console.log(err)

      return err
    }
  }

  //
  // changeNewPassword: async (password) => {
  //     if (!authenticationService.pwdUserValue) return;
  //     let token = authenticationService.pwdUserValue.token
  //     let url = `${globalUrl}/api/v1/administrative/users/${authenticationService.pwdUserValue.profile.UserID}/changePass`
  //     let requestconfig = {
  //         headers: {
  //             'Content-Type': 'application/json',
  //             'Authorization': token,
  //         }
  //     };
  //     try {
  //         return await axios.post(
  //             `${globalUrl}/api/v1/administrative/users/${authenticationService.pwdUserValue.profile.UserID}/changePass`,
  //             {newPassword: password.split(" ").join("")},
  //         requestconfig
  //         );
  //     } catch (err) {
  //         return err
  //     }
  //
  //
  // },
  // resetPassword: async (email, reCaptchaToken) => {
  //     let requestconfig = {
  //         headers: {
  //             'Content-Type': 'application/json',
  //             "Access-Control-Allow-Origin": "*",
  //             "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
  //             "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
  //         }
  //     };
  //     let body = {
  //         "Email": email,
  //         "reCaptchaToken": reCaptchaToken
  //
  //     };
  //     try {
  //         return await axios.post(
  //             `${globalUrl}/api/v1/administrative/users/forgetPass`,
  //             body,
  //             requestconfig
  //         );
  //     } catch (err) {
  //         return err
  //     }
  //
  //
  // },
  //
  // resend2fa: async () => {
  //
  //     let requestconfig = {
  //         headers: {
  //             'Content-Type': 'application/json',
  //             'Authorization': authenticationService.currentMfaUserValue.token,
  //         }
  //     };
  //     try {
  //         return await axios.post(
  //             `${globalUrl}/api/v1/administrative/users/resend2fa`,
  //             {},
  //             requestconfig
  //         );
  //     } catch (err) {
  //         return err
  //     }
  //
  // },
}

export default LoginAPICalls
