import React from "react";
import Constants from "../../utils/constants";

export function FooterSaveButtons(props) {
    return (
        <div
            className={`${props.containerClass} my-8  flex sm:flex-col lg:items-end relative bottom-[5%] `}>
            {props.children}
            <div className={`${props.buttonsClass} flex items-end sm:mt-[2rem]`}>
                <button type="button"
                        disabled={props.disabledSave}
                        onClick={props.onClickSave}
                        className={`${Constants.buttonClass} ${props.saveButtonClass} mr-12 w-loginButton text-white bg-alphaTauButton hover:bg-alphaTauButtonHover font-Roboto font-medium rounded-rounded10 text-textSize px-5 py-2.5 text-center`}>
                    {props.saveLabel}
                </button>
                <div className={'mr-10 underline underline-offset-4 text-alphaTauButton cursor-pointer'}
                     onClick={props.onClickCancel}>{props.cencelLabel}
                </div>

            </div>
        </div>
    )
}
