import React, {useState} from "react";
import {authenticationService} from "../../../services/AuthinticationService";
import {useNavigate} from "react-router-dom";
import {TextAlpha} from "../../input/TextAlpha";
import utils from "../../../utils/utils";
import logo from "../../../assets/logo.svg";
import {AlphaForm} from "../../forms/AlphaForm";
import {Error} from "../../forms/Error";
import formData from "../../forms/formsData.json";
import Constants from "../../../utils/constants";

export function ForgotPassword() {
    const [forgotPasswordInput, setForgotPasswordInput] = useState({
        Email: "",
    });
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState({});
    const formType=formData['forgotPassword']
    const [BackendErrors, setBackendErrors] = useState(null);

    const inputClassName = "bg-white rounded-xl  h-16 w-96  h-full bg-transparent font-Roboto text-lg text-alphaTauText"

    // let recaptchaRef = useRef(null)

    const SubmitFunc = async () => {

        let response = await authenticationService.forgetPass(forgotPasswordInput)
        if (response && response.status < 400) {
            sessionStorage.setItem('userID', response['UserStorageId']);
            sessionStorage.setItem('status', response.status);
            sessionStorage.setItem('Email', response.data.email);
            navigate("/verificationCode",{state:{type:"email",value:response.data.email}});
        }
        else {
            setBackendErrors('The e-mail you entered is incorrect. Please try again')
        }
    }

    const handleChange = async (event) => {
        let inputs = {...forgotPasswordInput}
        let name = event.target.name
        let value = event.target.value
        forgotPasswordInput[name]=event.target.value
        switch (name) {

            case 'Email':
                if(value && !utils.mailValidation(value)){
                    setError({Email:true})
                    setIsError(true)
                    formType[0]['error'] = "Please enter valid email address"
                }else if(!value){
                    formType[0]['error'] = "Please enter email address"
                    setError({Email:true})
                    setIsError(true)
                }
                else{
                    setError({Email:false})
                    setIsError(false)
                    formType[0]['error'] = ""
                }
                break;
            default: setIsError(false)
        }
        inputs[name] = value
        setForgotPasswordInput(inputs)
        setBackendErrors(null)
    }

    return (
        <div style={Constants.bgStyle}>
            <div className={"flex-col font-Roboto w-login h-login bg-loginBg shadow-2xl rounded-3xl  flex items-center  m-auto absolute inset-0 px-[5.675rem]"}>
            <div className={"mt-11"}>
                <img src={logo} alt={"AlphaTAU"} className={"flex w-64 self-center"}/>
            </div>

            <div>
                <div className={"mt-16 text-alphaTauText flex-col flex text-lg "}>
                    <TextAlpha text={"Forgot Password?"} className={"self-start font-bold text-[1.75rem]"} />
                    <TextAlpha text={"Please enter the email associated with your account "} className={" w-loginButton self-start text-[1.125rem] mb-[1.75rem] auth-label"} />
                </div>

                <AlphaForm
                    data={formType}
                    formData={forgotPasswordInput}
                    onChange={(e) => handleChange(e)}
                    containerClassName={"flex-col flex w-full h-full mt-[1.75rem]"}
                    labelClassName={"font-Roboto text-[1.125rem] text-alphaTauText"}
                    inputClassName={inputClassName}
                    isShown={error}
                    isError={isError}
                />

                <div className={"h-centered flex-col flex w-21.75 h-full text-xs"}>
                    <Error message={BackendErrors} isShown={BackendErrors}/>
                </div>
            </div>


            <button type="button"
                    className="mt-[14.75rem] w-loginButton text-white mt-codeBottom h-loginButtonHeight bg-alphaTauButton hover:bg-alphaTauButtonHover font-Roboto font-medium rounded-xl text-textSize px-5 py-2.5 text-center"
                    onClick={()=>SubmitFunc()}
            >
                OK
            </button>

        </div>
        </div>


    )
}

