import { configureStore } from "@reduxjs/toolkit";

import chambersReducer from "./reducer/chambersReducer";
import OrdersReducer from "./reducer/OrdersReducer";


export const store = configureStore({
    reducer: {
        chambers: chambersReducer,
        orders: OrdersReducer,
    },
});


