import BG from '../assets/bg-image.svg'

let Constants = {
  emailRegExp: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
  phoneRegExp: /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  bgStyle: {
    backgroundImage: `url(${BG})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%'
  },
  buttonClass: 'font-Roboto font-medium rounded-rounded10 text-center',
  mainScreenClass: 'w-width90 h-full pt-32 ml-24 overflow-y-auto pb-8 lg:ml-8 md:ml-8 ',
  chambers_searchInputs_system: [
    { name: 'SiteID', label: 'Sites', type: 'Select', data: [], hasLine: true, svgName: 'sitePlace' },
    { name: 'GloveBoxNumber', label: 'Glove box', type: 'Select', data: [], hasLine: true, svgName: 'gloveBox' },
    { name: 'ActivityLevel', label: 'Asymptotic activity', type: 'Select', data: [], hasLine: true, svgName: 'activityLevel' },
    {
      name: 'Status',
      label: 'General status',
      type: 'Select',
      data: [
        { value: 'active', label: 'Active' },
        { value: 'maintenance', label: 'Maintenance' },
        { value: 'maintenanceWithOrder', label: 'Maintenance with order' },
        { value: 'disabled', label: 'Disabled' }
      ],
      hasLine: true,
      svgName: 'toogleRight'
    },
    { name: 'CurrentStatus', label: 'Current Status', type: 'Select', data: [], hasLine: true, svgName: 'power' },
    { name: 'DateRange', label: 'Activity date', type: 'date', data: [], hasLine: true, svgName: 'calendar' }
  ],
  chambers_searchInputs: [
    { name: 'GloveBoxNumber', label: 'Glove box', type: 'Select', data: [], hasLine: true, svgName: 'gloveBox' },
    { name: 'ActivityLevel', label: 'Asymptotic activity', type: 'Select', data: [], hasLine: true, svgName: 'activityLevel' },
    {
      name: 'Status',
      label: 'General status',
      type: 'Select',
      data: [
        { value: 'active', label: 'Active' },
        { value: 'maintenance', label: 'Maintenance' },
        { value: 'maintenanceWithOrder', label: 'Maintenance with order' },
        { value: 'disabled', label: 'Disabled' }
      ],
      hasLine: true,
      svgName: 'toogleRight'
    },
    { name: 'CurrentStatus', label: 'Current Status', type: 'Select', data: [], hasLine: true, svgName: 'power' },
    { name: 'DateRange', label: 'Activity date', type: 'date', data: [], hasLine: true, svgName: 'calendar' }
  ],
  orders_searchInputs: [
    { name: 'ORDSTATUSDES', label: 'Order Status', type: 'Select', data: [], hasLine: true, svgName: 'total-small' },
    { name: 'TYPEDES', label: 'Order Type', type: 'Select', data: [], hasLine: true, svgName: 'document-small' }
  ],
  gantt_searchInputs: [
    { name: 'chamberInternalID', label: 'Chamber', type: 'Select', data: [], hasLine: true, svgName: 'chambers-small' },
    { name: 'OrderID', label: 'Order', type: 'Select', data: [], hasLine: true, svgName: 'document-small' },
    { name: 'TYPEDES', label: 'Order Type', type: 'Select', data: [], hasLine: true, svgName: 'total-small' }
  ],
  gantt_searchInputsSystem: [
    { name: 'SiteID', label: 'Sites', type: 'Select', data: [], hasLine: true, svgName: 'sitePlace' },
    { name: 'chamberInternalID', label: 'Chamber', type: 'Select', data: [], hasLine: true, svgName: 'chambers-small' },
    { name: 'OrderID', label: 'Order', type: 'Select', data: [], hasLine: true, svgName: 'document-small' },
    { name: 'TYPEDES', label: 'Order Type', type: 'Select', data: [], hasLine: true, svgName: 'total-small' }
  ],
  hours: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
  month: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28'
  ],
  hoursAmPm: [
    { label: '1 AM', value: '1 AM' },
    { label: '2 AM', value: '2 AM' },
    { label: '3 AM', value: '3 AM' },
    { label: '4 AM', value: '4 AM' },
    { label: '5 AM', value: '5 AM' },
    { label: '6 AM', value: '6 AM' },
    { label: '7 AM', value: '7 AM' },
    { label: '8 AM', value: '8 AM' },
    { label: '9 AM', value: '9 AM' },
    { label: '10 AM', value: '10 AM' },
    { label: '11 AM', value: '11 AM' },
    { label: '12 AM', value: '12 AM' },
    { label: '1 PM', value: '1 PM' },
    { label: '2 PM', value: '2 PM' },
    { label: '3 PM', value: '3 PM' },
    { label: '4 PM', value: '4 PM' },
    { label: '5 PM', value: '5 PM' },
    { label: '6 PM', value: '6 PM' },
    { label: '7 PM', value: '7 PM' },
    { label: '8 PM', value: '8 PM' },
    { label: '9 PM', value: '9 PM' },
    { label: '10 PM', value: '10 PM' },
    { label: '11 PM', value: '11 PM' },
    { label: '12 PM', value: '12 PM' }
  ],
  ganttOrdersColor: [
    '#108aa1',
    '#490528',
    '#1629b6',
    '#8a0b2d',
    '#DA3E9D',
    '#9f7013',
    '#6c0a5c',
    '#918e08',
    '#E33A16',
    '#B05B05',
    '#BA2E63',
    '#24BF61',
    '#4E24BF',
    '#24BF2B',
    '#BF2434',
    '#BF4524',
    '#2497BF',
    '#95BF24',
    '#4B1A92',
    '#1A9275',
    '#D65CD5',
    '#5CD69B',
    '#D6A65C',
    '#D65C90',
    '#5CD6D6',
    '#7BD65C',
    '#5C72D6',
    '#D65CBC',
    '#D6995C',
    '#9F5CD6',
    '#5CD6A6',
    '#F3920E',
    '#2F0C6B',
    '#EE64B8',
    '#04E418',
    '#D84163',
    '#68ABEE',
    '#EE7868',
    '#033B49',
    '#49033B'
  ],

  inputClass:
    'w-[21.75rem] 2xl:w-[20rem] xl:w-[21rem] lg:w-[21rem] md:w-[21rem] bg-white rounded-xl h-14 bg-transparent font-Roboto text-alphaTauText',
  inputLabelClass: 'font-Roboto text-inputSize text-alphaTauText font-bold mb-2'
}

export default Constants
