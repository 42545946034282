import React from "react";
import SVGraphics from "../../assets/SVGraphics";

export function Error(props) {
    return (
        <div className={'flex mt-1.5 items-center'}>
            <SVGraphics className={'h-4 w-4 mr-2.5 '} svgname={'alert'} style={{display:props.isShown?'block':'none'}}/>
              <span className={"text-red-500 text-xs" } style={{display:props.isShown?'block':'none'}}>{props.message}</span>
        </div>


    )
}
