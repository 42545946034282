import { BehaviorSubject } from 'rxjs'
import { LoginAPICalls } from './ApiService'
const currentUserSubject = new BehaviorSubject();
const mfaUserSubject = new BehaviorSubject();
const pwdUserSubject = new BehaviorSubject();
const fpUserSubject = new BehaviorSubject();
const fpvUserSubject = new BehaviorSubject();

let userLoggedIn = false;
let mfaUserLoggedIn = false
let pwdUserLoggedIn = false;

export const authenticationService = {
    submitforgotPassVerfication,
    login,
    logout,
    isLoggedin,
    forgetPass,
    reauthenticate,
    submit2fa,
    changeNewPassword,
    resetPassword,
    currentSessionToken,
    setUser,
    userLoggedIn,
    mfaUserLoggedIn,
    pwdUserLoggedIn,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value
    },
    currentfpUser: fpUserSubject.asObservable(),
    get currentfpUserValue() {
        return fpUserSubject.value
    },
    currentfpvUser: fpvUserSubject.asObservable(),
    get currentfpvUserValue() {
        return fpvUserSubject.value
    },
    currentMfaUser: mfaUserSubject.asObservable(),
    get currentMfaUserValue() {
        return mfaUserSubject.value
    },
    pwdUser: pwdUserSubject.asObservable(),
    get pwdUserValue() {
        return pwdUserSubject.value
    }
};

async function login(email, password) {

    let response = await LoginAPICalls.signIn(email, password);
    if (response && response.status < 400) {
        let data = response.data
        currentUserSubject.next(data);
        sessionStorage.setItem('loggedInUser', JSON.stringify(data))
        sessionStorage.setItem('userLoggedIn', String(true))

        // utils.set(data,'status','pass')
        // utils.set(data,'updatePassword',true)
        if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === '2fa') {
            mfaUserLoggedIn = true;
            mfaUserSubject.next(data);
        } else if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === 'PASS') {
            if (data.hasOwnProperty('updatePassword') && data['updatePassword'] === true) {
                pwdUserSubject.next(data)
                pwdUserLoggedIn = true
            } else  {
                userLoggedIn = true;
                currentUserSubject.next(data);

            }
        }
    }

    return response;
}

async function submit2fa(mfaCode, userID) {
    let response = await LoginAPICalls.submit2fa(mfaCode);
    if (response.status < 400) {
        let data = response.data
        currentUserSubject.next(data);
        sessionStorage.setItem('loggedInUser', JSON.stringify(data))
        sessionStorage.setItem('userLoggedIn', String(true))
        if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === 'pass') {
            if (data.hasOwnProperty('updatePassword') && data['updatePassword'] === true) {
                pwdUserSubject.next(data)
                pwdUserLoggedIn = true
            } else if (data.hasOwnProperty('updatePassword') && data['updatePassword'] === false) {
                userLoggedIn = true;
                currentUserSubject.next(data);
            }
            mfaUserSubject.next(null);
            mfaUserLoggedIn = false
        }
    }

    return response;
}
async function submitforgotPassVerfication(mfaCode) {
    let response = await LoginAPICalls.submitForgotPasswordCode(mfaCode);
    if (response.status < 400) {
        let data = response.data
        currentUserSubject.next(data);
        sessionStorage.setItem('userLoggedIn', String(false))
    }

    return response;
}
async function forgetPass(email) {
    let response = await LoginAPICalls.forgetPass(email);

    if (response.status < 400) {
        let data = response.data
        await fpUserSubject.next(data);
        sessionStorage.setItem('fpUserLoggedIn', String(false))
        sessionStorage.setItem('userLoggedIn', String(false))
    }

    return response;
}


function reauthenticate() {
    let data = JSON.parse(sessionStorage.getItem('loggedInUser'))
    sessionStorage.setItem('userLoggedIn', String(true))
    currentUserSubject.next(data);
}
function isLoggedin() {
    return Boolean(sessionStorage.getItem('userLoggedIn') === "true" && sessionStorage.getItem('loggedInUser') !== "undefined")
}

async function changeNewPassword(password) {

    let response = await LoginAPICalls.changeNewPassword(password);
    if (response && response.hasOwnProperty('data') &&
        response.data.hasOwnProperty('newPassword') &&
        response.data['newPassword'] === true) {
        let val = Object.assign({}, pwdUserSubject.value)
        pwdUserSubject.next(response.data)
        pwdUserLoggedIn = true
        // pwdUserSubject.next(null);
        // pwdUserLoggedIn = false
        currentUserSubject.next(val);
        userLoggedIn = true;

    }

    return response;
}
async function currentSessionToken() {
    let user = JSON.parse(JSON.stringify(currentUserSubject['value']));
    let currentToken = user['token']
    // let refreshToken = user['refreshToken']
    // let decodedToken = utils.parseJwt(currentToken)
    // let exp = (decodedToken['exp']) * 1000
    // let curTime = (new Date()).getTime()
    // let tokenExpired = curTime - exp > 0
    // return new Promise(async (resolve, reject) => {
    //     if (tokenExpired) {
    //         await refreshToken(refreshToken).then(async token => {
    //             //set new token to user
    //             user['token'] = token
    //             await currentUserSubject.next(user);
    //             sessionStorage.setItem('loggedInUser', JSON.stringify(user))
    //             resolve(token)
    //         }).catch(e => {
    //             console.error(e);
    //             reject(e);
    //         })
    //     } else {
    //         resolve(currentToken)
    //     }
    //
    // });
    return currentToken
}

async function resetPassword(email, reCaptchaToken) {
    return await LoginAPICalls.resetPassword(email, reCaptchaToken);
}

function logout() {
    document.getElementById('root').click();
    userLoggedIn = false;
    currentUserSubject.next(null);
    sessionStorage.clear()
}
function setUser(data){

    currentUserSubject.next(data);
    mfaUserSubject.next(data);
    sessionStorage.setItem('loggedInUser', JSON.stringify(data))
}
