import {OverlayTrigger, Tooltip} from "react-bootstrap";
import "../../style/view/UserOptions.css"
import React from "react";

class UsersOptions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ref: null
        }
        this.ref = null;
    }

    render() {
        let {options} = this.props;

        return(
            <>
                {
                    (options&&options.length > 0 && options[0]?.hasOwnProperty('FirstName'))&&
                    <OverlayTrigger
                    placement={"bottom"}
                    delay={{show: 250, hide: 250}}
                    trigger={["hover", "focus"]}
                    offset={0}
                    rootClose
                    // show={true}
                    overlay={<Tooltip id="tooltip-disabled" className={"tableToolTip "}>
                        {
                        options.length > 0 ? options?.map(option => <div
                            key={option?.FirstName + option?.LastName}
                        className={" "}>
                        {option?.FirstName + ' ' + option?.LastName}
                       </div>) : options?.length === 1 ? options[0]?.FirstName + ' ' + options[0]?.LastName : ""
                        }
                    </Tooltip>}>
                    <div>{options.length === 1 ? options[0].FirstName + ' ' + options[0].LastName : options.length > 0 ? options[0].FirstName + ` ${options[0].LastName} +` + (options.length - 1).toString() : ""}</div>
                    </OverlayTrigger>

                }
            </>
        )
        // if(!!options && options.length>0){

            // else return (
            //     <OverlayTrigger
            //         placement={"right"}
            //         delay={{show: 250, hide: 250}}
            //         trigger={["hover","focus"]}
            //         offset={10}
            //         rootClose
            //         overlay={
            //             <div className={'physician-options'}>
            //                 <Popover id="popover-faqs">
            //                     <div >
            //                         <Popover.Content>
            //
            //                             <div>
            //                                 {options.length > 0 ? options.map(option => <div
            //                                     className={'patients-table-physician'}>
            //                                     {(option)['FirstName'] + ' ' + (option)['LastName']}
            //                                 </div>) : options.length === 1 ? options[0].FirstName : ""}
            //                             </div>
            //                         </Popover.Content>
            //                     </div>
            //                 </Popover>
            //             </div>
            //         }>
            //         <div>{options.length === 1 ? options[0].FirstName +" "+ options[0].LastName  : options.length > 0 ? options[0].FirstName +" "+ options[0].LastName + " +" + ((options.length - 1).toString() ): ""}</div>
            //     </OverlayTrigger>
            //
            // );


    }
}

export default UsersOptions
