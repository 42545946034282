import React from "react";
import "../../style/input/checkbox.css"



export function RadioButtonAlpha(props) {

    return (
        <div className={'flex ml-8'}>
            {/*<InputLabel className={props.labelClassName}>{props.label}</InputLabel>*/}
                <div className={'flex '} >
                    <input name={props.name}
                           type={'radio'}
                           value={props.value}
                           checked={props.checked}
                           onClick={props.onChange}
                           className={'radio-custom-style cursor-pointer'}/>
                    <div onClick={props.onChange} className={'shadow-md w-[1.875rem] h-[1.875rem] border-2 border-red relative rounded-full right-[1.7rem] top-[-0.20rem]'}></div>
                </div>
                    {/*<span class="checkmarkRadio"></span>*/}

            <label className={""}>{props.label} </label>

            {/*<OutlinedInput*/}
            {/*    error={props.isError}*/}
            {/*    className={`inputClass ${props.inputClassName}`}*/}
            {/*    type={'text'}*/}
            {/*    id={props.name+props.value}*/}
            {/*    maxLength={props.maxLength}*/}
            {/*    // error={isErr}*/}
            {/*    // defaultValue={props.defaultValue}*/}
            {/*    value={props.value}*/}
            {/*    onChange={props.onChange}*/}
            {/*    // helperText={isErr ? errMessage : ''}*/}
            {/*    placeholder={props.placeholder}*/}
            {/*    disabled ={props.disabled}*/}
            {/*    // required={required}*/}
            {/*    name={props.name}*/}
            {/*    endAdornment={props.endAdornment}*/}
            {/*/>*/}
        </div>
    )
}
