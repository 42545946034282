import { authenticationService } from '../AuthinticationService'
import Config from '../../config/Config'
import restApi from './RestApi'

const globalUrl = Config.globalUrl

const GanttApi = {
  getGanttData: async (body) => {
    // body['startDate'] = '2023-06-23'
    // body['endDate'] ='2023-06-30'
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/gantt/${user.profile.UserStorageId}/getGanttData`, body, requestconfig)
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  getGanttNotes: async (siteID) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.get(`${globalUrl}/api/v1/webapp/gantt/${user.profile.UserStorageId}/getGanttNotes/${siteID}`, requestconfig)
    } catch (err) {
      return err
    }
  },
  addGanttNote: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }
      return await restApi.post(`${globalUrl}/api/v1/webapp/gantt/${user.profile.UserStorageId}/addGanttNote`, body, requestconfig)
    } catch (err) {
      return err
    }
  },
  deleteGanttNote: async (noteId) => {
    try {
      if (!authenticationService.currentUserValue) return
      let user = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token
        }
      }

      return await restApi.post(
        `${globalUrl}/api/v1/webapp/gantt/${user.profile.UserStorageId}/deleteGanttNote`,
        {
          noteID: noteId,
          UserStorageId: user.profile.UserStorageId
        },
        requestconfig
      )
    } catch (err) {
      return err
    }
  }
}
export default GanttApi
