import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  chambers: [],
  isNeedToUpdateActivity:false


};

export const chambersReducer = createSlice({
  name: "chambers",
  initialState,
  reducers: {
    setChambersData: (state, action) => {
      let _chambers =action.payload
      // let newState =[]

      // chambers.map(item=>{
      //   console.log(item)
      //   newState.push({...item, ...item.PII, ...item.User})
      // })
      state.chambers = _chambers;
    },
    setIsNeedToUpdateActivity: (state, action) => {
      let _isNeedToUpdateActivity =action.payload
      state.isNeedToUpdateActivity = _isNeedToUpdateActivity;
    },
  },
});

export const { setChambersData } = chambersReducer.actions;
export default chambersReducer.reducer;
