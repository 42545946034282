import React from "react";
import {TextAlpha} from "./TextAlpha";

import { OutlinedInput} from "@mui/material";
export function PasswordInputAlpha(props) {

    return (
        <div className={props.containerClassName}>
            <TextAlpha text={props.placeholder} className={props.labelClassName} />
            <OutlinedInput
                error={props.isError}
                className={props.inputClassName}
                type={props.type}
                id={'login'}
                // error={isErr}
                onChange={props.onChange}
                // helperText={isErr ? errMessage : ''}
                placeholder={props.placeholder}
                // required={required}
                handleKeyPress={props.handleKeyPress}
                name={props.name}
                autoFocus={false}
                multiline={false}
                notched={false}
                endAdornment={props.endAdornment}
            />
        </div>
    )
}
