import React from 'react'
import { Modal } from 'react-bootstrap'
import { confirmable, createConfirmation } from 'react-confirm'
import Constants from '../../utils/constants'
import SVGraphics from '../../assets/SVGraphics'
import PropTypes from 'prop-types'

class ConfirmComposed extends React.Component {
  componentDidMount() {
    document.getElementById('modal-ok-button').focus()
  }

  render() {
    let {
      proceedLabel,
      cancelLabel,
      title,
      message,
      extraMessage,
      extraExtraMessage,
      show,
      proceed,
      warningRed,
      red,
      hasSVG,
      svgWidth,
      modalStyle,
      svgHeight,
      svgName,
      enableEscape = true
    } = this.props
    return (
      <div
        id='modalError'
        tabIndex='-1'
        aria-hidden='true'
        className='fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full bg-stone-900/60 flex items-center justify-center '
      >
        <div className='w-2/5 h-auto max-w-xl md:h-auto self-center'>
          <Modal
            id={'Confirm-modal-body'}
            centered={true}
            show={show}
            onHide={() => proceed(false)}
            // backdrop={enableEscape ? true : "static"}
            keyboard={enableEscape}
            dialogClassName={'app-modal'}
          >
            <Modal.Body className={`app-modal-body ${!modalStyle && 'px-[2.438rem]'}`} style={modalStyle}>
              <div
                className={' w-8 h-8 p-1 text-center float-right cursor-pointer top-[1rem] right-[3%] relative text-black font-semibold'}
                onClick={() => proceed(false)}
              >
                &#10005;
              </div>
              {hasSVG && (
                <SVGraphics
                  style={{ width: svgWidth ? svgWidth : '2.5rem', height: svgHeight ? svgHeight : '2.5rem' }}
                  className={' mt-[2.188rem] mb-4 ml-auto mr-auto'}
                  svgname={svgName}
                />
              )}
              <span className={`app-modal-title ${red ? 'red' : ''} `}>{title}</span>
              <div className={'app-modal-content'}>
                {message}
                {extraMessage !== null && extraMessage !== undefined && (
                  <div className={`app-modal-content-extra ${warningRed ? 'bolded red' : ''}`}>{extraMessage}</div>
                )}
                {extraExtraMessage !== null && extraExtraMessage !== undefined && (
                  <div className={'app-modal-content-extra'}>{extraExtraMessage}</div>
                )}
              </div>
              <div className={'app-modal-footer h-centered py-[1.25rem]'}>
                <button
                  id={'modal-ok-button'}
                  style={{ width: cancelLabel !== 'none' ? '14.313rem' : '100%' }}
                  className={`${Constants.buttonClass} bg-alphaTauButton text-[#FFFFFF] h-[3.5rem] mr-[1.25rem]`}
                  onClick={() => proceed(true)}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter' && cancelLabel === 'none') {
                      proceed(true)
                    }
                  }}
                >
                  {proceedLabel?proceedLabel:'Yes'}
                </button>
                {cancelLabel !== 'none' ? (
                  <button
                    className={`${Constants.buttonClass} bg-[#FFFFFF] text-alphaTauButton border border-alphaTauButton w-[14.313rem] h-[3.5rem]`}
                    onClick={() => proceed(false)}
                  >
                    {cancelLabel?cancelLabel:'No'}
                  </button>
                ) : (
                  <div />
                )}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    )
  }
}

ConfirmComposed.propTypes = {
  proceedLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  svgWidth: PropTypes.string,
  svgHeight: PropTypes.string,
  svgName: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  modalStyle: PropTypes.object,
  hasSVG: PropTypes.bool,
  extraMessage: PropTypes.string,
  extraExtraMessage: PropTypes.string,
  show: PropTypes.bool,
  warningRed: PropTypes.bool,
  red: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool
}

export function AlertConfirm(
  { proceedLabel, cancelLabel, options },
  message,
  title
) {
  return createConfirmation(confirmable(ConfirmComposed))({
    message,
    proceedLabel,
    cancelLabel,
    title,
    ...options
  })
}

// let AlertConfirm = compose(
//     withTranslation()
// )(AlertConfirmComposed)

// export default AlertConfirm
