import React, {useEffect, useState} from "react";
import {TextAlpha} from "../../../input/TextAlpha";
import {useNavigate, useParams} from "react-router-dom";
import {v4 as uuidv4} from "uuid";
import formData from "../../../forms/formsData.json";
import Constants from "../../../../utils/constants";
import {object, string} from "yup";
import SystemAdmin from "../../../../services/ApiServices/SystemAdminApi";
import {AlphaForm} from "../../../forms/AlphaForm";
import {Country} from "country-state-city";
import {FormCard} from "../../../forms/FormCard";
import SVGraphics from "../../../../assets/SVGraphics";



export function EditSite() {

    const navigate = useNavigate();
    const [entry, setEntry] = useState({});
    const [productionAdmin, setProductionAdmin] = useState([{id:uuidv4(),FirstName:"",LastName:"",Email:"",PhoneNumber:"",isExist:true}]);
    const [error, setError] = useState({})
    const [adminErrors, setAdminErrors] = useState([{}])
    const [isError, setIsError] = useState(false)
    const [siteId, setSiteId] = useState("")
    const [formType, setFormType] = useState(formData['add_site'])
    const [formTypeEngineering, setFormTypeEngineering] = useState(formData['add_Process_engineering'])
    const [formTypeProductionAdmin, setFormTypeProductionAdmin] = useState([formData['add_Production_Admin']])
    const [disabled, setDisabled] = useState(false)
    // const [addAdminDisabled, setAddAdminDisabled] = useState(false)
    let params  = useParams();

    const phoneRegExp = Constants.phoneRegExp

    const siteSchema = object().shape({
        SiteName: string().required('Please insert Name').default(""),
        prioritySiteID: string().required('Please insert priority Site ID').default(""),
        Country: object().required().typeError('Please insert Country').default({}),
        State: object().optional().default({}),
        ZIP: string().required('Please insert ZipCode').typeError('Please insert ZipCode').default(""),
        City: object().optional().default({}),
        Street: string().required().typeError('Please insert Street').default(""),
        Number: string().required().typeError('Please insert Number').default(""),
        ActiveChambers: string().required('Please insert Chambers').typeError('Please insert Chambers').default(""),
        Phone: string().optional().matches(phoneRegExp, 'Phone number is not valid').nullable().default("")})

    const engineeringSchema = object().shape({
        technicalPersonFirstName: string().required('Please insert First Name').default(""),
        technicalPersonLastName: string().required('Please insert Last Name').default(""),
        technicalPersonPhoneNumber: string().required('Please insert Phone Number').typeError('Please insert Phone Number').matches(phoneRegExp, 'Phone number is not valid').default(""),
    });
    const productionAdminSchema = object().shape({
        FirstName: string().required('Please insert First Name').default(""),
        LastName: string().required('Please insert Last Name').default(""),
        Email: string().required().typeError('Please insert Email').email('Please insert Valid Email').default(""),
        PhoneNumber: string().required('Please insert Phone Number').typeError('Please insert Phone Number').matches(phoneRegExp, 'Phone number is not valid').default(""),
    });


    useEffect(() => {
        let form = JSON.parse(JSON.stringify(formType))
        form.forEach(f=>{
            if(f.name !== "Phone" && f.name !== "prioritySiteID" ){
                f['disabled'] = true
            }
        })
        setFormType(form)
            let id = params.id
            if(id) {
                setSiteId(id)
                const fetchDataGetSite = async (id) => { //get patient data from db
                    let site = await SystemAdmin.getSite(id)
                    if (site && site.data) {
                        site = site.data
                        let country = (Country.getAllCountries()).find(country =>country.name ===  site['Country'])
                        if(country){
                            site['Country'] = {label:site['Country'],value:country?.isoCode}
                            site['State'] = {label:site['State'],value:site['State']}
                            site['City'] = {label:site['City'],value:site['City']}
                        }
                        setEntry(site)
                        let adminsFormArr=[]
                        let adminsArr=[]

                        let admins = site.ProductAdmin
                        admins.forEach(admin=>{
                            admin['isExist'] = true
                            // admin['Country'] = site['Country']
                            adminsArr.push(admin)
                            let __formData = JSON.parse(JSON.stringify(formData['add_Production_Admin']))
                            __formData.forEach(d=>{
                                d['disabled'] = true
                            })
                            adminsFormArr.push(__formData)
                            adminErrors.push({})

                        })
                        setProductionAdmin(adminsArr)
                        setFormTypeProductionAdmin(adminsFormArr)
                    }
                }
                fetchDataGetSite(id).then()
            }

        },[])



    const onChange = async (name, value,type,id) => {
        let _entry = {...entry}
        let _productionAdmin = [...productionAdmin]
        let form = [...formType]
        let _formTypeEngineering = [...formTypeEngineering]
        let _formTypeProductionAdmin = formTypeProductionAdmin[id]
        let _adminErrors = adminErrors[id]
        // if(Object.keys(_adminErrors).length === 0 || !_adminErrors[id]){
        //     _adminErrors[id]={}
        // }
        // let adminIndex
        // if(id){
        //     adminIndex = _productionAdmin.findIndex(x=>x.id === id)
        // }
        let _form
        let formErrors = {...error}
        if (value === "") {
            value = null
        }
        if(type === 'add_site' || type === 'add_Process_engineering'){
            _entry[name] = value
            if(name==='Country'){
                _entry['State'] = null
                _entry['City'] = null
                form[7]['Country'] = value.value
            }
            if(type === 'add_site'){
                _form=form
            }else if(type === 'add_Process_engineering'){
                _form = _formTypeEngineering
            }
        }else if(type === 'add_Production_Admin'){
            if(name==="Email"){
                if(value?.match(Constants.emailRegExp)){
                    let user = await SystemAdmin.getAdminByEmail(value)
                    if(user && user.data){
                        user = user.data
                        _productionAdmin[id]['FirstName'] = user.FirstName
                        _productionAdmin[id]['LastName'] = user.LastName
                        _productionAdmin[id]['PhoneNumber'] = user.PhoneNumber
                    }
                }
            }
            _productionAdmin[id][name] = value
            _form = _formTypeProductionAdmin
        }
        let validateType = type === 'add_site' ? siteSchema : type === 'add_Process_engineering' ? engineeringSchema :  productionAdminSchema
        let objectType = type === 'add_site' ||  type === 'add_Process_engineering' ? _entry : _productionAdmin[id]
        await validateType.validate(objectType,{abortEarly: false}).then(function(_value) {
            setDisabled(false)
            formErrors[name] = false
            _adminErrors[name] = false
            setIsError(false)
        })
            .catch(function(err) {
                let index = _form.findIndex(x=>x.name===name)
                err?.inner?.forEach(error=>{
                    if(error.path===name){
                        if(type === 'add_Production_Admin'){
                            _adminErrors[error.path] = true
                        }else{
                            formErrors[error.path] = true
                        }
                        _form[index]['error'] = error.errors
                        setDisabled(true)
                        setIsError(true)
                    }
                })
                let errorIndex =err?.inner?.findIndex(x=>x.path===name)
                if(errorIndex===-1){
                    if(type === 'add_Production_Admin'){
                        _form[index]['error'] = []
                        _adminErrors[name] = false
                    }else{
                        formErrors[name] = false
                        setDisabled(false)
                    }
                    _form[index]['error'] = []
                }
            });
        // if(type === 'add_Production_Admin') {
            // productionAdminSchema.validate(productionAdmin[productionAdmin.length - 1], {abortEarly: false}).then(function (value) {
            //     setAddAdminDisabled(false)
            // })
            //     .catch(function (err) {
            //         setAddAdminDisabled(true)
            //     });
        // }
        setEntry(_entry)
        setProductionAdmin(_productionAdmin)
        if(type === 'add_site'){
            setFormType(_form)
        }else if(type === 'add_Process_engineering'){
            setFormTypeEngineering(_form)
        }else if(type === 'add_Production_Admin'){
            let formTypeAdmin = [...formTypeProductionAdmin]
            let errors = [...adminErrors]
            formTypeAdmin[id]=_form
            errors[id]=_adminErrors
            setFormTypeProductionAdmin(formTypeAdmin)
            setAdminErrors(errors)
        }
       else{
            setError(formErrors)
        }
        setError(formErrors)
        if (type!=='add_site'){
            await validateSite()
        }
    }
    const validateSite = async () => {
        let _form = JSON.parse(JSON.stringify(formType))
        let formErrors = {...error}
        await siteSchema.validate(entry,{abortEarly: false}).then(function(value) {
            // formErrors[name] = false
            setDisabled(false)
            setIsError(false)
        })
            .catch(function(err) {

                err.inner.forEach(error=>{
                    let index = _form.findIndex(x=>x.name===error.path)
                    if(index>-1){
                        formErrors[error.path] = true
                        _form[index]['error'] = error.errors
                        setDisabled(true)
                        setIsError(true)
                        setFormType(_form)
                    }
                })
            });
        setError(formErrors)

    }
    const addAdminForm = () => {
        let _form=JSON.parse(JSON.stringify(formData['add_Production_Admin']))
        _form.forEach(f=>{
            f['disabled'] = false
        })
        setProductionAdmin([...productionAdmin,{id:uuidv4(),FirstName:"",LastName:"",Email:"",PhoneNumber:"",Country:entry['Country']}])
        setFormTypeProductionAdmin([...formTypeProductionAdmin, _form]);
        setAdminErrors([...adminErrors, {}]);
    }
    const removeAdminForm = (id) => {
        let admin = [...productionAdmin]
        let index = admin.findIndex(x=>x.id === id)
        if(index>-1){
            admin.splice(index,1)
        }
        setProductionAdmin(admin)
    }
    const editSite = async() => {

        entry['Country'] = entry['Country'].label
        entry['State'] = entry['State']?.label?entry['State']?.label:entry['Country']
        entry['City'] = entry['City']?.label?entry['City']?.label:entry['Country']
        delete entry['ProductAdmin']
        delete entry['userSites']
        productionAdmin.forEach(admin=>{
            delete admin['id']
            delete admin['Country']
        })

        let siteInfo = {
            SiteInfo: entry,
            productionAdmins:productionAdmin
        }
        let submit = await SystemAdmin.editSite(siteInfo,siteId)
        if(submit && submit.data){
            navigate('/systemAdmin/sites')
        }
    }
    const isExistForm = JSON.parse(JSON.stringify(formTypeProductionAdmin))
    isExistForm.forEach(form=> {
        form['disabled'] = true
    })

    const notExistForm =JSON.parse(JSON.stringify(formTypeProductionAdmin))
    notExistForm.forEach(form=>{
             form['disabled'] = false
        })

    const cancelEdit = async () => {

            navigate('/systemAdmin/sites')
        }


    return(
        <div className="w-full h-full pt-32 ml-24 overflow-auto ">
            <div className={'w-11/12'}>
            <div className={'mb-7'}>
                <TextAlpha text={"Sites"}  className={"text-alphaTauText text-xl text-titleSize cursor-pointer"} onClick={()=>navigate('/systemAdmin/sites')}/>
                <TextAlpha text={" > "}  className={"text-alphaTauText text-xl  text-titleSize"}/>
                <TextAlpha text={" Edit Site"}  className={"text-alphaTauButton text-xl text-titleSize"}/>

            </div>

                <FormCard
                    cardClass={'pb-[1.25rem] mb-[1.5rem]'}
                    cardTitle={"Site Info"}
                    data={<> <AlphaForm
                        data={formType}
                        formData={entry}
                        country={entry['Country']?.value}
                        onChange={e => onChange(e.target.name, e.target.value,'add_site')}
                        containerClassName={"mb-6 mr-12"}
                        labelClassName={Constants.inputLabelClass}
                        inputClassName={Constants.inputClass}
                        isShown={error}
                        isError={isError}
                    />
                    </>
                    }

                />

            <div className={'mt-9'}>
                <FormCard
                    cardClass={'pb-[1.25rem] mb-[1.5rem]'}
                    cardTitle={"Process Engineer"}
                    data={<> <AlphaForm
                        data={formTypeEngineering}
                        formData={entry}
                        country={entry['Country']?.value}
                        onChange={e => onChange(e.target.name, e.target.value,'add_Process_engineering')}
                        containerClassName={"mb-6 mr-12"}
                        labelClassName={Constants.inputLabelClass}
                        inputClassName={Constants.inputClass}
                        isShown={error}
                        isError={isError}
                    />
                    </>
                    }

                />
            </div>
            <div className={'mt-9 '}>
                {productionAdmin.map((item, index) => {
                    return <FormCard
                        key={item.Email}
                        cardClass={'pb-[1.875rem]'}
                        blockClass={'mt-5'}
                        cardTitle={"Production admin info"}
                        hasDeleteIcon={item['isExist']?false:true}
                        length={productionAdmin.length}
                        deleteCurrent={()=>removeAdminForm(item.id)}
                        data={<AlphaForm
                            key={index + "admin"}
                            country={entry['Country']?.value}
                            // chosenOptions={chosenSites}
                            data={formTypeProductionAdmin[index]}
                            formData={item}
                            onChange={e => onChange(e.target.name, e.target.value,'add_Production_Admin',index)}
                            containerClassName={"mb-2 mr-12"}
                            labelClassName={Constants.inputLabelClass}
                            inputClassName={Constants.inputClass}
                            isShown={adminErrors[index]}
                            isError={isError}
                        />
                        }/>
                })}
                    {/*</div>*/}
                <div
                    className={'text-alphaTauButton text-[1.25rem] h-[3.5rem] font-bold font-Roboto flex items-center cursor-pointer'}
                    onClick={() => addAdminForm()}>
                    <SVGraphics className={'w-6 h-6 mr-[0.75rem]'} svgname={'plus-circle'}/>
                    Add
                </div>

                    {/*<div className={'w-1/2 flex flex-col justify-end mb-32'}>*/}
                    {/*    <button*/}
                    {/*        disabled={addAdminDisabled}*/}
                    {/*        className={"mt-12 mr-8 text-center w-10 h-10 bg-alphaTauButton text-white rounded-full text-2xl"}*/}
                    {/*        onClick={()=>addAdminForm()}*/}
                    {/*    >+</button>*/}
                    {/*</div>*/}

                {/*</div>*/}
            </div>
            <div className={'float-right my-8 flex items-center pb-[3.125rem]'}>
                <button type="button"
                        disabled={disabled}
                        onClick={()=>editSite()}
                        className="mr-12 w-loginButton text-white bg-alphaTauButton hover:bg-alphaTauButtonHover font-Roboto font-medium rounded-rounded10 text-textSize px-5 py-2.5 text-center" >
                    Edit
                </button>
                <div className={'mr-10 underline underline-offset-4 text-alphaTauButton text-[1.313rem]'} onClick={()=>cancelEdit()}>Cancel</div>
            </div>

            </div>
        </div>
    )
}