import {OverlayTrigger, Tooltip} from "react-bootstrap";
import "../../style/view/UserOptions.css"
import React from "react";

export function TableRowOptions(props) {

    const options = props.options

    return(
            <>
                {options && options.length === 1 &&  <div
                    className={'cursor-pointer'}
                    onClick={()=>props.onButtonClick()}
                >{options[0]}</div>}


                {options && options.length > 1 &&
                    <OverlayTrigger
                    placement={"bottom-start"}
                    delay={{show: 250, hide: 250}}
                    trigger={["hover", "focus"]}
                    offset={0}
                    rootClose
                    // show={true}
                    overlay={<Tooltip id="tooltip-disabled" className={"tableToolTip "}>
                        {
                        options?.map(option => <div className={" "}>{option}</div>)
                        }
                    </Tooltip>}>
                    <div
                        className={'cursor-pointer'}
                        onClick={()=>props.onButtonClick()}
                    >{ options[0] + ` +` + (options.length - 1).toString() }</div>
                    </OverlayTrigger>
                }
            </>
        )
}
