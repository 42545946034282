import React from "react";
import {InputLabel, OutlinedInput} from "@material-ui/core";

export function EmailInputAlpha(props) {

    return (
        <div className={props.containerClassName}>
            <InputLabel className={props.labelClassName}>{props.label}</InputLabel>
            <OutlinedInput
                type={"email"}
                disabled={props.disabled}
                maxLength={props.maxLength}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                name={props.name}
                className={props.inputClassName}
                placeholder={props.value?props.value:props.placeholder}
                id={'login'}
                onChange={props.onChange}
                defaultValue={props.value?props.value:''}
                // value={props.value?props.value:''}
            />

        </div>
    )
}
