const Config = {
  globalUrl: process.env.REACT_APP_API_ENDPOINT,
  userRoles: ['SystemAdmin'],
  loginRoles: ['SystemAdmin'],

  roles: {
    SystemAdmin: 'SystemAdmin'
  },
  version: 'V' + require('../../package.json')['version'],
  BuildVersion: require('../../package.json')['build']
}

Object.freeze(Config)
export default Config
