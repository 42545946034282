import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { confirmable, createConfirmation } from 'react-confirm'
import '../../style/errorModal.css'

class ErrorModal extends React.Component {
  componentDidMount() {
    document.getElementById('err-modal-ok-button').focus()
  }

  render() {
    let { proceedLabel, title, errMessages, show, proceed, enableEscape = true, t } = this.props

    return (
      <div
        id='modalError'
        tabIndex='-1'
        aria-hidden='true'
        className='fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full bg-stone-900/60 flex items-center justify-center '
      >
        <div className='w-2/5 h-auto max-w-xl md:h-auto self-center'>
          <Modal
            centered={true}
            show={show}
            onHide={() => proceed(false)}
            backdrop={enableEscape ? true : 'static'}
            keyboard={enableEscape}
            dialogClassName={'modal-dialog'}
          >
            <Modal.Body className={'modal-content '}>
              {/*<SVGraphics onClick={() => proceed(false)}  svgname={'close'} height={'15px'} width={'15px'} style={{*/}
              {/*    float: 'left',*/}
              {/*    top: '10px',*/}
              {/*    left: '10px',*/}
              {/*    position: 'absolute',*/}
              {/*    cursor: 'pointer'*/}
              {/*}}/>*/}
              <div className={'alpha-modal-content'}>
                <span className={'app-modal-title'}>{title}</span>
                <div className={'app-modal-content red'}>
                  {errMessages &&
                    errMessages.map((txt) => {
                      return (
                        <div key={txt}>
                          <span key={txt} style={{ marginBottom: '1rem' }}>
                            {txt}
                          </span>
                          <br />
                        </div>
                      )
                    })}
                </div>
                <div className={'app-modal-footer h-centered'}>
                  <button
                    id={'err-modal-ok-button'}
                    className={'btn btn-primary button-confirm'}
                    onClick={() => proceed(false)}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        proceed(true)
                      }
                    }}
                  >
                    {proceedLabel === 'Accept' ? t('modal.accept') : proceedLabel}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    )
  }
}

ErrorModal.propTypes = {
  okLabbel: PropTypes.string,
  proceedLabel: PropTypes.string,
  t: PropTypes.string,
  title: PropTypes.string,
  errMessages: PropTypes.array,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool
}

export function makeError({ proceedLabel, options}, errMessages) {
  return createConfirmation(confirmable(ErrorModal))({
    errMessages,
    proceedLabel,
    ...options
  })
}
