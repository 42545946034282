import axios from 'axios'
import { authenticationService } from '../AuthinticationService'

class RestApi {
  async get(url, config) {
    let authConfig = await this.getAuthConfig(url, config)
    return await axios.get(url, authConfig)
  }

  async post(url, data, config) {
    let authConfig = await this.getAuthConfig(url, config)
    return await axios.post(url, data, authConfig)
  }

  async put(url, data, config) {
    let authConfig = await this.getAuthConfig(url, config)
    return await axios.put(url, data, authConfig)
  }

  async s3Put(url, data, config) {
    return await axios.put(url, data, config)
  }

  async delete(url, config) {
    let authConfig = await this.getAuthConfig(url, config)
    return await axios.delete(url, authConfig)
  }

  async getAuthConfig(url, config) {
    return new Promise((resolve) => {
      let headers = config.headers || {}
      authenticationService
        .currentSessionToken()
        .then(async (accessToken) => {
          let user = JSON.parse(JSON.stringify(authenticationService.currentUserValue))
          if (accessToken !== user['token']) {
            user['token'] = accessToken
            await authenticationService.setUserData(user)
          }
          headers['Authorization'] = accessToken
          config.headers = headers
          resolve(config)
        })
        .catch(() => {
          config.headers = headers
          resolve(config)
        })
      // }
    })
  }
}

let restApi = new RestApi()
export default restApi
