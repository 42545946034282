import React from "react";
import SVGraphics from "../../assets/SVGraphics";


export function FormCard(props) {

    let hasDeleteIcon = !!props.hasDeleteIcon

    return(

        <div className={`w-full bg-white h-auto rounded-[0.625rem] border-l-8 border-alphaTauButton pl-[2.125rem] shadow-lg ${props.blockClass}`}>

           <div className={'flex justify-between'}>
               <div className={`${props.cardTitleClass} pt-[1.25rem] text-alphaTauButton text-2xl font-bold text-titleSize`}>{props.cardTitle}</div>
               {hasDeleteIcon && props.length>1 &&
                   <SVGraphics className={'flex justify-end  mt-[1.55rem] mr-[1.25rem] ml-[1.55rem] cursor-pointer max-w-[1.5rem] max-h-[1.5rem]'} svgname={'trash'} onClick={props.deleteCurrent}/>
               }

           </div>

            <div style={props.cardStyle} className={`${props.cardClass} pt-[1rem] mt-4 w-10/12 grid md:grid-cols-1 gap-x-32 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 sm:grid-cols-1 max-sm:grid-cols-2 `}>
                {props.data}
            </div>

            {props.hasAdditional && <div
                className={`${props.cardClass} w-10/12 grid md:grid-cols-1 gap-x-12 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 sm:grid-cols-1 max-sm:grid-cols-2 `}>
                {props.additionalComp}
            </div>}
        </div>
    )
}