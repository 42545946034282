import SVGraphics from "../../assets/SVGraphics";
import React from "react";


export function SearchInputButtonComponent(props) {


    return(
        <div className={'w-full mt-6 grid grid-cols-3 '}>
            <div  className={'flex col-span-2 h-[56px]'}>
                <input type="search" id="default-search"
                       onChange={props.onChange}
                       className={`rounded-l-md w-full p-4 pl-10 text-sm text-gray-900  bg-white d outline-0  ${props.inputClass}`}
                       placeholder="Search" required/>
                <div className={'bg-alphaTauButton flex items-center rounded-r-md'}>
                    <SVGraphics className="mr-3 h-6 w-6 ml-4" svgname={'glass'} />
                </div>
            </div>
            {props.hasButton &&
                <div className={'flex col-span-1 h-[56px]'}>
                    <button type="button"
                            onClick={props.addNewOnClick}
                            className="font-Roboto flex items-center w-full text-white ml-2 bg-alphaTauButton hover:bg-alphaTauButtonHover font-medium rounded-rounded10 text-textSize md:text-base lg:text-textSize px-5 p-2.5 text-center" >
                        {props.buttonText}
                    </button>
                </div>
            }


        </div>

    )
}
