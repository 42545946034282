import React, {useState} from "react";
import {authenticationService} from "../../../services/AuthinticationService";
import {useNavigate} from "react-router-dom";
import {TextAlpha} from "../../input/TextAlpha";
import logo from "../../../assets/logo.svg";
import {AlphaForm} from "../../forms/AlphaForm";
import {Error} from "../../forms/Error";
import formData from "../../forms/formsData.json";
import Constants from "../../../utils/constants";
import {AlertConfirm} from "../../modals/Confirm";

export function ResetPassword() {

    const PasswordDontMatchException = "The passwords do not match"
    const PasswordRequirementException = "The password you entered doesn’t meet the minimum security requirements"

    const [newPasswordInputs, setNewPasswordInputs] = useState({
        newPassword: "",
        confirmNewPassword:""
    });
    const [isError, setIsError] = useState(null);
    const navigate = useNavigate();
    const [error, setError] = useState({});
    const [formType, setFormType] = useState(formData['newPassword'])
    // const [isLoading, setIsLoading] = useState(true);
    const [disableButton, setdisableButton] = useState(true);
    const [showPassword, setShowPassword] = useState({'newPassword':false,'confirmNewPassword':false});
    const [BackendErrors, setBackendErrors] = useState(null);
    const [errRegexObj,setErrRegexObj] = useState({})
    const inputClassName = "bg-white rounded-xl  h-16 w-96  h-full bg-transparent font-Roboto text-lg text-alphaTauText"

    // let recaptchaRef = useRef(null)

    const SubmitFunc = async () => {
        let userID = sessionStorage.getItem("userID")
        // let status = sessionStorage.getItem("status")
        if (Object.keys(errRegexObj).length > 0) {
            setIsError(PasswordRequirementException)
        } else if(newPasswordInputs.newPassword !== newPasswordInputs.confirmNewPassword){
            setIsError(PasswordDontMatchException)
        }else{
            // setIsLoading(true)
            let response = await authenticationService.changeNewPassword(newPasswordInputs, userID)
            if (response && response.status < 400) {
                let data = response.data
               await AlertConfirm({
                    proceedLabel: 'Ok',
                    cancelLabel: 'none',
                    options: {hasSVG: true, svgName: "check-circle2" ,svgWidth:'5.188rem',
                        svgHeight:'5.188rem',modalStyle:{padding:'2.813rem 6.063rem'}},

                }, "Password has been changed successfully.\nUse your new password to log in.", `Password changed!`)
                if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === 'pass') {
                    navigate('/login');
                }
            }
            // setIsLoading(false)
        }
    }
    const passwordValidation = (password)=> {
        let lengthPattern = /^.{8,20}$/;
        let specialCharacterPattern = /^(?=.*[!@#$%^&*()_\-+={}[\]\\|:;"'<>,.?\/]).*$/;
        let oneNumberPattern = /^(?=.*\d).*$/;
        let upperCasePattern = /^(?=.*[A-Z]).*$/;
        let lowerCasePattern = /^(?=.*[a-z]).*$/;

        let errObj={}

        if (!lengthPattern.test(password)) {
            errObj['1']=true
        }
        if (!specialCharacterPattern.test(password)) {
            errObj['2']=true
        }
        if (!oneNumberPattern.test(password)) {
            errObj['3']=true
        }
        if (!upperCasePattern.test(password)) {
            errObj['4']=true
        }
        if (!lowerCasePattern.test(password)) {
            errObj['5']=true
        }
        // const isValidationError = Object.keys(errObj).length > 0
        setIsError(null)
        setErrRegexObj(errObj)
        if(Object.keys(errObj).length ===0){
            setdisableButton(false)
        }else{setdisableButton(true)}
    }
    const handleChange = async (event) => {
        let inputs = {...newPasswordInputs}
        let name = event.target.name
        let value = event.target.value
        inputs[name]=event.target.value
        let form = [...formType]
        let formErrors = {...error}
        switch (name) {
            case 'newPassword':
                passwordValidation(value)
                break;
            default: value = event.target.value
        }
        inputs[name] = value
        setNewPasswordInputs(inputs)
        setBackendErrors(null)
        setFormType(form)
        setError(formErrors)

    }
    const setShowPasswordType = ( name ) => {
        const showPasswordObj = Object.assign({},showPassword)
        showPasswordObj[name] = !showPassword[name]
        setShowPassword(showPasswordObj)

    }
    return (
        <div style={Constants.bgStyle}>
            <div className={"flex-col font-Roboto w-login h-login bg-loginBg shadow-2xl rounded-3xl  flex items-center  m-auto absolute inset-0 px-[5.675rem]"}>
            <div className={"mt-11"}>
                <img src={logo} alt={"AlphaTAU"} className={"flex w-64 self-center"}/>
            </div>

        <div>
            <div className={"mt-[2.313rem] text-alphaTauText flex-col flex text-lg mb-[3.625rem]"}>
                <TextAlpha text={"Change password?"} className={"self-start font-bold text-[1.75rem]"} />
                <TextAlpha text={"Please enter the new password below "} className={" w-loginButton self-start text-[1.125rem]  auth-label"} />
            </div>


                    <AlphaForm
                        data={formType}
                        formData={newPasswordInputs}
                        onChange={(e) => handleChange(e)}
                        containerClassName={"flex-col flex w-full h-full"}
                        inputClassName={inputClassName}
                        isShown={error}
                        type={showPassword}
                        disableErrorMessage={true}
                        showPassword={showPassword}
                        showOnClick={(e)=>setShowPasswordType(e)}
                    />
            <div className={'mt-0'}>
                <Error
                    message={isError}
                    isShown={isError}/>
            </div>
            <div className={' w-96'}>
                <div className={' flex-col flex w-full h-full '}>
                    <label className={'font-Roboto text-lg text-alphaTauText'}>New Password must contain:</label>
                    <div className="password-requirements flex">
                        <ul>
                             <li style={{color:errRegexObj['1']?'red':'black'}}>8-20 characters</li>
                           <li style={{color:errRegexObj['2']?'red':'black'}}>One special character</li>
                           <li style={{color:errRegexObj['3']?'red':'black'}}>One number</li>
                        </ul>
                        <ul>
                           <li style={{color:errRegexObj['4']?'red':'black'}}>One uppercase character</li>
                           <li style={{color:errRegexObj['5']?'red':'black'}}>One lowercase character</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>


    <div className={"w-full text-left mt-2 ml-24"}>
                    <Error
                        message={BackendErrors}
                        isShown={BackendErrors}/>
                </div>

                <button type="button"
                        disabled={disableButton}
                        className="mt-[3.813rem] w-loginButton text-white h-loginButtonHeight bg-alphaTauButton hover:bg-alphaTauButtonHover font-Roboto font-medium rounded-xl text-textSize px-5 py-2.5 text-center"
                        onClick={()=>SubmitFunc()}
                >
                    Change Password
                </button>

        </div>
            {/*<AlertConfirm title={'Password Changed'} proceedLabel="Yes" message="" show={true} proceed={()=>{}} />*/}
        </div>


    )
}

