import React, {useEffect, useState} from "react";
import {FormCard} from "../../../forms/FormCard";
import formData from "../../../forms/formsData.json";
import {AlphaForm} from "../../../forms/AlphaForm";
import {TextAlpha} from "../../../input/TextAlpha";
import {useLocation, useNavigate} from "react-router-dom";
import {object, string} from "yup";
import SystemAdmin from "../../../../services/ApiServices/SystemAdminApi";
import {AlertConfirm} from "../../../modals/Confirm";
import SVGraphics from "../../../../assets/SVGraphics";
import Constants from "../../../../utils/constants";
import {LoginAPICalls, SystemAdminApi} from "../../../../services/ApiService";
import {authenticationService} from "../../../../services/AuthinticationService";


export function EditUser() {
    const navigate = useNavigate();
    const [formType, setFormType] = useState(formData['add_user'])
    const [siteForm, setSiteForm] = useState([formData['user_sites']])
    const [userSites, setUserSites] = useState([]);
    const [userRole, setUserRole] = useState(null);
    const [sitesOption, setSitesOption] = useState([])
    const [entry, setEntry] = useState({});
    const [chosenSites, setChosenSites] = useState([]);
    const [error, setError] = useState({})
    const [siteFormErrors, setSiteFormErrors] = useState([])

    const [isError, setIsError] = useState(false)
    const right = <span className={"text-[#444444] text-2xl text-titleSize ml-2"}>&#8250;</span>

    const userSchema = object().shape({
        FirstName: string().required('Please insert First Name'),
        LastName: string().required('Please insert Last Name'),
        Gender: string().required('Please insert Gender'),
        PhoneNumber: string().nullable(),
        Email: string().matches(Constants.emailRegExp, "Invalid Email").required("Please insert Email"),
    });

    const userSiteSchema = object().shape({
        SiteID: string().required('Please select Site'),
        Role: string().required('Please select Role'),
    })

    const location = useLocation();

    useEffect(() => {
        let currentUser = authenticationService.currentUserValue
        setUserRole(currentUser.role[0])
        let form = JSON.parse(JSON.stringify(formType))
        let siteFormsTemp = JSON.parse(JSON.stringify(siteForm))
        const entry = location.state;
        let userRole = entry.userSites.find(x=>x.SiteID===currentUser.profile.SiteID)?.Role[0]


        let chosenSitesObj = []
        let sites = []
        if(currentUser.role[0]==="SystemAdmin") {
            fetchData().then(p => {
                p.data.forEach(site => {
                    if (site.IsActive) {
                        sites.push({value: site.SiteID, label: site.SiteName})
                    }
                })
            })
        }else{
            currentUser.profile.Sites.forEach(site => {
                sites.push({value: site.SiteID, label: site.SiteName})
            })

        }
        // let rolesData
        // if(currentUser.role[0]!=="SystemAdmin"){
        //     rolesData = siteFormsTemp[0][1].data?.filter(x=>x.value!=="ProductAdmin" && x.value !== "SystemAdmin")
        // }else{
        //     rolesData = siteFormsTemp[0][1].data?.filter(x=> x.value !== "SystemAdmin")
        // }
            siteFormsTemp[0][0].data = sites

            setSiteForm(siteFormsTemp)
            setSitesOption(sites)
            let user = {...entry}
            let siteFormArr=[]
            let userSitesArr=[]
            let currentUserSites=user.userSites
            currentUserSites.forEach((item,index)=>{
                chosenSitesObj.push({index:index,value:item.SiteID})
                userSitesArr.push({Role:item.Role[0],isActive:item.isActive===true?1:0,SiteID:item.SiteID})
                siteFormArr.push(siteFormsTemp[0])
                siteFormErrors.push({})
            })

        if(currentUser.role[0]!=="SystemAdmin" && userRole==="ProductAdmin"){
            form.forEach(f=>{
                f.disabled = true
            })
            siteFormArr.forEach(f=>{
                f.forEach(field=> {
                    field.disabled = true
                })
            })
        }

            setFormType(form)
            setEntry(user)
            setChosenSites(chosenSitesObj)
            setSiteForm([...siteFormArr])
            setUserSites([...userSitesArr])
            setSiteFormErrors([...siteFormErrors])


    }, [])


    const validateForm = async (entry) => {
        let form = [...formType]
        let formErrors = {...error}
        let value = false
        await userSchema.validate(entry, {abortEarly: false})
            .then(function (value) {
                formErrors = {}
                setIsError(false)
                setError(formErrors)
            }).catch(function (err) {
                formErrors = {}
                err.inner.forEach(error => {
                    formErrors[error.path] = true
                    let findIndex = form.findIndex(x => x.name === error.path)
                    form[findIndex]['error'] = error.errors
                })
                value=true
                setError(formErrors)
                setIsError(value)
                value= true
            });

        return value
    }

    const validateSiteForm= async (userSites) => {
        let value=false
        for (let i = 0; i < userSites.length; i++) {
            let item= userSites[i]
            let index=i
            let form = [...siteForm[index]]
            let formErrors = {...siteFormErrors[index]}

            await userSiteSchema.validate(item, {abortEarly: false})
                .then(function () {
                    formErrors={}
                    siteForm[index]=[...form]
                    siteFormErrors[index]={...formErrors}
                    setSiteForm([...siteForm])
                    setSiteFormErrors([...siteFormErrors])
                })
                .catch(function (err){
                    value=true
                    formErrors = {}
                    err.inner.forEach(error => {
                        formErrors[error.path] = true
                        let findIndex = form.findIndex(x => x.name === error.path)
                        form[findIndex]['error'] = error.errors
                        setIsError(true)
                    })
                });
            siteForm[index]=[...form]
            siteFormErrors[index]={...formErrors}

            setSiteForm([...siteForm])
            setSiteFormErrors([...siteFormErrors])
        }

        return value


    }


    const fetchData = async () => {
        return await SystemAdmin.getAllSitesAdvanced({filters: []})
    }

    const modifyUser = async () => {
        let user = {...entry}
        let valueValidateForm = await validateForm(user)
        let valueValidateSites =  await validateSiteForm(userSites)
        userSites.forEach(userSite=>{
            userSite['isActive'] = userSite['isActive'] === 1 ? true : false
        })
        user['userSites'] = userSites
        if (!valueValidateForm && !valueValidateSites) {
            let submit = await SystemAdminApi.editUser(user)
            if (submit && submit.data) {
                navigate('/users')
            }
        }

    }

    const onChange = async (name, value) => {
        let user = {...entry}
        if (name === 'Gender') {
            value = value.value
        } else if (name === 'SiteID') {
            value = value.value
        }
        if (value === "") {
            value = null
        }
        user[name] = value

        if(error[name]){
            await validateForm(user)
        }

        setEntry(user)
    }

    const onChangeSiteForm = async (index, name, value) => {
        let currentFrom = {...userSites[index]}
        if (name === 'Role') {
            value = value.value
        } else if (name === 'SiteID') {
            value = value.value
            let _chosenSites = JSON.parse(JSON.stringify(chosenSites))
            let chosenSiteIndex = chosenSites.findIndex(x=>x.index===index)
            if(chosenSiteIndex>-1){
                _chosenSites[chosenSiteIndex].value = value
            }else{
                _chosenSites.push({index:index,value:value})
            }
            setChosenSites(_chosenSites)
        }
        if (value === "") {
            value = null
        }
        currentFrom[name] = value
        userSites[index] = {...currentFrom}
        setUserSites([...userSites])
        if(siteFormErrors[index][name]){
            await validateSiteForm(userSites,index)
        }

    }

    const addSites = () => {
        let _form = JSON.parse(JSON.stringify(formData['user_sites']))
        _form[0].data=sitesOption
        setUserSites([...userSites, {isActive: 1}]);
        setSiteForm([...siteForm, _form]);
        setSiteFormErrors([...siteFormErrors, {}]);
    }
    const resetUserPassword = async () => {
        if(entry.Email){
            let confirmReset = await AlertConfirm({
                proceedLabel:'Yes',
                cancelLabel:'No'
            },"Are you sure you want to Reset user password?","User reset password")
            if(confirmReset){
                let response= await LoginAPICalls.forgetPass({Email:entry.Email})
                if(response){
                     await AlertConfirm({
                        proceedLabel:'Ok',
                        cancelLabel:'none'
                    },"Reset done successfully","User reset password")

                }
            }

        }
    }
    const deleteCurrent = (index) => {
        const userSiteArray = [...userSites];
        const siteFormsArr = [...siteForm];
        const siteFormsErrArr = [...siteFormErrors];

        if (userSiteArray.length > 1) {
            userSiteArray.splice(index, 1);
            siteFormsArr.splice(index, 1);
            siteFormsErrArr.splice(index, 1);
        }
        let _chosenSites = JSON.parse(JSON.stringify(chosenSites))
        let chosenSiteIndex = chosenSites.findIndex(x=>x.index===index)
        if(chosenSiteIndex>-1){
            _chosenSites.splice(chosenSiteIndex,1)
        }
        setChosenSites(_chosenSites)
        setSiteFormErrors(siteFormsErrArr);
        setUserSites(userSiteArray);
        setSiteForm(siteFormsArr);
    }

    return (
        <div className="w-full h-full pt-32 ml-24 overflow-auto">
            <div className={'w-11/12'}>
                <div className={'mb-7'}>
                    <TextAlpha text={"Users"}  className={"text-alphaTauText text-[1.688rem] text-titleSize cursor-pointer font-bold"} onClick={()=>navigate('/users')}/>
                    <TextAlpha text={right}  />
                    <TextAlpha text={" Edit user"}  className={"text-alphaTauButton text-[1.5rem] text-titleSize"}/>

                </div>
                <FormCard
                    cardClass={'pb-[1.875rem]'}
                    cardTitle={"User info"}
                    hasAdditional={true}
                    additionalComp={
                    <div
                        className={"cursor-pointer w-[9.375rem] h-[1.563rem] text-[1.313rem] leading-[1.563rem] font-Roboto font-normal text-alphaTauButton border-b border-alphaTauButton"}
                        onClick={()=>resetUserPassword()}>Reset password</div>

                }
                    data={ <AlphaForm
                        data={formType}
                        formData={entry}
                        onChange={e => onChange(e.target.name, e.target.value)}
                        containerClassName={"mb-2 mr-12"}
                        labelClassName={Constants.inputLabelClass}
                        inputClassName={Constants.inputClass}
                        isShown={error}
                        isError={isError}
                    />}
                />
                {userSites.map((item, index) => {
                    return <FormCard
                        key={item.SiteID}
                        cardClass={'pb-[1.875rem]'}
                        blockClass={'mt-5'}
                        cardTitle={"Sites"}
                        hasDeleteIcon={true}
                        length={userSites.length}
                        deleteCurrent={() => deleteCurrent(index)}
                        data={<AlphaForm
                            key={index + "site"}
                            chosenOptions={chosenSites}
                            data={siteForm[index]}
                            formData={userSites[index]}
                            onChange={e => onChangeSiteForm(index, e.target.name, e.target.value)}
                            containerClassName={"mb-2 mr-12"}
                            labelClassName={Constants.inputLabelClass}
                            inputClassName={Constants.inputClass}
                            isShown={siteFormErrors[index]}
                            isError={isError}
                        />
                        }/>
                })}
                {userRole === "SystemAdmin" &&
                <div
                    className={'text-alphaTauButton text-[1.25rem] h-[3.5rem] w-[8rem] font-bold font-Roboto flex items-center cursor-pointer'}
                    onClick={() => addSites()}>
                    <SVGraphics className={'w-6 h-6 mr-[0.75rem]'} svgname={'plus-circle'}/>
                    Add Site
                </div>
                }

                <div className={'float-right my-8 flex items-center pb-[3.125rem]'}>
                    <button type="button"
                            disabled={false}
                            onClick={()=>modifyUser()}
                            className="mr-6 w-loginButton text-white bg-alphaTauButton hover:bg-alphaTauButtonHover font-Roboto font-medium rounded-rounded10 text-textSize px-5 py-2.5 text-center" >
                        Update
                    </button>
                    <div className={'ml-10 underline underline-offset-4 text-alphaTauButton text-[1.313rem] cursor-pointer'} onClick={()=>navigate('/users')}>Cancel</div>
                </div>
            </div>
        </div>
    )

}