import React from "react";
import {InputLabel, OutlinedInput} from "@mui/material";


export function InputAlpha(props) {



    return (
        <div className={props.containerClassName}>
            <InputLabel className={props.labelClassName}>{props.label}</InputLabel>
            <OutlinedInput
                error={props.isError}
                className={`inputClass ${props.inputClassName}`}
                type={'text'}
                id={props.name+props.value}
                maxLength={props.maxLength}
                // error={isErr}
                // defaultValue={props.value}
                value={props.value}
                onChange={props.onChange}
                // helperText={isErr ? errMessage : ''}
                placeholder={props.placeholder}
                disabled ={props.disabled}
                // required={required}
                name={props.name}
                endAdornment={props.endAdornment}
            />
        </div>
    )
}
