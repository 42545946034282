import React, {useEffect, useState} from "react";
import {authenticationService} from "../../../services/AuthinticationService";
import formData from "../../forms/formsData.json";
import {useNavigate} from "react-router-dom";
import utils from "../../../utils/utils";
import {Error} from "../../forms/Error";
import {AlphaForm} from "../../forms/AlphaForm";
import logo from "../../../assets/logo.svg"
import {TextAlpha} from "../../input/TextAlpha";
import Constants from "../../../utils/constants";

export function Login() {
    const [LoginInputs, setLoginInputs] = useState({
        Email: "",
        Password: ""
    });
    const navigate = useNavigate();
    const [error, setError] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [isError, setIsError] = useState(false);
    const [formType, setFormType] = useState(formData['login'])
    const [BackendErrors, setBackendErrors] = useState(null);


    const inputClassName = "bg-white rounded-xl  h-16 w-96  h-full bg-transparent font-Roboto text-lg text-alphaTauText"
    // let recaptchaRef = useRef(null)

    useEffect(()=>{
        document.getElementById("login")?.addEventListener("keyup", handlekeypress);
        return () => {
            document.getElementById("login")?.removeEventListener('keyup', handlekeypress);
        };
    },[LoginInputs])


    // const SubmitFunc = async (reCaptchaToken) => {
    const SubmitFunc = async () => {

            let response = await authenticationService.login(LoginInputs.Email, LoginInputs.Password);
            sessionStorage.setItem('Email', LoginInputs.Email);
            sessionStorage.setItem('Password', LoginInputs.Password);

            if (response && response.status < 400) {
                let data = response.data
                if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === '2fa') {
                    // const {from} = {from: {pathname: "/verificationCode",state:"test"}};
                    // navigate(from);

                    // if(data.role[0] === 'ProductAdmin' || data.role[0] === 'SystemAdmin'){
                    //     const phoneNumber = "XXX-XXXX" + data.profile.PhoneNumber.slice(9); // Replace first six characters with "XXXXXX"
                    //
                    //     navigate("/verificationCode",{state:{type:"phone",value:phoneNumber}});
                    // }else{
                    //     navigate("/gantt");
                    // }
                    const phoneNumber = "XXX-XXXX" + data.profile.PhoneNumber.slice(9); // Replace first six characters with "XXXXXX"

                    navigate("/verificationCode", {state: {type: "phone", value: phoneNumber}});

                }
            } else {
                setBackendErrors('Wrong Email or Password')
            }

    }
    const handleChange = async (event) => {

        let inputs = {...LoginInputs}
        let name = event.target.name
        let value = event.target.value
        LoginInputs[name]=event.target.value
        let form = [...formType]
        let formErrors = {...error}
        if (value === "") {
            value = null
        }
        switch (name) {
            case 'Email':
                if(value && !utils.mailValidation(value)){
                    formErrors['Email'] = true
                    setIsError(true)
                    form[0]['error'] = "Please enter valid email address"
                }else if(!value){
                    form[0]['error'] = "Please enter email address"
                    formErrors['Email'] = true
                    setIsError(true)
                } else{
                    formErrors['Email'] = false
                    form[0]['error'] = ""
                }
                break;
            case 'Password':
                if(!value){
                    form[1]['error'] = "Please enter password"
                    formErrors['Password'] = true
                    setIsError(true)
                }
                else{
                    formErrors['Password'] = false
                    form[1]['error'] = ""
                }
                break;
            default:

                setIsError(false)
        }
        inputs[name] = value
        setLoginInputs(inputs)
        setFormType(form)
        setError(formErrors)
        setBackendErrors(null)
    }
    const handlekeypress = async (event) => {
        if (event.key === 'Enter') {
            if(LoginInputs.Email && LoginInputs.Password) {
                await SubmitFunc()
            }
        }
    };

    return (
            <div style={Constants.bgStyle} >

                <div className={"flex-col font-Roboto w-login h-login lg:h-[44rem] lg:w-[30rem] bg-loginBg shadow-2xl rounded-3xl  flex items-center  m-auto absolute inset-0"}>
                    <div className={"mt-11"}>
                        <img src={logo} alt={"AlphaTAU"} className={"flex w-64 self-center"}/>
                    </div>
                    <div>
                        <div className={"mt-16 text-alphaTauText flex-col flex"}>
                            <TextAlpha text={"Log In"} className={"self-start font-bold text-4xl"} />
                            <TextAlpha text={"Please enter your details"} className={"self-start text-lg mb-12 lg:mb-8"} />
                        </div>
                        <AlphaForm
                            data={formType}
                            formData={LoginInputs}
                            onChange={(e) => handleChange(e)}
                            // onKeyUp={handlekeypress}
                            containerClassName={"flex-col flex w-full h-full"}
                            labelClassName={"font-Roboto text-lg text-alphaTauText"}
                            inputClassName={inputClassName}
                            isShown={error}
                            type={showPassword?'text':'password'}
                            showPassword={showPassword}
                            showOnClick={()=>setShowPassword(!showPassword)}
                            isError={isError}
                        />
                        <div className={"w-full text-left mt-2 text-lg"} >
                            <Error
                                message={BackendErrors}
                                isShown={BackendErrors}/>
                        </div>
                    </div>
                <div className={'mt-24 lg:mt-8'} >
                    <button type="button"
                            onClick={() => SubmitFunc()}
                            disabled={!LoginInputs['Email'] || !LoginInputs['Password'] || (LoginInputs['Email'] && !utils.mailValidation(LoginInputs['Email']))}
                            className=" w-loginButton text-white mt-8 h-loginButtonHeight bg-alphaTauButton hover:bg-alphaTauButtonHover font-Roboto font-medium rounded-rounded10 text-textSize px-5 py-2.5 text-center">
                        Log In
                    </button>
                    <div  className={"font-normal mt-2 text-center text-alphaTauButton cursor-pointer text-base font-Roboto"} onClick={()=>navigate('/forgotPassword')}>Forgot password</div>
                </div>
            </div>
            </div>


    )
}
