import React from 'react';
import "../../style/input/loadingComponent.css"


export function LoadingComponent(props){

        return (
            <div className="modalBackground loaderBackground">
            <div className={"loader spin"} style={props.style}/>
            </div>
        );

}

