import React, {forwardRef} from "react";
import {InputLabel} from "@mui/material";
import DatePicker from "react-datepicker";


export function DateTimeAlpha(props) {

    const style = {

        backgroundColor: props.backgroundColor?props.backgroundColor:"#ffffff",
        width: "100%",
        height: "3.5rem",
        color: "#BABABA",
        textTransform: "capitalize",
        boxShadow: 'unset',
        fontSize: "18px",
        outline: "unset",
        borderRadius: "0.75rem"
    }
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
      <input className={`${props.inputClassName} pl-2 border border-[#e5e7eb] placeholder:font-normal placeholder:text-[#ACAEB1] placeholder:text-[#BABABA] opacity-100 placeholder:opacity-100 disabled:text-[#ACAEB1]` } style={style} defaultValue={value} placeholder={props.label} disabled={props.disabled} onClick={onClick} ref={ref}/>)

    )
    return (
        <div className={props.containerClassName}>
            <InputLabel className={props.labelClassName}>{props.label}</InputLabel>
            {/*<OutlinedInput*/}
            {/*    error={props.isError}*/}
            {/*    className={`inputClass ${props.inputClassName}`}*/}
            {/*    type={'text'}*/}
            {/*    id={props.name+props.value}*/}
            {/*    maxLength={props.maxLength}*/}
            {/*    // error={isErr}*/}
            {/*    // defaultValue={props.defaultValue}*/}
            {/*    value={props.value}*/}
            {/*    onChange={props.onChange}*/}
            {/*    // helperText={isErr ? errMessage : ''}*/}
            {/*    placeholder={props.placeholder}*/}
            {/*    disabled ={props.disabled}*/}
            {/*    // required={required}*/}
            {/*    name={props.name}*/}
            {/*    endAdornment={props.endAdornment}*/}
            {/*/>*/}
            <DatePicker
                calendarClassName={'alpha-date-time'}
                selected={props['isRange']?props.startDate:props.selected}
                id={props.name+props.value}
                onChange={props.onChange}
                startDate={props.startDate}
                endDate={props.endDate}
                placeholderText={props.placeholder}
                selectsRange={props['isRange']?true:false}
                dateFormat="dd MMMM yyyy hh:mm aa"
                showTimeSelect
                isClearable={props.isClearable==="false"?false:true}
                readOnly={props.disabled===true?true:false}
                minDate={props.minDate}
                // inline
                customInput={<ExampleCustomInput
                    placeholder={props.placeholder}/>}
            />
        </div>
    )
}
