import React from "react";
import SVGraphics from "../../assets/SVGraphics";
import "../../style/input/searchInput.css";

export function SearchInput(props) {

    return (
            <div  className={'flex h-full'}>

                <input type="search" id="default-search"
                       onChange={props.onChange}
                       className={`rounded-l-md w-searchWidth shadow-[0_4px_6px_0px_rgba(0,0,0,0.06)] p-4 pl-10 text-sm text-gray-900  bg-white d outline-0  ${props.inputClass}`}
                       placeholder="Search" required/>
                <div className={'bg-alphaTauButton flex items-center rounded-r-md'}>
                    <SVGraphics className="mr-3 h-6 w-6 ml-4" svgname={'glass'} />

                </div>
            </div>
    )
}
