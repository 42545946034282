import LoginAPICalls from "./ApiServices/LoginAPICalls";
import axios from "axios";
import {makeError} from "../components/modals/ErrorModal";
import {authenticationService} from "./AuthinticationService";
import SystemAdminApi from './ApiServices/SystemAdminApi';
import ChambersApi from './ApiServices/ChambersApi'
import GanttApi from './ApiServices/GanttApi'
import ConfigurationApi from './ApiServices/ConfigurationApi'
import OrdersApi from './ApiServices/OrdersApi'
import UsersApi from './ApiServices/UsersApi'
export {
    LoginAPICalls,
    SystemAdminApi,
    ChambersApi,
    GanttApi,
    UsersApi,
    ConfigurationApi,
    OrdersApi

    // CliniciansApi
};

axios.interceptors.request.use(async (config) => {
    // Do something before request is sent
    let headers = config.headers
    headers['Strict-Transport-Security'] = 'max-age=31536000; includeSubDomains'
    headers['X-Frame-Option'] = 'DENY'
    headers['Content-Type'] = 'application/json'
    headers['Access-Control-Allow-Origin'] = '*'
    headers['Access-Control-Allow-Methods'] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS'
    headers['Access-Control-Allow-Headers'] = 'Origin, Content-Type, X-Auth-Token'
    headers['Server'] = ["Hidden"]

    if (authenticationService.currentUserValue && !headers['Authorization'])
        headers['Authorization'] = authenticationService.currentUserValue?.token

    config.headers = headers

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// declare a response interceptor
axios.interceptors.response.use(
    async (response) => {
        return response;
    },
    async (error) => {
        if (String(error.response.config.url).includes('receiveCodeFromWCU')) {
            return error;
        }
       else if (error.response && error.response.data['message']!=='wrong code') {
           if(!error.response.data.message.includes("Wrong UserID or Password")){
               // let method = String(error.response.config.method).toLowerCase()
               // let endpoint = String(error.response.config.url)
               let response = error.response

               let title = 'Error'
               let errors = response.data.body
               let messages = []
               if (Array.isArray(errors)) {
                   errors.forEach(err => messages.push(err.message.replace(/"/g, "")))
               } else {
                   if (response.hasOwnProperty('data') && response.data.hasOwnProperty('message'))
                       messages.push(response.data.message.replace(/"/g, ""))
                   else if (response.hasOwnProperty('data'))
                       messages.push(String(response.data).replace(/"/g, ""))
                   else {
                       messages.push(String(response).replace(/"/g, ""))
                   }
               }
               await makeError({proceedLabel: 'Ok', options:{title}},messages);
               return Promise.reject(error)
           }else{
               let response = error.response

               let title = 'Error'
               let errors = response.data.body
               let messages = []
               if (Array.isArray(errors)) {
                   errors.forEach(err => messages.push(err.message.replace(/"/g, "")))
               } else {
                   if (response.hasOwnProperty('data') && response.data.hasOwnProperty('message'))
                       messages.push(response.data.message.replace(/"/g, ""))
                   else if (response.hasOwnProperty('data'))
                       messages.push(String(response.data).replace(/"/g, ""))
                   else {
                       messages.push(String(response).replace(/"/g, ""))
                   }
               }
               await makeError({proceedLabel: 'Ok', options:{title}},messages);
               return Promise.reject(error)
           }
        } else if(error.response && error.response.status>200){
            await makeError({proceedLabel: 'Ok', options: {title: 'Error'}}, [error.response.data.data['message']]);
            return Promise.reject(error)
            // return Promise.reject({error, request: error.request, data: {message: 'Connection Error'}})
        }
       else if (error.request) {// client never received a response, or request never left
            /*let endpoint = String(error.request.responseURL)
                .replace('//dev.api.resigene.com/api/v1/webapp/', '')*/
        }

       else
        {
            await makeError({proceedLabel: 'Ok', options: {title: 'Connection Error'}}, ['Connection error']);
            return Promise.reject({error, request: error.request, data: {message: 'Connection Error'}})
        }
    });
