import React from "react";
import "../../style/input/checkbox.css"


export function InputCheckboxAlpha(props) {

    return (
        <div className={`checkbox-wrapper ${props.containerClassName}`}>
            <input type={"checkbox"} ref={props.ref} name={props.name} className={props.inputClassName} onChange={props.onChange} checked={props.checked} {...props.rest}/>

        </div>
    )
}
