//UsersApi
import { authenticationService } from '../AuthinticationService'
import Config from '../../config/Config'
import restApi from './RestApi'
import axios from 'axios'

const globalUrl = Config.globalUrl

const UsersApi = {
  createUser: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let currentUser = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: currentUser?.token
        }
      }
      return await restApi.post(
        `${globalUrl}/api/v1/webapp/userManagement/${currentUser.profile.UserStorageId}/createUser`,
        body,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  editUser: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let currentUser = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: currentUser?.token
        }
      }
      return await restApi.post(
        `${globalUrl}/api/v1/webapp/userManagement/${currentUser.profile.UserStorageId}/editUser/${body.UserStorageId}`,
        body,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },

  getAllUsers: async (body) => {
    try {
      if (!authenticationService.currentUserValue) return
      let currentUser = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: currentUser?.token
        }
      }
      return await restApi.post(
        `${globalUrl}/api/v1/webapp/userManagement/${currentUser.profile.UserStorageId}/getAllUsers`,
        body,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },

  getAdminByEmail: async (email) => {
    try {
      if (!authenticationService.currentUserValue) return
      let currentUser = authenticationService.currentUserValue
      const requestconfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: currentUser?.token
        }
      }
      return await restApi.get(
        `${globalUrl}/api/v1/webapp/userManagement/${currentUser.profile.UserStorageId}/getAdminByEmail/${email}`,
        requestconfig
      )
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  changeSite: async (id, siteId) => {
    if (!authenticationService.currentUserValue) return
    let currentUser = authenticationService.currentUserValue
    let requestconfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: currentUser?.token
      }
    }
    try {
      return await axios.post(
        `${globalUrl}/api/v1/webapp/userManagement/${currentUser.profile.UserStorageId}/changeSite/${id}`,
        { siteID: siteId },
        requestconfig
      )
    } catch (err) {
      console.log(err)

      return err
    }
  }
}
export default UsersApi
