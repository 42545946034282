import React, {useEffect, useState} from "react";
import SystemAdmin from '../../../services/ApiServices/SystemAdminApi';
import { Table } from '../../table/table';
import UsersOptions from '../../options/UsersOptions';
import { useNavigate } from 'react-router-dom';
import {SelectAlphaIcon} from '../../input/SelectAlphaIcon';
import SVGraphics from '../../../assets/SVGraphics';
import { Switch } from "@mui/material";
import { SearchInputButtonComponent } from "../../input/SearchInputButtonComponent";
import Constants from "../../../utils/constants";
import {Alert} from "../../modals/Alert";


export function Sites() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);
    const [searchInputs, setSearchInputs] = useState([]);
    const [countryStates, setCountryStates] = useState([]);
    const [siteFilter, setSiteFilter] = useState({ filters:[]});
    const [sites, setSites] = useState(null);

    useEffect( () => {
        fetchData().then(p=>{
            setSites(p.data)
            setFilteredData(p.data)
            setIsLoading(false)
        })
        const inputs = [
            {name:"Status",label:"Status",type:"Select",data:[{value: true, label: 'Active'},{value: false, label: 'Disabled'}],hasLine:true,svgName:"toogleRight",disabled:false},
            {name:"Country",label:"Country",type:"Select",data:[],hasLine:true,svgName:"sitePlace",disabled:false},
            {name:"State",label:"State",type:"Select",data:[],hasLine:true,svgName:"sitePlace",disabled:true}]
        const fetchDataGetSitesFilters = async () => { //get patient data from db
            let siteFilters = await SystemAdmin.getSitesCountry()
            if (siteFilters && siteFilters.data ) {
                siteFilters = siteFilters.data
                setCountryStates(siteFilters)
                let countries = []
                siteFilters.forEach(country=>{
                    countries.push({label:country.Country,value:country.Country})
                })
                inputs.forEach(input=>{
                    if(input.name === "Country"){
                        input.data=countries
                    }
                })
                setSearchInputs(inputs)
            }
        }
        fetchDataGetSitesFilters().then()

    }, [])
    const fetchData= async()=> {
        return await SystemAdmin.getAllSitesAdvanced(siteFilter)
    }

    const handleSiteStatus = async (event,siteId) => {
        const checked = event.target.checked
        if (!checked) {
            //disableSite
             await SystemAdmin.disableSite({siteID:siteId})
            fetchData().then(p=>{
                setSites(p.data)
                setFilteredData(p.data)
                setIsLoading(false)
            })
        }
    }

    const handleSearch=(event)=>{
        let filterValue = event.target.value.toLowerCase()
        let filteredData =[]
        for (let i = 0; i < sites.length; i++) {
            filteredData = sites.filter(user =>
                String(user['SiteName']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['Country']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['Street']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['Number']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['Type']).toLowerCase().indexOf(filterValue) > -1
            )
        }
        setFilteredData(filteredData)
    }
    const columns = [
        {
            Header: 'Site Name',
            accessor: 'SiteName', // accessor is the "key" in the data
            // sortType: 'string'
        },
        {
            Header: 'Country',
            accessor: 'Country',
            // sortType: 'string'
        },
        {
            Header: 'Street',
            accessor: 'Street',
            // sortType: 'string'
        },
        {
            Header: 'Number',
            accessor: 'Number',
            // sortType: 'string'
        },
        {
            Header: 'Production Admin',
            accessor: '',
            // sortType: 'string',
            Cell:(props)=>{
                    return(<UsersOptions options={props.row.original.ProductAdmin.Admins}/>)
                    // <div>
                    //
                    //     {props.row.original.Users.length > 0 ?props.row.original.Users[0].PII.FirstName+" "+props.row.original.Users[0].PII.LastName:''}
                    // </div>
                }},
        {
            Header: '№ of active chambers',
            accessor: 'ChamberCount',
            // sortType: 'string'
        },
        {
            Header: 'Type',
            accessor: 'Type',
            // sortType: 'string'
        },
        {
            Header: 'Status',
            accessor: 'IsActive',
            Cell:(props)=>{
                return(
                    <Switch
                        checked={props.row.original.IsActive}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={(e)=>handleSiteStatus(e,props.row.original.SiteID)}
                    />
                )
            }

        },
        {
            Header: 'Edit',
            accessor: 'Edit',
            Cell:(cell)=>{
                return(<div onClick={() => navigate(`/systemAdmin/editSite/${cell.row.original?.SiteID}`)}>
                        <SVGraphics className={'w-6 h-6 cursor-pointer'} svgname={'edit'} />

                </div>
                )
            }
        },
    ]
    const filterOnChange = async (e, type) => {

        let inputs = [...searchInputs]
        let filters = {...siteFilter}
            let statusFilter = filters.filters.findIndex(x=>x.name===type)
            if(statusFilter>-1){
                if(!e.target.value){
                    filters['filters'].splice(statusFilter,1)
                }else{

                    filters['filters'][statusFilter]['value'] = e.target.value.value
                }
            }else{
                filters.filters.push({name:type,value:e.target.value.value})
            }
        if(type === 'Country' ){
            let index = inputs.findIndex(x=>x.name === 'State')
            if(e && e.target.value){
                let states = countryStates.find(x=>x.Country===e.target.value.value)
                if(states){
                    let data = [...new Set(states.state) ]
                    let statesData =[]
                    data.forEach(d=>{
                        statesData.push({value:d,label:d})
                    })
                    if(index>-1){
                        inputs[index]['data'] = statesData
                    }

                }
            }else{
                if(index>-1){
                    inputs[index]['data'] = []
                }
            }
            setSearchInputs(inputs)
        }

        setSiteFilter(filters)
        fetchData().then(p=>{
            setSites(p.data)
            setFilteredData(p.data)
            setIsLoading(false)
        })

    }
    const clearFilters = async () => {
        setSiteFilter( {filters:[]})
        let newSites =  await SystemAdmin.getAllSitesAdvanced({filters:[]})
        if(newSites && newSites.data){
            newSites = newSites.data
            setSites(newSites)
            setFilteredData(newSites)
            setIsLoading(false)
        }
    }
return(
    <div className={Constants.mainScreenClass}>
                    <div className={"site-search-inputs"}>
                        {searchInputs.map(item =>{
                            return <SelectAlphaIcon
                                key={item.name}
                                svgName={item.svgName}
                                data={item.data}
                                name={item.name}
                                label={item.label}
                                value={siteFilter['filters'].find(filter=>filter.name===item.name)?.value}
                                selected={siteFilter['filters'].find(filter=>filter.name===item.name)?.value}
                                type={item.type}
                                disabled={item.disabled}
                                onChange={(e)=>filterOnChange(e,item.name )}
                                hasLine={item.hasLine}
                                inputClassName={'Select-Icon'}
                            />
                        })}
                        <div className={'ml-[3rem] lg:ml-4 text-alphaTauButton text-[1.25rem] h-[3.5rem] font-bold font-Roboto flex items-center cursor-pointer'} onClick={()=>clearFilters()}><span className={'mr-4'}>&#10005;</span> Clear all</div>
                    </div>

        <div className={"w-width90 lg:w-[98%]"}>
            <SearchInputButtonComponent
                buttonText={"+ Add new site"}
                hasButton={true}
                inputClass={""}
                addNewOnClick={()=>navigate('/systemAdmin/addSite')}
                onChange={(e)=>handleSearch(e)}/>
            <Table data={filteredData} columns={columns} isLoading={isLoading}/>
        </div>

        {/*<Alert*/}
        {/*bg={'bg-[#FAF2F2]'}*/}
        {/*border={'border-[#FF3434]'}*/}
        {/*color={'text-[#F00]'}*/}
        {/*message={'Site can not be disabled due to measurements in progress '}*/}
        {/*icon={'alert'}*/}
        {/*/>*/}




    </div>
)
}