import React from "react";
import SVGraphics from "../../../assets/SVGraphics";
import injector from "../../../assets/gantt/icons8-syringe-100 1.png"
import truck from "../../../assets/gantt/icons8-truck-100 1.png"
import {OverlayTrigger} from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";

export function DeliveryGanttView(props) {

    const toolTipDataTitles = [{name:'SiteName',label:'Clinical Site'},{name:'TYPECODE',label:'Order Type'},{name:'SBD_SEEDQTY',label:'Number of Seeds'},{name:'SIBD_ALPH_FIXATION',label:'Seed Type'}]
    const orderToolTip = (arr) => {
        arr['SiteName'] = props.siteName
        return <div className={'py-[0.375rem] text-[#444444] text-[1.125rem] font-Roboto w-[15rem]'}>
            {toolTipDataTitles.map(data=>{
                return <div className={'flex justify-between py-[0.375rem]'} key={data.name}>
                    <div className={' font-semibold '}>{data.label}:</div>
                    <div>{arr[data.name]}</div>
                </div>
            })}
        </div>
    }

    const getDeliveryData = (date) => {
        // let actionsOrders = props.actionsOrders
        // let shippingArray = actionsOrders.filter(x=>x.actionDate?.setHours(0,0,0,0).valueOf()===date.date?.setHours(0,0,0,0).valueOf())
        let deliveryArray = props.orders.filter(x=>new Date(x.EDATE).setHours(0,0,0,0).valueOf()===date.date?.setHours(0,0,0,0).valueOf())
        let treatmentArray = props.orders.filter(x=>new Date(x.SIBD_TREATDAY).setHours(0,0,0,0).valueOf()===date.date?.setHours(0,0,0,0).valueOf())
        return <div className={'w-[100%] '}>
            <div className={'flex justify-around'}>
                {treatmentArray.length >0 && treatmentArray.map(arr=>{
                    const onHover = <Tooltip className={"tableToolTip left-[3.3%] top-[1%]"}>
                        {orderToolTip(arr)}</Tooltip>
                    return <div key={arr.actionID}>
                        <div>
                            <div style={{backgroundColor:arr.color}} className={'w-[28px] h-[28px] rounded-full relative top-[50px] flex items-center justify-center'}>
                                <div className={'text-[0.813rem] max-w-5.375rem w-[5rem] break-words absolute bottom-[33px] pl-1'}>{`${props.siteName} ${arr.TYPECODE?"- "+arr.TYPECODE:""}`}</div>
                                <div style={{border:`1px solid ${arr.color}`}} className={'h-[0.688rem] w-[1px] relative bottom-[17px] left-[35%]'}>

                                </div>
                                <OverlayTrigger trigger={['hover','focus']} delay={{show: 250, hide: 250}}  overlay={onHover} placement={'bottom'}><img alt={'treatment'} src={injector}/>
                                </OverlayTrigger>
                            </div>
                        </div>

                    </div>
                })
                }
                { deliveryArray.length > 0  && deliveryArray.map(arr=>{
                    const onHover = <Tooltip className={"tableToolTip left-[3.3%] top-[1%]"}>
                        {orderToolTip(arr)}</Tooltip>
                    return   <div key={arr.actionID}>
                        <div style={{backgroundColor:arr.color}} className={'w-[28px] h-[28px] rounded-full relative top-[105px] flex items-center justify-center'}>
                            <div className={'flex flex-column items-center justify-center'}>
                            <div className={'text-[0.813rem] max-w-5.375rem w-[5rem] break-words absolute top-[40px] pl-1'}>{`${props.siteName} ${arr.TYPECODE?"- "+arr.TYPECODE:""}`}</div>
                            <div style={{border:`1px solid ${arr.color}`}} className={'h-[0.688rem] w-[1px] relative top-[17px] left-[35%]'}/>
                            <OverlayTrigger trigger={['hover','focus']} delay={{show: 250, hide: 250}}  overlay={onHover} placement={'bottom'}>
                                <img src={truck} alt={'shipment'}/>
                            </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                })}
                {deliveryArray.length === 0 && treatmentArray.length === 0 &&
                <SVGraphics className={'w-[28px] cursor-pointer  '} svgname={''}/>
                }
                {/*{shippingArray.map(arr=>{*/}
                {/*    console.log("arr",arr)*/}
                {/*    const onHover = <Tooltip className={"tableToolTip left-[3.3%] top-[1%]"}>*/}
                {/*        {orderToolTip(arr)}</Tooltip>*/}
                {/*    return <div key={arr.actionID}>*/}
                {/*    {arr?.svgName==='injector'&&*/}
                {/*        <div>*/}

                {/*   <div className={'w-[28px] h-[28px] rounded-full relative top-[50px] bg-[#D58D1F] flex items-center justify-center'}>*/}
                {/*       <div className={'text-[0.813rem] max-w-5.375rem w-[5rem] break-words absolute bottom-[33px] pl-1'}>{`${arr.SiteName} - ${arr.ORDERTYPE}`}</div>*/}
                {/*       <div className={'h-[0.688rem] w-[1px] border border-[#D58D1F] relative bottom-[17px] left-[35%]'}>*/}

                {/*       </div>*/}
                {/*       <OverlayTrigger trigger={['hover','focus']} delay={{show: 250, hide: 250}}  overlay={onHover} placement={'bottom'}><img alt={'treatment'} src={injector}/>*/}
                {/*       </OverlayTrigger>*/}
                {/*        </div>*/}
                {/*        </div>}*/}
                {/*    {arr?.svgName==='circle-truck'&&*/}
                {/*    <div>*/}

                {/*   <div className={'w-[28px] h-[28px] rounded-full relative top-[105px] bg-[#24857A] flex items-center justify-center'}>*/}
                {/*       <div className={'text-[0.813rem] max-w-5.375rem w-[5rem] break-words absolute top-[40px] pl-1'}>{`${arr.SiteName} - ${arr.ORDERTYPE}`}</div>*/}
                {/*       <div className={'h-[0.688rem] w-[1px] border border-[#24857A] relative top-[17px] left-[35%]'}></div>*/}
                {/*       <OverlayTrigger trigger={['hover','focus']} delay={{show: 250, hide: 250}}  overlay={onHover} placement={'bottom'}>*/}
                {/*        <img src={truck} alt={'shipment'}/>*/}
                {/*       </OverlayTrigger>*/}
                {/*   </div>*/}
                {/*    </div>  }*/}
                {/*    </div>*/}
                {/*})}*/}

            </div>

            <svg xmlns="http://www.w3.org/2000/svg" width={'100%'}>
                <line x1="0" y1="40" y2="40" x2="100%" style={{stroke:'#D7DDDF'}} strokeWidth="3px"/>
                <line x1="0" y1="92" y2="92" x2="100%" style={{stroke:'#D7DDDF'}} strokeWidth="3px"/>
            </svg>
        </div>
    }

    return(
        <div className={'grid grid-cols-9'}>
            <div className={'w-[143px] h-[191px] bg-[#77A5FF] opacity-20'}/>
            {props.currentRange.map(range=>{
                return <div key={range?.date.getTime()} className={`h-[193px] border border-[#D7DDDF] ${range.day==='Saturday'?'bg-[#D9D9D9]/30 ':'bg-[#F2F5FA]'} relative right-[17px]`}>
                    {getDeliveryData(range)}
                </div>
            })
            }
        </div>
    )

}