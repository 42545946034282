import React, { useEffect, useState } from "react";
import { SearchInput } from "../input/SearchInput";
import { Table } from "../table/table";
import { useNavigate } from "react-router-dom";
import SVGraphics from "../../assets/SVGraphics";
import { SelectAlphaIcon } from "../input/SelectAlphaIcon";
import Constants from "../../utils/constants";
import utils from "../../utils/utils";
import OrdersApi from "../../services/ApiServices/OrdersApi";
import moment from "moment";
import { useAppSelector } from "../../redux/hooks";
import { authenticationService } from "../../services/AuthinticationService";

export function Orders() {
    const navigate = useNavigate();
    const index = useAppSelector((state)=>state.orders.pageIndex)
    const [isLoading, setIsLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);
    const [searchInputs, setSearchInputs] = useState([]);
    const [orderFiltersData, setOrderFiltersData] = useState([]);
    const [orders, setOrders] = useState(null);
    const [pageIndex, setPageIndex] = useState(index);
    const [ordersFilter, setOrdersFilter] = useState({filters:[]});
    const [userRole, setUserRole] = useState(null);


    useEffect( () => {
        let user = authenticationService.currentUserValue
        setUserRole(user.role[0])
        fetchData('db').then()
        getFilterData().then()


    }, [])
    useEffect(()=>{
        setPageIndex(index)
    },[index])

    const getFilterData = async () => {
        const _searchInputs = Constants.orders_searchInputs
        let orderStatus =  await OrdersApi.getOrdersStatuses()
        if(orderStatus && orderStatus.data){
            let statuses = []
            orderStatus.data.forEach(status=>{
                statuses.push({label:status['ORDSTATUSDES'],value:status['ORDSTATUSDES']})
            })
            _searchInputs[0]['data'] = statuses
        }
        let ordersType = await OrdersApi.getOrdersTypes()
        let types = []
        if(ordersType && ordersType.data){
            ordersType.data.forEach(type=>{
                types.push({label:utils.getOrderTypeLabel(type['TYPEDES']),value:type['TYPEDES']})
            })
            _searchInputs[1]['data'] = types
        }
        setSearchInputs(_searchInputs)

    }

    const fetchData = async (type) => {
        let _filtersData =  [{name:'totalOrders', label:'Total Orders', svgName:'total'},{name:'pendingPlan', label:'Pending For Plan', svgName:'document'},{name:'pendingApproval', label:'Pending Approval', svgName:'loader'},{name:'approvedOrders', label:'Approved Orders', svgName:'approved'}]
        let allOrders
        if(type==='db'){
            allOrders = await OrdersApi.getOrders()
            if(allOrders && allOrders.data['Complete'].length===0){
                allOrders = await OrdersApi.getOrdersFromPriority()
            }
        }else{
            allOrders = await OrdersApi.getOrdersFromPriority()
        }
        if(allOrders && allOrders.data){
            _filtersData[0]['value'] = allOrders.data['Complete'].length
            setOrders(allOrders.data['Complete'])
            setFilteredData(allOrders.data['Complete'])
            // setIsLoading(false)
        }
        let statusCount = await OrdersApi.getOrdersStatusCount()
        if(statusCount && statusCount.data){
            _filtersData[1]['value'] = statusCount.data['pendingPlan']
            _filtersData[2]['value'] = statusCount.data['pendingApproval']
            _filtersData[3]['value'] = statusCount.data['approvedOrders']
        }
        setOrderFiltersData(_filtersData)
        setIsLoading(false)
    }
    const handleSearch=(event)=>{
        let filterValue = event.target.value.toLowerCase()
        let filteredData =[]
        for (let i = 0; i < orders.length; i++) {
            filteredData = orders.filter(user =>
                    String(user['OrderID']).toLowerCase().indexOf(filterValue) > -1 ||
                    String(user['ORDSTATUSDES']).toLowerCase().indexOf(filterValue) > -1 ||
                    String(utils.getOrderTypeLabel(user['TYPEDES'])).toLowerCase().indexOf(filterValue) > -1
                // String(user['Number']).toLowerCase().indexOf(filterValue) > -1 ||
                // String(user['Type']).toLowerCase().indexOf(filterValue) > -1
            )
        }
        setFilteredData(filteredData)
    }
    const refreshData = async () => {
        setIsLoading(true)
        fetchData('priority').then()
        getFilterData().then()
    }

    const columns = [
        {
            Header: ' PO ID',
            accessor: 'OrderID', // accessor is the "key" in the data
            Cell:(cell)=>{
                return(<div>
                        {cell.row.original.ORDNAME?.substr(cell.row.original.ORDNAME.length - 4)}
                    </div>
                )
            }
            // sortType: 'string'
        },
        {
            Header: 'Order Status',
            accessor: 'ORDSTATUSDES',
        },
        {
            Header: 'Procedure Date',
            accessor: 'SIBD_TREATDAY',
            Cell:(cell)=>{
                return(<div>
                        {cell.row.original.SIBD_TREATDAY?moment(cell.row.original.SIBD_TREATDAY).format('MMM DD YY'):''}
                    </div>
                )
            }
            // sortType: 'string'
        },
        {
            Header: 'Procedure Time',
            accessor: 'SIBD_TREATTIME',

            // sortType: 'string'
        },
        {
            Header: 'Pick up Date and Time',
            accessor: 'EDATE',
            Cell:(cell)=>{
                return(<div>
                        {cell.row.original.EDATE?moment(cell.row.original.EDATE).format('DD MMM YYYY h:mm A'):''}
                    </div>
                )
            }
            // sortType: 'string'
        },
        {
            Header: 'Target Activity Level',
            accessor: 'SBD_PREFACTIV',

            // sortType: 'string',
        },

        {
            Header: 'Procedure Location',
            accessor: 'Y_5380_6_ESHB',
            // sortType: 'string'
        },
        {
            Header: 'Required Seeds',
            accessor: 'SBD_SEEDQTY'
            // sortType: 'string'
        },
        {
            Header: 'Order Type',
            accessor: 'TYPEDES',
            Cell:(cell)=>{
                let type = utils.getOrderTypeLabel(cell.row.original['TYPEDES'])
                    if(cell.row.original['TYPEDES']==='008'){
                       type = type +"-"+ cell.row.original['ALPH_CHAMBERNUM']
                    }
                return(
                    type
                //     <OverlayTrigger
                //     placement={"bottom"}
                //     delay={{show: 250, hide: 250}}
                //     trigger={["hover", "focus"]}
                //     offset={0}
                //     rootClose
                //     // show={true}
                //     overlay={<Tooltip id="tooltip-disabled" className={"tableToolTip "}>
                //         <div className={'w-[7rem] overflow-hidden'}>
                //             {type}
                //         </div>
                //     </Tooltip>} >
                //     <div className={" text-ellipsis"}>{type}</div>
                // </OverlayTrigger>
                )
            }
            // sortType: 'string'
        },
        {
            Header: ()=> (
                <div className={'w-[3rem] h-[3rem] bg-alphaTauButton rounded-[0.625rem] flex justify-center items-center cursor-pointer'}
                     onClick={()=>refreshData()}
                >
                    <SVGraphics className={'w-[1.5rem]'} svgname={'refresh-white'} />
                </div>
            ),
            accessor: 'calculator',
            disableSortBy:true,
            width:40
            // Cell:(cell)=>{
            //     return(<div
            //             onClick={() => openCalculator(cell.row.original?.OrderID)}
            //         >
            //             <img className={'w-6 h-6 cursor-pointer'} src={calculator} alt={'calculator'}/>
            //
            //     </div>
            //     )
            // }
        },
    ]
    const  openCalculator = async (id) => {
        if(userRole==='SystemAdmin' || userRole==='ProductAdmin' || userRole==='TeamLeader'){
            let orderID = id.original.OrderID
            if(id.original['TYPEDES'] !== '008'){
                navigate(`/orders/calculateOrder/${orderID}`)
            }
        }
    }


    const filterOrders = async (name,value) => {

        let filters = {...ordersFilter}
        let filter = filters.filters.findIndex(x=>x.name===name)
        let valueToAdd=value?.value
        if(valueToAdd!=='totalOrders'){
            // if(name==='pendingPlan'||name==='pendingApproval'||name==='approvedOrders') {
            //     filters.filters.push({name: name, value: value})
            // }
            // else{
                if(filter>-1){
                    if(!value){
                        filters['filters'].splice(filter,1)
                    }else{
                        filters['filters'][filter]['value'] = valueToAdd
                    }
                }else{
                    filters.filters.push({name:name,value:valueToAdd})
                }
            // }

            setOrdersFilter(filters)
            let filterData = await OrdersApi.filteredOrders(filters)
            setOrders(filterData.data)
            setFilteredData(filterData.data)

        }else{
            fetchData('db').then()
        }
        // setIsLoading(false)
        // fetchData(filters).then()
    }

    const filterOrdersByPlanStatus = async (name,value) => {

        let valueToAdd=value?.value
        if(valueToAdd!=='totalOrders'){
            let filterData = await OrdersApi.filterOrdersByPlanStatus({planStatus :valueToAdd})
            setOrders(filterData.data)
            setFilteredData(filterData.data)
        }else{
            fetchData('db').then()
        }
    }

    return(

        <div className={Constants.mainScreenClass}>
            <div className={"w-width90"}>
            <div className={'flex justify-between lg:flex md:grid md:grid-cols-2 md:gap-4'}>
                {
                    orderFiltersData.map(filter=>{
                        return <div
                            key={filter.name}
                            onClick={()=>filterOrdersByPlanStatus("planStatus", {value:filter.name})}
                            className={'w-[19%] lg:w-[19%] md:w-3/4 h-[7.063rem] bg-[#F2F5FA] rounded-[0.625rem] shadow-md flex items-center justify-center cursor-pointer'}
                        >
                            <SVGraphics className={'w-[4.375rem] '} svgname={filter.svgName} />
                            <div className={'ml-[0.813rem]'}>
                                <div className={'text-alphaTauButton font-Roboto font-bold leading-[2.625rem] text-[2.25rem] h-[2.625rem]'}>{filter.value}</div>
                                <div className={'text-[#747879] font-Roboto leading-[1.125rem] text-[0.938rem]'}>{filter.label}</div>
                            </div>

                        </div>
                    })
                }
            </div>

            <div className={'flex lg:flex-row md:flex-col mt-8 justify-between'}>
                <div className={'w-[51%]c lg:w-[51%] md:w-[90%] h-[3.5rem]'}>
                    <SearchInput
                        onChange={(e)=>handleSearch(e)}
                    />
                </div>

                <div className={"site-search-inputs ml-6 lg:mt-0 lg:ml-6 md:mt-4 md:ml-0"}>
                    {searchInputs.map(item =>{
                        return <SelectAlphaIcon
                            key={item.name}
                            svgName={item.svgName}
                            data={item.data}
                            type={item.type}
                            value={ordersFilter['filters']?.find(filter=>filter.name===item.name)?.value}
                            selected={ordersFilter['filters']?.find(filter=>filter.name===item.name)?.value}
                            name={item.name}
                            label={item.label}
                            svgClass={'w-[1.5rem] h-[1.5rem]'}
                            onChange={(e)=>filterOrders(item.name,e.target.value)}
                            // onChange={(e)=>onChangeFilter(e)}
                            hasLine={item.hasLine}
                            inputClassName={'Select-Icon'}
                        />
                    })}
                    {/*<div className={'ml-[3rem] text-[#17597C] text-[1.25rem] h-[3.5rem] font-bold font-Roboto flex items-center cursor-pointer'} onClick={()=>clearFilters()}><span className={'mr-4'}>&#10005;</span> Clear all</div>*/}
                </div>
            </div>

                <Table data={filteredData?filteredData:[]} columns={columns} isLoading={isLoading}
                       pageIndex={pageIndex}
                       // onPageChange={(e)=>handlePageChange(e)}
                       onClick={openCalculator}
                />
            </div>




        </div>
    )
}