import React, {useState, useEffect} from "react";
import {authenticationService} from "../../../services/AuthinticationService";
import {useLocation, useNavigate} from "react-router-dom";
import {TextAlpha} from "../../input/TextAlpha";
import logo from "../../../assets/logo.svg";
import {Error} from "../../forms/Error";
import ReactCodeInput from "react-code-input";
import '../../../style/input/verificationCode.css'
import Constants from "../../../utils/constants";

export function VerificationCode() {
    const [VerificationCodeInputs, setVerificationCodeInputs] = useState();
    const navigate = useNavigate();
    const [BackendErrors, setBackendErrors] = useState(null);
    const [viewText, setviewText] = useState(false);
    const [title, setTitle] = useState(null);
    const {state} = useLocation();
    // let recaptchaRef = useRef(null)
    useEffect(() => {
        if (state.type === "email") {
            setTitle("email")
        } else {
            setTitle("phone")
        }
    }, [])
    useEffect(()=>{
        let login = document.getElementById("login")
        login?.addEventListener("keyup", handleKeyPress);
        return () => {
            login?.removeEventListener('keyup', handleKeyPress);
        };
    },[VerificationCodeInputs])
    const SubmitFunc = async (reCaptchaToken) => {
        let response
        if (state.type === "email") {
            response = await authenticationService.submitforgotPassVerfication(VerificationCodeInputs);
        } else {
            response = await authenticationService.submit2fa(VerificationCodeInputs);
        }
        if (response && response.status < 400) {
            let data = response.data
            if (data && data.hasOwnProperty('status') && String(data['status']) === 'forgetPass') {
                navigate('/ResetPassword')
            } else if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === 'pass') {
                if (data.profile.PassNeedsToChange) {
                    navigate('/ResetPassword')
                } else {
                    if( data.role[0] === 'SystemAdmin'){
                        navigate('/systemAdmin/sites');
                    }else{
                        navigate("/gantt");
                    }
                }
            }
        } else {
            setBackendErrors('Verification code invalid. Please try again')
        }
    }
    const handleKeyPress = async (event) => {
        if (event.key === 'Enter') {
            if(VerificationCodeInputs?.length===6) {
                await SubmitFunc()
            }
        }
    };

    const onCodeChange = async (e) => {
        setBackendErrors(null)
        setVerificationCodeInputs(e)
    }
    const sendAgain = async (e) => {

        let response
        if (state.type === "email") {


            response = await authenticationService.forgetPass({Email: sessionStorage.getItem('Email')})
        } else {
            response =  await authenticationService.login(sessionStorage.getItem('Email'), sessionStorage.getItem('Password'));
        }

        if (response && response.status < 400) {
            let data = response.data
            if (data ) {
                // const phoneNumber = "XXXXXXX" + data.profile.PhoneNumber.slice(9); // Replace first six characters with "XXXXXX"
                setviewText(true)
                // navigate("/verificationCode",{state:{type:"phone",value:phoneNumber}});
            }
        }
    }
    const getReactCodeInputProps = (valid) => {
        return {
            fontFamily: 'monospace',
            marginRight: '8px',
            MozAppearance: 'textfield',
            width: '50px',
            borderRadius: '10px',
            fontSize: '20px',
            height: '54px',
            backgroundColor: '#FFFFFF',
            color: valid ? 'lightskyblue' : 'red',
            textAlign: 'center',
            border: `1px solid ${valid ? '#D0D5DD' : 'red'}`,
        }
    }

    return (
        <div style={Constants.bgStyle}>
        <div
        className={"flex-col font-Roboto w-login h-login bg-loginBg lg:h-[44rem] lg:w-[31rem] shadow-2xl rounded-3xl flex items-center m-auto absolute inset-0 px-[5.675rem] lg:px-[4rem]"}>
        <div className={"mt-11"}><img src={logo} alt={"AlphaTAU"} className={"flex w-64 self-center"}/></div>
        <div>
            <div className={"mt-16 text-alphaTauText flex-col flex  "}><TextAlpha text={`Please check your ${title}`}
                                                                                className={"self-start font-bold text-[1.75rem]"}/>

                <TextAlpha text={`We’ve sent a code to ${state.value}`} className={"self-start text-lg mb-12"}/></div>
            <div  id={'login'}
                className={'h-centered verification-code-container font-Roboto text-lg text-alphaTauText flex-col flex w-21.75 h-full'}>
                <label className={"self-start text-xs mb-2 text-[#75797A]"}> Please enter your verification code below</label>
                <ReactCodeInput type='number' fields={6}
                                isValid={!BackendErrors}

                                className={'code-verification-password'}
                                inputStyle={getReactCodeInputProps(true)}
                                inputStyleInvalid={getReactCodeInputProps(false)}
                                onChange={(e) => onCodeChange(e)}/>

                <div className={"h-centered flex-col flex w-21.75 h-full text-xs"}>
                    <Error message={BackendErrors} isShown={BackendErrors}/>
                </div>
            </div>

        </div>
        <button type="button"
                className="mt-48 w-loginButton text-white mt-codeBottom h-loginButtonHeight bg-alphaTauButton hover:bg-alphaTauButtonHove0r font-Roboto font-medium rounded-xl text-textSize px-5 py-2.5 text-center"
                onClick={() => SubmitFunc()}> Verify
        </button>
        <div className={"font-normal mt-[3.125rem] text-center text-alphaTauButton cursor-pointer text-base font-Roboto"}
             onClick={() => sendAgain()}>Resend verification code
        </div>
            {viewText &&
                <div className={"mt-[0.625rem] text-[#444444] text-[0.938rem] font-bold text-center text-alphaTauButton cursor-pointer text-base font-Roboto"}>
                    The verification code was resent
                </div>
            }
    </div>
        </div>
    )
}

